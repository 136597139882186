import React from "react";

import URL from '../../../../../../URL.js';
import Tree, { useTreeState } from "../../../../../Base/TreeView/index.tsx";

import { AmpMainTreeViewProps } from "./Props/AmpMainTreeViewProps.ts";
import { IIconContext } from "../../../../../Base/TreeView/IconInterpterer/IIconContext.ts";
import { Interpreter } from "../../../../../Base/TreeView/IconInterpterer/Interpreter.ts";
import { ITreeViewIcon } from "../../../../../Base/TreeView/IconInterpterer/ITreeViewIcon.ts";
import { AmpPositionsIconInterpreter } from "./IconInterpreters/AmpPositionsIconInterpreter.ts";

export default function AmpPositionsTree(props: AmpMainTreeViewProps) {
	const [responseStatus, setResponseStatus] = React.useState({
		errorStatus:0, 
		successfulStatus:''
	});
	const [treeData, setTree] = React.useState<{
		id: number, 
		image: ITreeViewIcon, 
		header: string, 
		children: any
	}[]>([]);
	const { required, handlers } = useTreeState({ 
		id: 'tree', 
		data: treeData, 
		defaultOpened: [1, 2], 
		multipleSelect: false 
	});
	
	// Задание интерпретатора для выбора иконок и базовой структуры дерева
	const iconInterpreter = new AmpPositionsIconInterpreter();
	const treeBaseData: [] = [];

	// Обновление данных
	React.useEffect(() => {
		setTree([]);
        fetchTreeRootData();
		fetchTreeData(iconInterpreter);
    }, []);

	// Задел для фильтрации данных
	React.useEffect(() => {
		setTree([]);
        fetchTreeRootData();
		fetchTreeData(iconInterpreter);
    }, [props.ampId, props.isSubassy, props.partEff, props.ipcPos, props.description, props.position, props.fin]);

	// Корневой узел
	const treeRootData = [{
		isRootData: true,
		id: 1,
		image: iconInterpreter.Interpret(getTreeViewIconParams(undefined, true)),
		header: { id: { value: `${props.acFamily}; AIR COMPANY` } },
		children: [{
			id: 2,
			image: iconInterpreter.Interpret(getTreeViewIconParams(undefined, undefined, true)),
			header: { id: { value: `${props.acFamily}; AIR COMPANY: Position Structure`, width: 350 } },
			children: []
		}]
	}];

    // Получение базовой информации в шапке
	const fetchTreeRootData = async () => {
		Object.assign(treeBaseData, treeRootData);
	}

	// Получение общей информации для дерева
	const fetchTreeData = async (iconInterpreter: Interpreter<IIconContext>) => {
		if (treeBaseData?.length == 0) 
			return;

		const response = await fetchResponseAsync();
		if (response.ok) {
			const result = await response.json();
			treeBaseData[0].children[0].children = [];

			result.value?.forEach(element => {
				const treeViewIconParams = getTreeViewIconParams(element);
				const headerParams = getNodeHeaderParams(element);

				const child = getChildNode(
					iconInterpreter.Interpret(treeViewIconParams),
					headerParams,
					element.assyStatus
				);
				treeBaseData[0].children[0].children.push(child);
			});

			setTree(treeBaseData);
		} 
		else {
			setResponseStatus({...responseStatus, errorStatus:response.status});
		}
	};

	// Получение информации о children
	const fetchTreeChildrenData = async (node: any, iconInterpreter: Interpreter<IIconContext>) : Promise<any> => {
		const response = await fetchResponseAsync(true, node);
		if (response.ok) {
			const result = await response.json();
			let childNode = { children: [{}] };
            childNode.children = [];

			result.leafs?.forEach(element => {
				const treeViewIconParams = getTreeViewIconParams(element);
				const headerParams = getNodeHeaderParams(element);

				const child = getChildNode(
					iconInterpreter.Interpret(treeViewIconParams),
					headerParams,
					element.assyStatus
				);
				childNode.children.push(child);
			});

			return childNode;
		} 
		else {
			setResponseStatus({...responseStatus, errorStatus:response.status});
		}
	};

	const fetchResponseAsync = async (isChild: boolean = false, node: any = undefined): Promise<Response> => {
		var url = isChild
			? `${URL}${process.env.REACT_APP_API_PARTM}/AmpModelPositions/IpcStructLeaf(mainId=${node.data.header.id.value}`
			: `${URL}${process.env.REACT_APP_API_PARTM}/AmpModelPositions/IpcStructTree(skip=${props.skip ?? 0},take=${props.take ?? 0}`;
		url = url.concat(
			`,ampId=${props.ampId}`,
			`,isSubassy=${props.isSubassy ?? false}`,
			`,partEff='${props.partEff}'`,
			`,ipcPos='${props.ipcPos}'`,
			`,description='${props.description}'`,
			`,position='${props.position}'`,
			`,fin='${props.fin}')`
		);

		const headers = { 
			headers: { 'Authorization': `Bearer ${localStorage.getItem('asid')}` }
		};

		return await fetch(url, headers);
    }

	function getTreeViewIconParams(element: any = null, isRootProject: boolean = false, isRootPlane: boolean = false): any {
		return { 
			assyStatus: element?.assyStatus, 
			mpExists: element?.mpExists, 
			ipcPos: element?.ipcPos,
			isRootProject: isRootProject,
			isRootPlane: isRootPlane
		};
	}

	function getNodeHeaderParams(element: any): any {
		return { 
			id: { value: element.id, width: 70 }, 
			ipcPos: { value: element.ipcPos, width: 130 }, 
			pos: { value: element.position, width: 80 }, 
			description: { value: element.description, width: 350 }, 
			fin: element.fin
				? { value: `FIN: ${element.fin}`, width: 110 }
				: { value: '', width: 110 },
			remarks: element.remarks
				? { value: `Remarks: ${element.remarks}`, width: 200 }
				: { value: '', width: 200 }
		};
	}

	function getChildNode(interpreterResult: any, headerParams: any, conditionItem: any = undefined): any {
		const isExtended = conditionItem == 'MA' || conditionItem == 'LA';
		const simpleChild = {
			id: 3,
			image: interpreterResult,
			header: headerParams
		};
		const extendedChild = {
			...simpleChild,
			children: [{
				...simpleChild,
				header: { id: { value: 'No content' } }
			}]
		};

		return isExtended ? extendedChild : simpleChild;
	}

	const openNodeAsync = async (node) => {
		if (!node.isOpened() && node.data.header?.id?.value > 0) {
			const childNode = await fetchTreeChildrenData(node, iconInterpreter);
			handlers.setRawChildren(node, childNode.children, undefined, true);
		}
		handlers.setOpen(node);
	}

	return (
      	<Tree
			{...required}
			{...handlers}
			gapMode="padding" 
			depthGap={20}
			setSelected={(node, selected) => {
				handlers.setSelected(node, selected);
				props.setPositionId(node.data.header.id.value);
			}}
			setOpen={openNodeAsync}
		/>
    );
}