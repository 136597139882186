import React from 'react';
import SignForm from './components/LoginForm.tsx'; 
import Main from './components/Main_page/Main_page.tsx';
import ResetPassword from './components/ResetPassword.tsx';
import PartM from './components/Modules/Part-M/Main.tsx';
import AMP from './components/Modules/Part-M/AMP/Main.tsx';
import Settings from './components/Modules/Settings/Main.tsx';
import Admin_Module from './components/Modules/Admin_module/Main.tsx';

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

function App() {
	const asid = localStorage.getItem('asid');

	return (
		<BrowserRouter>
			<Routes>
				{/*<Route path="/SignIn" element={localStorage.getItem('asid') ? <Navigate to="/" replace /> : <JoySignInSideTemplate />} />*/}
				<Route path="/SignIn" element={<SignForm/>} />
				<Route path="/ResetPassword*" element={<ResetPassword/>} />
				<Route path="/Settings" element={<Settings/>} />
				<Route path="/*" element={<Main />} />
				<Route path="/Part-M" element={<PartM />} />
				<Route path="/Part-M/AMP" element={<AMP />} />
				<Route path="/Administrative" element={<Admin_Module />} />
			</Routes>
		</BrowserRouter>
	);
};

export default App;

