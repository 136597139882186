import * as React from 'react';

//Компоненты библиотеки @mui/joy
import Box from '@mui/joy/Box';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Input from '@mui/joy/Input';
import Tooltip from '@mui/joy/Tooltip';
import Checkbox from '@mui/joy/Checkbox';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';

//Иконки
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

//Компоненты 
import AddEditeForm from './AddEditeForm.tsx';
import ErrorSuccessfulResponse from '../../../Components/ErrorSuccessfullResponse.tsx';

export default function Operator_Registration(props) {
	//#region Общие стили
	const headerStyle = {
		width: 'auto',
		fontWeight:'normal',
		fontSize:'16px',
		color:'black'
	};

	const rowStyle = {
		whiteSpace: 'nowrap', 
		width: 'auto',
		fontSize:'18px'
	};
	//#endregion

	//#region Стили отдельных элементов
	const mainContentTopBoxStyle = {
		px:1,
		pb: 1,
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		minWidth: 0,
		height: '85dvh',
		position:'relative'
	};

	const mainContentBottomBoxStyle = {
		px:1,
		pb: 1,
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		minWidth: 0,
		height: '85dvh',
		gap: 1,
	};

	const workspaceHeaderStyle = { 
		fontSize:'16px',
		fontWeight:'bold',
		color:'#000078',
		fontFamily:'Arial, sans-serif'
	};

	const searchAndFiltersBoxStyle = {
		display:'flex',
		flexWrap: 'nowrap',
		gap: 1.5,
		alignItems:'flex-end',
		justifyContent:'center'
	};
	//#endregion

	//#region Стили таблицы
	const tableSheetStyle = {
		display:'initial',
		width: '100%',
		borderRadius: '0px',
		flexShrink: 1,
		overflow: 'auto',
		minHeight: '735px',
		border: '1px solid black',
		bgcolor:'#848284'
	};

	const tableStyle = {
		'--TableCell-headBackground': '#d6d3ce',
		'--Table-headerUnderlineThickness': '1px',
		'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
		'--TableCell-paddingY': '1px',
		'--TableCell-height': '29px',
		'--TableCell-paddingX': '5px',
		'--TableCell-borderColor': '#939293',
		tableLayout: 'auto', 
		width:'auto',
		borderRadius:'0px'
	};

	const filtersStyle = {
		position:'absolute',
		width:'100%',
		top:0,
		minWidth:0,
		minHeight:0,
		height: '100%',
		px: 0.5,
		m: 0,
		borderRadius: '0px',	
		"--Input-decoratorChildHeight": "24px",
	};
	//#endregion

	//#region Style Setters
	const setShowFiltersIconStyle = (showFiltersParam: boolean) => {
        return {
			fontSize: '20px',
			transition: '0.2s',
			transform: !showFiltersParam ? 'rotate(0deg)' : 'rotate(180deg)',
		};
	}
	//#endregion

	const headers = [
		{id:'icao',name:'ICAO_Code'}, 
		{id:'iata',name:'IATA_Code'}, 
		{id:'name',name:'Name'}, 
		{id:'staOffice',name:'STA_Main_Office'}, 
		{id:'address',name:'Address'}, 
		{id:'phone',name:'Phone'}, 
		{id:'fax',name:'Fax'}, 
		{id:'email',name:'E-Mail'}, 
		{id:'contactOne',name:'Contact_1'}, 
		{id:'contactTwo',name:'Contact_2'}, 
		{id:'web',name:'WEB'},
		{id:'remarks',name:'Remarks'},
		{id:'active',name:'Active'}
	];

	const [data, setData] = React.useState<{id: number, icao: string, iata: string, name: string, staOffice: string, address: string, phone: string, fax: string, email: string, contactOne: string, contactTwo: string, web: string, remarks: string, active: string}[]>([]);
	const [filteredData, setFilteredData] = React.useState <{id: number, icao: string, iata: string, name: string, staOffice: string, address: string, phone: string, fax: string, email: string, contactOne: string, contactTwo: string, web: string, remarks: string, active: string}[]>([]);
	const [selectedRow, setSelectedRow] = React.useState({id:0});
	const [filters, setFilters] = React.useState({id:0, icao:'', iata:'', name:'', staOffice:'', address:'', phone:'', fax:'', email:'', contactOne:'', contactTwo:'', web:'', remarks:'', active:'Y'});
	const [showFilters, setShowFilters] = React.useState(false);
	const [sort, setSort] = React.useState ({lable:'id', order:'asc'});
	const [showEditor, setShowEditor] = React.useState (true);
	const [operators, setOperators] = React.useState<{ icao: string, name:string }[]>([]);
	const [staOffice, setStaOffice] = React.useState<{code:string}[]>([]);
	const [responseStatus, setResponseStatus] = React.useState({errorStatus:0, successfulStatus:''})

	//Запрос данных для таблицы
	const fetchData = async () => {
		const response = await fetch(`${props.URL}${process.env.REACT_APP_API_ADMIN}/OperatorRegistrations`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {
			const result = await response.json();
			const sortedResult =result.value.sort((a, b) => b.id - a.id);
			setData(sortedResult);
		} else {
			setResponseStatus({...responseStatus, errorStatus:response.status});
		}
	};
	
	//Запрос для получения списка операторов
	const fetchOperators = async () => {
		const response = await fetch(`${props.URL}${process.env.REACT_APP_API_ADMIN}/ASYSAirlines`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {	
			const result = await response.json();
			setOperators(result.value);
		} else {
			setResponseStatus({...responseStatus, errorStatus:response.status});
		}
	};

	//Запрос для получения списка станций
	const fetchStaOffice = async () => {
		const response = await fetch(`${props.URL}${process.env.REACT_APP_API_ADMIN}/StaRegistrations?$select=code`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {	
			const result = await response.json();
			setStaOffice(result.value);
		} else {
			setResponseStatus({...responseStatus, errorStatus:response.status});
		}
	};

	React.useEffect(() => {
		fetchData();
		fetchOperators();
		fetchStaOffice();
	}, []);
	
	//Выбор строки
	const handleRowSelection = (row) => {
		if(row.id === selectedRow.id){
			setSelectedRow({id:0});
		} else {
			setSelectedRow(row);
		}  
	};

	//Фильтрация данных
	React.useEffect(() => {
		let filtered = data;
        for (const key in filters) {
			if(key != 'active' && filters[key] != '') {
				filtered = filtered.filter(row => row[key]?.toString().toLowerCase().includes(filters[key].toString().toLowerCase()))
			} else {
				if (filters.active == 'Y') {filtered = filtered.filter(row => row.active == filters.active)}
			}
		}
        setFilteredData(filtered);

		//Сброс выделения строки, если она не попадает в отфильтрованные данные
		if(!filtered.some(obj => obj.id === selectedRow.id)){
			setSelectedRow({id:0})
		}
    }, [data, filters]);

	//Сброс фильтров
	const refreshFilters = () => {
		setFilters({id:0, icao:'', iata:'', name:'', staOffice:'', address:'', phone:'', fax:'', email:'', contactOne:'', contactTwo:'', web:'', remarks:'', active:'Y'});
		setSelectedRow({id:0});
	}

	//Изменение фильтров
	const handleChange = (property, value) => {
		setFilters({ ...filters, [property]: value });
	};

	//Сортировка данных
	const handleSort = (property, value) => {
		let filtered = filteredData
		if (property === 'id'  || property === 'idUser') {
			filtered = filtered.sort((a, b) => value === 'asc' ? a[property] - b[property] : b[property]- a[property]);
		} else {
			filtered = filtered.sort((a, b) => value === 'asc' ? a[property]?.localeCompare(b[property]) : b[property]?.localeCompare(a[property]));
		}
		setFilteredData(filtered)
		setSort({lable:property, order:value})
	};

	//Проверка наличия фильтров
	const checkFilters = () => {
		const initialState = {id:0, icao:'', iata:'', name:'', staOffice:'', address:'', phone:'', fax:'', email:'', contactOne:'', contactTwo:'', web:'', remarks:'', active:'Y'};
		return Object.keys(initialState).every(key => filters[key] === initialState[key]);
	}

	//Открытие и закрытие Editore
	const toggleEditore = () => {
		setShowEditor(prevState => !prevState);
	}

	//Создание Excel отчёта
	const createExcel = () => {
		return fetch(`${props.URL}${process.env.REACT_APP_API_ADMIN}/OperatorRegistrations/Xlsx()${filters.active === 'Y' ? `?$filter=active eq '${filters.active}'` : ''}`, {
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/vnd.ms-excel'
			},
		})
		.then((response) => {
			if (response.ok) {
				return response.blob();
			} else {
				setResponseStatus({...responseStatus, errorStatus:response.status});
			}
		})
		.then((blob) => {
			if (blob) {
				const url = window.URL.createObjectURL(blob); 
				const link = document.createElement('a'); 
				link.href = url;
				link.setAttribute('download', 'Operator_Registration.xlsx'); 
				document.body.appendChild(link); 
				link.click();
				link.remove(); 
				window.URL.revokeObjectURL(url);
			}
		})
		.catch((error) => {
			console.error('Ошибка при получении файла:', error);
		});
	};

	const rowRefs = React.useRef([]);

	const handleFocusRow = () => {
        const selectedRowIndex = filteredData.findIndex(row => row.id === selectedRow.id);
        if (selectedRowIndex !== -1 && rowRefs.current[selectedRowIndex]) {
            rowRefs.current[selectedRowIndex].focus();
        }
    };

	return (
		<React.Fragment>
			<ErrorSuccessfulResponse responseStatus={responseStatus} setResponseStatus={setResponseStatus} />
			<Box
				sx={{
					display:'grid',
					gridTemplateColumns: showEditor ? '70% 30%' : '1rf',
				}}
			>
				<Box
					component="main"
					className="MainContent"
					sx={mainContentTopBoxStyle}
				>
					<Box
						justifyContent="center"
						alignItems="center"
						sx={{ 
							position:'absolute', 
							right:0,
							mt:0.5 
						}}
					>
						<Tooltip title='Create Excel Report'>
							<IconButton
								variant='plain'
								onClick={createExcel}
								sx={{
									m:0,
								}}
							>
								<img 
									width='35px' 
									src="images/svg/excel.svg" 
									alt="" 
								/>
							</IconButton>
						</Tooltip>
						<Tooltip title='Reset Filters'>
							<IconButton
								variant='plain'
								onClick={refreshFilters}
								sx={{
									m:0,
								}}
							>
								<img 
									width='35px' 
									src="images/svg/refresh.svg" 
									alt="" 
								/>
							</IconButton>
						</Tooltip>
						<Tooltip title= {showEditor? 'Hide Editor' : 'Show Editor'}>
							<IconButton
								variant='plain'
								onClick={toggleEditore} 
								sx={{
									m:0,
									transition: '0.3s',
									transform: showEditor? 'rotate(0deg)' : 'rotate(180deg)',
								}}
							>
								<img 
									width='35px' 
									src="images/svg/arrowBack.svg" 
									alt="" 
								/>
							</IconButton>
						</Tooltip>
					</Box>	
					<Typography 
						sx={workspaceHeaderStyle}
					>
						Operator Registration List:
					</Typography>			
					<Box
						className="SearchAndFilters-tabletUp"
						sx={searchAndFiltersBoxStyle}
					>
						<Checkbox 
							variant="outlined"
							color="neutral" 
							label="Active Only"
							checked={filters.active == 'Y' ? true:false}
							onChange={(event) => handleChange('active', event.target.checked ? 'Y' : 'N')}
							sx={{
								mb:1,
								'& .css-r4cpcz-JoyCheckbox-checkbox': {
									borderRadius:0,
									'--joy-palette-neutral-outlinedBorder':'black',
								},
								'& .css-n110n' : {
									borderRadius:0,
									'--joy-palette-neutral-outlinedBorder':'black',
								},
							}}
						/>
					</Box>
					<Box
						onClick={() => setShowFilters(!showFilters)}
						sx={{
							border:'1px solid black',
							borderBottom:'none',
							height:'24px',
							display:'flex',
							alignItems:'center',
							justifyContent:'center',
							bgcolor:checkFilters()?'transparent':'#DDE7EE'
						}}
					>
						<ArrowDropDownIcon 
							sx={setShowFiltersIconStyle(showFilters)}
						/>
					</Box>
					<Sheet
						variant="outlined"
						sx={tableSheetStyle}
					>
						<Table
							borderAxis="both"
							stickyHeader
							hoverRow
							sx={tableStyle}
						>
							<thead>
								<tr>
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort('id', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'id' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform:sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
												fontWeight:filters.id != 0 ? 'bold' : 'normal'
											}}
										>
											ID
										</Typography>
									</th>
									{ headers.map((row) => (
										<th style={headerStyle}>
											<Typography
												onClick={() => handleSort(row.id, sort.order == 'asc' ? 'desc':'asc' )}
												endDecorator={<ArrowDropDownIcon />}
												sx={{
													'& svg': {
														color:sort.lable === row.id ? '#636b74' : 'transparent',
														transition: '0.2s',
														transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
													},
													fontWeight:filters[row.id] ? 'bold' : 'normal'
												}}
											>
												{row.name}
											</Typography>
										</th>
									))}
								</tr>
								{showFilters && (
									<tr>
										<th style={{padding:0}}>
											<Input
												value={filters.id}
												onChange={(event) => handleChange('id', event.target.value)}
												type="number"
												slotProps={{
													input: {
														step: 1,
														min:0
													}
												}}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.icao}
												onChange={(event) => handleChange('icao', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.iata}
												onChange={(event) => handleChange('iata', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.name}
												onChange={(event) => handleChange('name', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.staOffice}
												onChange={(event) => handleChange('staOffice', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.address}
												onChange={(event) => handleChange('address', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.phone}
												onChange={(event) => handleChange('phone', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.fax}
												onChange={(event) => handleChange('fax', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.email}
												onChange={(event) => handleChange('email', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.contactOne}
												onChange={(event) => handleChange('contactOne', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.contactTwo}
												onChange={(event) => handleChange('contactTwo', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.web}
												onChange={(event) => handleChange('web', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.remarks}
												onChange={(event) => handleChange('remarks', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{backgroundColor:'#d6d3ce'}}></th>
									</tr>
								)}
							</thead>
							<tbody>
								{filteredData.length == 0 && (
									<th colSpan={999}>
										<td style={{...rowStyle, textAlign:'center'}}>No Records Where Found</td>
									</th>
								)}
								{filteredData.map((row, index) => (
									<tr
										key={row.id}
										onClick={() => handleRowSelection(row)}
										style={{ 
											backgroundColor: selectedRow.id === row.id ? '#c0c0ff': index % 2 === 0 ? '#EDF2F7' : 'white',
											color: 'black',
										}}
										ref={el => (rowRefs.current[index] = el)}
                            			tabIndex={0}
									>
										<td style={{...rowStyle, backgroundColor: selectedRow.id === row.id ? '#c0c0ff' :'#c6c3c6'}}>{row.id}</td>
										<td style={rowStyle}>{row.icao}</td>
                                		<td style={rowStyle}>{row.iata}</td>
                                		<td style={rowStyle}>{row.name}</td>
										<td style={rowStyle}>{row.staOffice}</td>
										<td style={rowStyle}>{row.address}</td>
										<td style={rowStyle}>{row.phone}</td>
										<td style={rowStyle}>{row.fax}</td>
										<td style={rowStyle}>{row.email}</td>
										<td style={rowStyle}>{row.contactOne}</td>
										<td style={rowStyle}>{row.contactTwo}</td>
										<td style={rowStyle}>{row.web}</td>
										<td style={rowStyle}>{row.remarks}</td>
										<td style={rowStyle}>{row.active}</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Sheet>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'flex-start',
							fontSize:'12px'
						}}
					>
						Found {filteredData.length} Records
					</Box>
				</Box>
				{showEditor && (
					<Box
						component="main"
						className="MainContent"
						sx={mainContentBottomBoxStyle}
					>
						<AddEditeForm
							URL={props.URL}
							selectedRow={selectedRow} 
							setSelectedRow={setSelectedRow}
							reloadTable={fetchData}
							focusRow={handleFocusRow}
							setResponseStatus={setResponseStatus}
							operators={operators}
							staOffice={staOffice}
						/>
					</Box>
				)}
			</Box>
		</React.Fragment>
	);
}