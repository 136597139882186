import React from "react";

import URL from '../../../../../../URL.js';
import { AmpDepTreeViewProps } from "./Props/AmpDepTreeViewProps.ts";
import Tree, { useTreeState } from "../../../../../Base/TreeView/index.tsx";

import { AmpPartEffIconInterpreter } from "./IconInterpreters/AmpPartEffIconInterpreter.ts";
import { IIconContext } from "../../../../../Base/TreeView/IconInterpterer/IIconContext.ts";
import { Interpreter } from "../../../../../Base/TreeView/IconInterpterer/Interpreter.ts";
import { ITreeViewIcon } from "../../../../../Base/TreeView/IconInterpterer/ITreeViewIcon.ts";

export default function AmpPartEffictivityTree(props : AmpDepTreeViewProps) {
    const [responseStatus, setResponseStatus] = React.useState({
        errorStatus:0, 
        successfulStatus:''
    });
    const [treeData, setTree] = React.useState<{
        id: number, 
        image: ITreeViewIcon
    }[]>([]);
    const { required, handlers } = useTreeState({ 
        id: 'tree', 
        data: treeData, 
        defaultOpened: [1], 
        multipleSelect: false 
    });

    // Задание интерпретатора для выбора иконок и базовой структуры дерева
    const iconInterpreter = new AmpPartEffIconInterpreter();
    const treeBaseData: [] = [];
    
    // Обновление данных при выборе position 
    React.useEffect(() => {
        if (props.positionId > 0) {
            fetchTreeRootData();
            fetchTreeData(iconInterpreter);

            const node = handlers.getNode(1);
            if (node != null && !node?.isOpened())
               handlers.setOpen(node, true);
        }
        else
            fetchTreeStartData();
    }, [props.positionId]);

    // Корневой узел до выбора position
    const treeDataRootUnselected = [{
        isRootData: true,
        id: 1,
        image: iconInterpreter.Interpret(getTreeViewIconParams(undefined, true)),
        header: { id: { value: 'Select IPC Position' } },
        children: []
    }];
    // Корневой узел после выбора position
    const treeDataRootSelected = [{
        isRootData: true,
        id: 1,
        image: iconInterpreter.Interpret(getTreeViewIconParams(undefined, undefined, true)),
        header: { id: { value: 'Part Effectivity:' } },
        children: []
    }];

    // Получение первичного заголовка до выбора position
    const fetchTreeStartData = async () => {
        Object.assign(treeBaseData, treeDataRootUnselected);
        setTree(treeBaseData);
    }

    // Получение первичного заголовка после выбора position
    const fetchTreeRootData = async () => {
        Object.assign(treeBaseData, treeDataRootSelected);
    }
    
    // Получение общей информации для дерева
    const fetchTreeData = async (iconInterpreter: Interpreter<IIconContext>) => {
        if (treeBaseData?.length == 0) 
            return;

        const response = await fetchResponseAsync();
        if (response.ok) {
            const result = await response.json();
            treeBaseData[0].children = [];
    
            result?.forEach(element => {
                const treeViewIconParams = getTreeViewIconParams(element);
				const headerParams = getNodeHeaderParams(element);

                const child = getChildNode(
					iconInterpreter.Interpret(treeViewIconParams),
					headerParams
				);
                treeBaseData[0].children.push(child);
            });
    
            setTree(treeBaseData);
        } 
        else {
            setResponseStatus({...responseStatus, errorStatus:response.status});
        }
    };

    const fetchResponseAsync = async (isChild: boolean = false, node: any = undefined): Promise<Response> => {
        const url = isChild 
            ? ''
            : `${URL}${process.env.REACT_APP_API_PARTM}/AmpModelPns/PartEffectivity(ampId=${props.ampId},positionId=${props.positionId})`;

        const headers = { 
                headers: { 'Authorization': `Bearer ${localStorage.getItem('asid')}` }
            };

        return await fetch(url, headers);
    }

    function getTreeViewIconParams(element: any, isStartState: boolean = false, isRoot: boolean = false): any {
		return { 
			hasTreatment: element?.hasTreatment,
            isStartState: isStartState,
            isRoot: isRoot
		};
	}

	function getNodeHeaderParams(element: any): any {
        const mainPnValue = `Preferable: ${element.mainPn}`;

		return { 
			id: { value: element.id, width: 70 }, 
            pn: { value: element.pn, width: 120 }, 
            description: { value: element.description, width: 240 }, 
            mainPn: { value: mainPnValue, width: 140 }
		};
	}

    function getChildNode(interpreterResult: any, headerParams: any, conditionItem: any = undefined): any {
        return {
            id: 2,
            image: interpreterResult,
            header: headerParams
        };
    }

    return (
      	<Tree
			{...required}
			{...handlers}
			gapMode="padding" 
			depthGap={20}
		/>
    );
}