import * as React from 'react';

import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Input from '@mui/joy/Input';
import Tooltip from '@mui/joy/Tooltip';
import Checkbox from '@mui/joy/Checkbox';
import Textarea from '@mui/joy/Textarea';
import FormLabel from '@mui/joy/FormLabel';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import IconButton from '@mui/joy/IconButton';
import Autocomplete from '@mui/joy/Autocomplete';

import ConfirmAction from '../../../Components/ConfirmAction.tsx';

export default function AddEditForm(props) {
	//Стили
	const inputStyle = { borderRadius: 0, mx: 1, height: 30, minHeight: 0, border:'1px solid black', color:'#000084', fontWeight:'bold', fontSize:'18px', lineHeight:1  }
	const labelStyle = { mx: 1, my: 0, p: 0,fontSize:'16px' }
	const formControlStyle = { my: 1 }

	const [countries, setCountries] = React.useState<{name: string}[]>([]) 
	const [editedData, setEditedData] = React.useState({id:0, code:'', name:'', country:'', remarks:'', activate:''});
	const [showConfirm, setShowConfirm] = React.useState(false);
	const [confirmActionType, setConfirmActionType] = React.useState('');
	const windowName = 'STA'
	const [isCodeValid, setIsCodeValid] = React.useState(true);
	const [meta, setMeta] = React.useState([{name:'',max:0}]);

	//Внесение изменений в data
	const handleChange = (property, value) => {
		//Проверка максимальной длинны строки
		const maxLengthDefault = 255;
		let maxLength = (meta.find(item => item.name.toLowerCase() === property.toLowerCase())?.max ?? "") || maxLengthDefault;
		if (value.length > maxLength) return;	
		
		setEditedData({ ...editedData, [property]: value });
	};

	React.useEffect(() => {
		setEditedData(props.selectedRow);
		setIsCodeValid(true);
	}, [props.selectedRow]);

	//Запрос ограничений для полей
	React.useEffect(() => {
		const fetchMeta = async () => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_ADMIN}/StaRegistrations/Meta()`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setMeta(result);
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		};
		fetchMeta();
	}, []);

	React.useEffect(() => {
		const fetchCountry = async () => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_ADMIN}/ASYSCountries`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setCountries(result.value);
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		};
		fetchCountry();
	}, []);

	//Запрос проверки валидности Code
	const checkValidCode = async () => {
		const response = await fetch(`${props.URL}${process.env.REACT_APP_API_ADMIN}/StaRegistrations/ValidCode()?sta='${editedData.code}'`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {
			const result = await response.json();
			setIsCodeValid(result.value);
			return result.value;
		} else {
			props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
		}
	};

	const handleAction = async (type) => {
		setConfirmActionType(type);
		if (type === 'Add'){
			if (await checkValidCode()) {
				setShowConfirm(true);
			}
		} else if (type === 'Update') {
			if(editedData.code === props.selectedRow.code || await checkValidCode()){
				setIsCodeValid(true);
				setShowConfirm(true);
			}
		} else if (type === 'Delete'){
			setShowConfirm(true);
		}
		
	};

	//Обработчик события добавления новой записи
	const handleAdd = () => {
		fetch(
			`${props.URL}${process.env.REACT_APP_API_ADMIN}/StaRegistrations`, 
			{
				method: 'POST',
				headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/json'
				},
				body: JSON.stringify(editedData)
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Add'});
			}
			return response.json();
		})
		.then(data => {
			props.setSelectedRow(data)
			props.reloadTable();
			props.focusRow();
		})
		.catch(error => console.error('Ошибка создания группы:', error));
	}

	//Обработчик события обновления выбранной записи
	const handleUpdate = () => {
		fetch(
			`${props.URL}${process.env.REACT_APP_API_ADMIN}/StaRegistrations/${props.selectedRow.id}`, 
			{
				method: 'PATCH',
				headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/json'
				},
				body: JSON.stringify(editedData)
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Update'});
			}
			props.reloadTable();
			props.focusRow();
			return response.json();
		})
		.catch(error => console.error('Ошибка обновления группы:', error));
	}

	//Обработчик события удаления выбранной записи
	const handleDelete =() => {
		fetch(
			`${props.URL}${process.env.REACT_APP_API_ADMIN}/StaRegistrations/${props.selectedRow.id}`, 
			{
				method: 'DELETE',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				}
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Delete'});
			}
			props.setSelectedRow({id:0})
			setEditedData({})
			props.reloadTable();
			return response.json();
		})
		.catch(error => console.error('Ошибка удаления группы:', error));

	}

	return (
		<Box>
			{showConfirm && (
				<ConfirmAction 
					actionType={confirmActionType} 
					windowName={windowName} 
					onConfirmAdd={handleAdd} 
					onConfirmUpdate={handleUpdate} 
					onConfirmDelete={handleDelete} 
					setShowConfirm={setShowConfirm}
					showConfirm={showConfirm}
				/>
			)}
			<Typography 
				sx={{ 
					ml: 1,
					fontSize:'16px',
					fontWeight:'bold',
					color:'#000078',
					fontFamily:'Arial, sans-serif'
				}}
			>
				STA Editor:
			</Typography>
			<Tooltip title='Add'>
				<IconButton
					variant='plain'
					disabled={editedData.code && editedData.name && editedData.country ? false : true}
					onClick={()=>handleAction('Add')}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px' 
						src="images/svg/add.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Tooltip title='Update'>
				<IconButton 
					variant='plain'
					disabled={props.selectedRow.id && editedData.code && editedData.name && editedData.country ? false : true}
					onClick={()=>handleAction('Update')}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px' 
						src="images/svg/save.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Tooltip title='Delete'>
				<IconButton 
					variant='plain'
					disabled={props.selectedRow.id ? false : true}
					onClick={()=>handleAction('Delete')}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px'  
						src="images/svg/trashcan.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Grid container spacing={0} sx={{ flexGrow: 1 }}>
				<Grid xs={8}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={{ ...labelStyle, width: '100%', display: 'flex' }}>
							<span style={{ display: 'flex', alignItems: 'center' }}>
								<Typography>STA Code:</Typography>
								<Typography sx={{ color: '#C41C1C', fontSize: '1em', marginLeft: '4px' }}>*</Typography>
							</span>
							<Typography 
								color='danger'
								sx={{ 
									ml: 'auto', 
									mr:2,
									fontSize:14,
									display:isCodeValid === false? 'flex' :'none'
								}}
							>
								Code is already exist
							</Typography>
						</FormLabel>
						<Input
							color={!isCodeValid ? 'danger' : 'primary'}
							sx={{...inputStyle, border:isCodeValid ? '1px solid black':'1px solid #C41C1C', color: isCodeValid ? '#000084':'#C41C1C'}} 
							value={editedData?.code || ''}
							onChange={(event) => handleChange('code', event.target.value)}
						/>
					</FormControl>
				</Grid>
				<Grid xs={4} sx={{display:'flex', justifyContent:'center'}}>
					<Checkbox 
						label="Activate"
						variant="outlined"
						color='danger'
						defaultChecked 
						checked={editedData.activate == 'Y' ? true:false}
						onChange={(event) => handleChange('activate', event.target.checked ? 'Y' : 'N')}
						sx={{
							mt:1,
							color:'#840000',
							fontWeight:'bold'
						}}
					/>
				</Grid>
				<Grid xs={12}>
					<FormControl sx={formControlStyle} required>
						<FormLabel sx={labelStyle}>
							STA Name:
						</FormLabel>
						<Input
							size="sm"
							sx={inputStyle}
							value={editedData?.name || ''}
							onChange={(event) => handleChange('name', event.target.value)}
						/>
					</FormControl>
				</Grid>
				<Grid xs={12}>
					<FormControl sx={formControlStyle} required>
						<FormLabel sx={labelStyle}>
							STA Country:
						</FormLabel>
						<Autocomplete
							options={countries.map(data=> ({ label: data.name }))}
							value={countries.find(item => item?.name?.toLowerCase() === editedData?.country?.toLowerCase()) ? { label: editedData.country } : null}
							sx={inputStyle}
							onChange={(event, value) => handleChange('country', value ? value.label : '')}
						/>
					</FormControl>
				</Grid>
				<Grid xs={12}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>
							Remarks:
						</FormLabel>
						<Textarea
							maxRows={10}
							value={editedData?.remarks || ''}
							sx={{
								...inputStyle, 
								height: '250px', 
								lineHeight: '1.2'
							}}
							onChange={(event) => handleChange('remarks', event.target.value)}
						>
						</Textarea>
					</FormControl>
				</Grid>
			</Grid>
		</Box>
	);
}
