import * as React from 'react';

//Компоненты библиотеки @mui/joy
import Box from '@mui/joy/Box';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Input from '@mui/joy/Input';
import Tooltip from '@mui/joy/Tooltip';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import Autocomplete from '@mui/joy/Autocomplete';

import CircularProgress from '@mui/material/CircularProgress';

//Иконки
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

//Компоненты 
import AddEditeForm from './AddEditeForm';
import ErrorSuccessfulResponse from '../../../../Components/ErrorSuccessfullResponse.tsx';

export default function Engine_Type(props) {

	//Стили
	const headerStyle = {width: 'auto',fontWeight:'normal',fontSize:'16px',color:'black',};
	const rowStyle = {whiteSpace: 'nowrap', width: 'auto',fontSize:'18px'};
	const filtersStyle = {position:'absolute',width:'100%',top:0,minWidth:0,minHeight:0,height:'100%',px: 0.5,m: 0,borderRadius: '0px',"--Input-decoratorChildHeight": "24px",};

	const headers =[{id:'type',name:'Engine_Type'}, {id:'family',name:'Engine_Family'},{id:'manufacturer',name:'Manufacturer'},{id:'remarks',name:'Remarks'},{id:'apu',name:'APU'},{id:'numOfFanBlades',name:'Num_Fan_Blades'}]

	const [data, setData] = React.useState<{id:number, type:string, family:string, manufacturer:string, remarks:string, apu:string, numOfFanBlades:number}[]>([]);
	const [filteredData, setFilteredData] = React.useState <{id:number, type:string, family:string, manufacturer:string, remarks:string, apu:string, numOfFanBlades:number}[]>([]);
	const [selectedRow, setSelectedRow] = React.useState({id:0});
	const [filters, setFilters] = React.useState({id:0, type:'', family:'', manufacturer:'', remarks:'', apu:'', numOfFanBlades:0});
	const [showFilters, setShowFilters] = React.useState(false);
	const [sort, setSort] = React.useState ({lable:'id', order:'asc'});
	const [engineFamilies, setEngineFamilies] = React.useState ([{}]);
	const [showEditor, setShowEditor] = React.useState (true);

	const [responseStatus, setResponseStatus] = React.useState({errorStatus:0, successfulStatus:''})

	//Запрос данных для таблицы
	const fetchData = async () => {
		const response = await fetch(`${props.URL}${process.env.REACT_APP_API_ADMIN}/EngineTypes`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {
			const result = await response.json();
			setData(result.value);
		} else {
			setResponseStatus({...responseStatus, errorStatus:response.status});
		}
	};

	//Запрос списка Engine Families
	const fetchFamilies = async () => {
		const response = await fetch(`${props.URL}${process.env.REACT_APP_API_ADMIN}/EngineFamilies?$select=family,manufacturer`,
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {	
			const result = await response.json();
			setEngineFamilies(result.value);
		} else {
			setResponseStatus({...responseStatus, errorStatus:response.status});
		}
	};

	React.useEffect(() => {	
		fetchData();
		fetchFamilies();
	}, []);

	//Выбор строки
	const handleRowSelection = (row) => {
		if(row.id === selectedRow.id){
			setSelectedRow({id:0});
		} else {
			setSelectedRow(row);
		}  
	};
	
	//Фильтрация данных
	React.useEffect(() => {
		let filtered = data;
		for (const key in filters) {
			if(filters[key] != ''){
				filtered = filtered.filter(row => row[key]?.toString().toLowerCase().includes(filters[key].toString().toLowerCase()))
			}
		}
        setFilteredData(filtered);
		
		//Сброс выделения строки, если она не попадает в отфильтрованные данные
		if(!filtered.some(obj => obj.id === selectedRow.id)){
			setSelectedRow({id:0})
		}
    }, [data, filters]);

	//Сброс фильтров
	const refreshFilters = () => {
		setFilters({id:0, type:'', family:'', manufacturer:'', remarks:'', apu:'', numOfFanBlades:0});
		setSelectedRow({id:0});
	}

	//Изменение фильтров
	const handleChange = (property, value) => {
		setFilters({ ...filters, [property]: value });
	};

	//Сортировка данных
	const handleSort = (property, value) => {
		let filtered = filteredData
		if (property === 'id' || property === 'numOfFanBlades'){
			filtered = filtered.sort((a, b) => value === 'asc' ? a[property] - b[property] : b[property]- a[property]);
		} else {
			filtered = filtered.sort((a, b) => value === 'asc' ? a[property]?.localeCompare(b[property]) : b[property]?.localeCompare(a[property]));
		}
		setFilteredData(filtered)
		setSort({lable:property, order:value})
	};

	//Проверка наличия фильтров
	const checkFilters = () => {
		const initialState = {id:0, type:'', family:'', manufacturer:'', remarks:'', apu:'', numOfFanBlades:0};
		return Object.keys(initialState).every(key => filters[key] === initialState[key]);
	}

	//Открытие и закрытие Editore
	const toggleEditore = () => {
		setShowEditor(prevState => !prevState);
	}
	
	//Создание Excel отчёта
	const createExcel = () => {
		return fetch(`${props.URL}${process.env.REACT_APP_API_ADMIN}/EngineTypes/Xlsx()`, {
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/vnd.ms-excel'
			},
		})
		.then((response) => {
			if (response.ok) {
				return response.blob();
			} else {
				setResponseStatus({...responseStatus, errorStatus:response.status});
			}
		})
		.then((blob) => {
			if (blob) {
				const url = window.URL.createObjectURL(blob); 
				const link = document.createElement('a'); 
				link.href = url;
				link.setAttribute('download', 'Engine_Type.xlsx'); 
				document.body.appendChild(link); 
				link.click();
				link.remove(); 
				window.URL.revokeObjectURL(url);
			}
		})
		.catch((error) => {
			console.error('Ошибка при получении файла:', error);
		});
	};

	//Создание PDF отчёта
	{/*const createReport = () => {
		setReportLoading(true);
		return fetch(`${URL}/reports/Reports`, {
			method: 'POST',
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/json'
			},
			body:JSON.stringify({
				"templateName": "Templates/PostgreSQL/Admin/UserRegistrationList",
				"isExportAsPicture": false,
				"parameters": [
					{
						"name": 'userId',
						"value": `${filters.login}`
					},
					{
						"name": 'name',
						"value": `${filters.name}`
					},
					{
						"name": 'code',
						"value": `${filters.code}`
					},
					{
						"name": 'userGroup',
						"value": `${filters.userGroup}`
					},
					{
						"name": 'licence',
						"value": `${filters.licenceNum}`
					},
					{
						"name": 'skill',
						"value": `${filters.skill}`
					},
					{
						"name": 'ac',
						"value": ``
					},
					{
						"name": 'engCategories',
						"value": ``
					},
					{
						"name": 'secGroup',
						"value": `${filters.securityGroup}`
					},
					{
						"name": 'active',
						"value": `${filters.activate}`
					},
					{
						"name": 'storeDbName',
						"value": `Store`
					},
				]
			})
		})
		.then((response) => {
			if (response.ok) {
				return response.blob();
			} else {
				errors(response.status);
				setReportLoading(false);
			}
		})
		.then((blob) => {
			if (blob) {
				const url = window.URL.createObjectURL(blob); 
				const link = document.createElement('a'); 
				link.href = url;
				link.setAttribute('download', 'UserRegistrationList.pdf'); 
				document.body.appendChild(link); 
				link.click();
				link.remove(); 
				window.URL.revokeObjectURL(url);

				setReportLoading(false);
			} else {
				setReportLoading(false);
			}
		})
		.catch((error) => {
			console.error('Ошибка при получении файла:', error);
			setReportLoading(false);
		});
	};
*/}

	return (
		<React.Fragment>
			<ErrorSuccessfulResponse responseStatus={responseStatus} setResponseStatus={setResponseStatus} />
			<Box
				sx={{
					display:'grid',
					gridTemplateColumns: showEditor ? '65% 35%' : '1fr',
				}}
			>
				<Box
					component="main"
					className="MainContent"
					sx={{
						px:1,
						pb: 1,
						flex: 1,
						display: 'flex',
						flexDirection: 'column',
						minWidth: 0,
						position:'relative',
						height: '40dvh',
					}}
				>
					<Box
						justifyContent="center"
						alignItems="center"
						sx={{
							position:'absolute',
							right:0,
							mt:0.5
						}}
					>
						<Tooltip title='Create Excel Report'>
							<IconButton
								variant='plain'
								onClick={createExcel}
								sx={{
									m:0,
								}}
							>
								<img 
									width='35px' 
									src="images/svg/excel.svg" 
									alt="" 
								/>
							</IconButton>
						</Tooltip>
						<Tooltip title='Reset Filters'>
							<IconButton
								variant='plain'
								onClick={refreshFilters}
								sx={{
									m:0,
								}}
							>
								<img 
									width='35px' 
									src="images/svg/refresh.svg" 
									alt="" 
								/>
							</IconButton>
						</Tooltip>
						<Tooltip title= {showEditor? 'Hide Editor' : 'Show Editor'}>
							<IconButton
								variant='plain'
								onClick={toggleEditore} 
								sx={{
									m:0,
									transition: '0.3s',
									transform: showEditor? 'rotate(0deg)' : 'rotate(180deg)',
								}}
							>
								<img 
									width='35px' 
									src="images/svg/arrowBack.svg" 
									alt="" 
								/>
							</IconButton>
						</Tooltip>
					</Box>		
					<Typography 
						sx={{ 
							fontSize:'16px',
							fontWeight:'bold',
							color:'#000078',
							fontFamily:'Arial, sans-serif'
						}}
					>
						Engine Type Registration List:
					</Typography>			
					<Box
						onClick={() => setShowFilters(!showFilters)}
						sx={{
							border:'1px solid black',
							borderBottom:'none',
							height:'24px',
							display:'flex',
							alignItems:'center',
							justifyContent:'center',
							mt:3,
							bgcolor:checkFilters()?'transparent':'#DDE7EE'
						}}
					>
						<ArrowDropDownIcon 
							sx={{
								fontSize: '20px',
								transition: '0.2s',
								transform: !showFilters ? 'rotate(0deg)' : 'rotate(180deg)',
							}}
						/>
					</Box>
					<Sheet
						variant="outlined"
						sx={{
							display:'initial',
							width: '100%',
							borderRadius: '0px',
							flexShrink: 1,
							overflow: 'auto',
							minHeight: '290px',
							border: '1px solid black',
							bgcolor:'#848284',
							
						}}
					>
						<Table
							borderAxis="both"
							stickyHeader
							hoverRow
							sx={{
								'--TableCell-headBackground': '#d6d3ce',
								'--Table-headerUnderlineThickness': '1px',
								'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
								'--TableCell-paddingY': '1px',
								'--TableCell-height': '28px',
								'--TableCell-paddingX': '5px',
								'--TableCell-borderColor': '#939293',
								tableLayout: 'auto', 
								width:'auto',
								borderRadius:'0px',
							}}
						>
							<thead>
								<tr>
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort('id', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'id' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform:sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
												fontWeight:filters.id != 0 ? 'bold' : 'normal'
											}}
										>
											ID
										</Typography>
									</th>
									{headers.map((row) => (
										<th style={headerStyle}>
											<Typography
												onClick={() => handleSort(row.id, sort.order == 'asc' ? 'desc':'asc' )}
												endDecorator={<ArrowDropDownIcon />}
												sx={{
													'& svg': {
														color:sort.lable === row.id ? '#636b74' : 'transparent',
														transition: '0.2s',
														transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
													},
													fontWeight:filters[row.id] ? 'bold' : 'normal'
												}}
											>
												{row.name}
											</Typography>
										</th>
									))}
								</tr>
								{showFilters && (
									<tr>
										<th style={{padding:0}}>
											<Input
												value={filters.id}
												onChange={(event) => handleChange('id', event.target.value)}
												type="number"
												slotProps={{
													input: {
														step: 1,
														min:0
													}
												}}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.type}
												onChange={(event) => handleChange('type', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.family}
												onChange={(event) => handleChange('family', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.manufacturer}
												onChange={(event) => handleChange('manufacturer', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.remarks}
												onChange={(event) => handleChange('remarks', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Autocomplete
												options={[...new Set(data.map(data => data.apu))].filter(row => row).map(apu => ({ label: apu}))}
												onChange={(event, value) => handleChange('apu', value ? value.label : '')}
												value={filters.apu === '' ? null : { label: filters.apu }}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.numOfFanBlades}
												onChange={(event) => handleChange('numOfFanBlades', event.target.value)}
												type="number"
												slotProps={{
													input: {
														step: 1,
														min:0
													}}}
												sx={filtersStyle}
											/>
										</th>
									</tr>
								)}
							</thead>
							<tbody>
								{filteredData.length == 0 && (
									<th colSpan={999}>
										<td style={{...rowStyle, textAlign:'center'}}>No Records Where Found</td>
									</th>
								)}
								{filteredData.map((row, index) => (
									<tr
										key={row.id}
										onClick={() => handleRowSelection(row)}
										style={{ 
											backgroundColor: selectedRow.id === row.id ? '#c0c0ff': index % 2 === 0 ? '#EDF2F7' : 'white',
											color: 'black',
										}}
									>
										<td style={{...rowStyle, backgroundColor: selectedRow.id === row.id ? '#c0c0ff' :'#c6c3c6'}}>{row.id}</td>
										<td style={rowStyle}>{row.type}</td>
										<td style={rowStyle}>{row.family}</td>
										<td style={rowStyle}>{row.manufacturer}</td>
										<td style={rowStyle}>{row.remarks}</td>
										<td style={rowStyle}>{row.apu}</td>
										<td style={rowStyle}>{row.numOfFanBlades}</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Sheet>
				</Box>
				{showEditor && (
					<Box
						component="main"
						className="MainContent"
						sx={{
							px:1,
							pb: 1,
							flex: 1,
							display: 'flex',
							flexDirection: 'column',
							minWidth: 0,
							//height: '85dvh',
							gap: 1,
						}}
					>
						<AddEditeForm
							URL={props.URL}
							selectedRow={selectedRow} 
							reloadTable={fetchData}
							setResponseStatus={setResponseStatus}
							engineFamilies={engineFamilies}
						/>
					</Box>
				)}
			</Box>
		</React.Fragment>
	);
}
