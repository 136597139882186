import * as React from 'react';

import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import Table from '@mui/joy/Table';
import Button from '@mui/joy/Button';
import Checkbox from '@mui/joy/Checkbox';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';

import CloseIcon from '@mui/icons-material/Close';

export default function AircraftsUtilites(props) {
    const [data, setData] = React.useState<{aircraftId:number, hasAccess:boolean, serialNumber:string, acReg:string, family:string, acType:string, icao:string, mro:string, staLocation:string}[]>([]);
	const [editedData, setEditedData] = React.useState<{aircraftId:number, hasAccess:boolean, serialNumber:string, acReg:string, family:string, acType:string, icao:string, mro:string, staLocation:string}[]>([]);
	const [countSelected, setCountSelected] = React.useState(0);
	const [defData, setDefData] = React.useState([{}]);
	let selectedRow;
	
	const handleClose = () => {
		props.setOpen(false);
	};

	let xcenter = (window.innerWidth/2)-300;
	let ycenter = (window.innerHeight/2)-300;

	const [isDragging, setIsDragging] = React.useState(false);
  	const [position, setPosition] = React.useState({ x: xcenter, y: ycenter });
  	const [startPosition, setStartPosition] = React.useState({ x: 0, y: 0 });
	const handleMouseDown = (e) => {
		setIsDragging(true);
		setStartPosition({
			x: e.clientX - position.x,
			y: e.clientY - position.y,
		});
	};
	
	const handleMouseMove = (e) => {
		if (!isDragging) return;
		setPosition({
			x: e.clientX - startPosition.x,
			y: e.clientY - startPosition.y,
		});
	};
	
	const handleMouseUp = () => {
		setIsDragging(false);
	};

	const fetchData = async () => {
		try {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_ADMIN}/UsersAircrafts/AircraftAccess(exceptFamilies=[])?user='${props.selectedUser.user}'&icao='${props.selectedUser.icao}'`, { headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			const result = await response.json();
			setData(result);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

    React.useEffect(() => {
		fetchData();
	}, []);

	React.useEffect(() => {
		const def = editedData.filter((item) => {
			const aircraftItem = data.find((aircraft) => aircraft.aircraftId === item.aircraftId);
			return aircraftItem ? item.hasAccess !== aircraftItem.hasAccess : true;
		}).map((item) => ({
			aircraftId: item.aircraftId,
			userId: props.selectedUser.idUser,
			addFlag: item.hasAccess,
		}));
		setDefData(def)
	}, [editedData]);
	
	const handleSelectAll = (event) => {
		setEditedData(prevState => prevState.map(item => true ? { ...item, hasAccess: event.target.checked }: item))
	};

	const handleCheckboxChange = (event) => {
		setEditedData(prevState => prevState.map(item => item.aircraftId === selectedRow.aircraftId ? { ...item, hasAccess: event.target.checked }: item))
	};

	React.useEffect(() => {
		setEditedData(data);
	}, [data]);

	React.useEffect(() => {
		const countSelectedRow = () => {
			let i = 0;
			editedData.map((row) => (
				row.hasAccess &&
				i++
			))
			setCountSelected(i)
		};
		countSelectedRow();
	}, [editedData]);

	const handleUpdate = () => {
		fetch(
			`${props.URL}${process.env.REACT_APP_API_ADMIN}/UsersAircrafts/ModifyAircraftsAccesses`, 
			{
				method: 'POST',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				},
				body: JSON.stringify(defData)
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Add'});
			}
			fetchData();
			return response.json();
		})
		.catch(error => console.error('Ошибка создания группы:', error));
	}

	return (
		<React.Fragment>
			<div
				onClick={handleClose}
				style={{
					position: 'fixed',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					backgroundColor: 'rgba(0, 0, 0, 0.3)',
					zIndex: 10,
					overflow:'hidden',
				}}
       		/>
				<Sheet
					variant="plain"
					sx={{
						height:'600px',
						width:'600px',
						borderRadius: '0px',
						position: 'absolute',
						border:'1px solid black',
						zIndex:11,
						left: position.x,
						top: position.y,
					}}
				>
					<Box
						sx={{
							display:'grid',
							gridTemplateRows:'30px 570px'
						}}
					>
						<Box
							onMouseDown={handleMouseDown}
							onMouseMove={handleMouseMove}
							onMouseUp={handleMouseUp}
							sx={{
								display:'flex',
								alignItems:'center',
								background:'#08246b',
								cursor: isDragging ? 'grabbing' : 'grab',
							}}
						>
							<Typography 
								sx={{
									fontSize:'15px',
									color:'white',
                                    ml:1
								}}
							>
								SECURITY-USERS-AIRCRAFTS UTILITY
							</Typography>
							<IconButton 
							 	onClick={handleClose}
								sx={{
									mr:1,
									ml:'auto',
									'--Icon-color':'white',
									height:'19px',
									minHeight:'0px',
									width:'19px',
									minWidth:'0px'
								}}
							>
								<CloseIcon/>
							</IconButton>
						</Box>
						<Box
							sx={{
								display:'grid',
								gridTemplateRows:'60px 445px',
								
							}}
						>
                           <Box
                                sx={{
									display:'flex',
									justifyContent: 'space-between',
									alignItems:'center'
                                }}
                            >
								<Typography
									sx={{
										color:'black',
										fontWeight:'bold',
										fontSize:'20px',
										m:1,
									}}
								>
									User: {props.selectedUser.user}
								</Typography>
								<Typography
									sx={{
										color:'black',
										fontWeight:'bold',
										fontSize:'20px',
										m:1,
									}}
								>
									Aircraft family: ALL
								</Typography>
                            </Box>
                            <Box
                                sx={{
                                    borderTop:'1px solid black',
									borderBottom:'1px solid black',
                                }}
                            >
								<Box
									sx={{
										height:'30px',
										display:'flex',
										justifyContent: 'space-between',
										alignItems:'center'
									}}
								>
									<Typography
										sx={{
											color:'black',
											fontWeight:'bold',
											fontSize:'16px',
											m:1,
										}}
									>
										Actual aircrafts for user: 
									</Typography>
									<Checkbox
										label='Select ALL'
										variant="outlined"
										color='neutral'
										checked={countSelected > 0 && countSelected == editedData.length}
										indeterminate={countSelected > 0 && countSelected < editedData.length}
										onChange={handleSelectAll}
										sx={{
											fontSize:'16px',
											mr:1,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											fontWeight:'bold',
											'--Checkbox-size':'15px',
										}}
									/>
								</Box>
								<Sheet
									className="OrderTableContainer"
									variant="outlined"
									sx={{
										minWidth: '0px',
										borderRadius: '0px',
										flexShrink: 1,
										overflow: 'auto',
										minHeight: 0,
										width:'597px',
										height:'413px',
									}}
								>
									<Table
										aria-labelledby="tableTitle"
										borderAxis="both"
										stripe="even"
										stickyHeader
										hoverRow
										sx={{
											'--TableCell-headBackground': '#393c42',
											'--Table-headerUnderlineThickness': '1px',
											'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
											'--TableCell-paddingY': '1px',
											"--TableCell-height": "12px",
											'--TableCell-paddingX': '5px',
											'--TableCell-borderColor': '#c6bebd',
											'--TableRow-stripeBackground':'#efefef',
											tableLayout: 'auto', 
											width:'auto',
											borderRadius:'0px',
										}}
									>
										<thead>
											<tr>
												<th style={{width: 'auto',fontWeight:'normal',fontSize:'18px',color:'white'}}>Has rights</th>
												<th style={{width: 'auto',fontWeight:'normal',fontSize:'18px',color:'white'}}>AC_SN:</th>
												<th style={{width: 'auto',fontWeight:'normal',fontSize:'18px',color:'white'}}>AC_REG:</th>
												<th style={{width: 'auto',fontWeight:'normal',fontSize:'18px',color:'white'}}>AC_Family:</th>
												<th style={{width: 'auto',fontWeight:'normal',fontSize:'18px',color:'white'}}>AC_Type:</th>
												<th style={{width: 'auto',fontWeight:'normal',fontSize:'18px',color:'white'}}>Operator_Code_ICAO:</th>
												<th style={{width: 'auto',fontWeight:'normal',fontSize:'18px',color:'white'}}>MRO:</th>
												<th style={{width: 'auto',fontWeight:'normal',fontSize:'18px',color:'white'}}>STA_Location:</th>
											</tr>
										</thead>
										<tbody>
											{editedData.map((row) => (
												<tr
													key={row.aircraftId}
													onClick={() => selectedRow = row}
													style={{ 
														//backgroundColor: selectedRow === row ? '#f0f4f8' : 'white',
													}}
												>
													<td style={{ whiteSpace: 'nowrap', width: 'auto',fontSize:'18px',textAlign: 'center' }}>
														<Checkbox 
															variant="outlined"
															color="neutral"
															checked={row.hasAccess}
															onChange={handleCheckboxChange}
															sx={{
																mt:1,
																color:'#840000',
																fontWeight:'bold',
																'& .css-r4cpcz-JoyCheckbox-checkbox': {
																	borderRadius:0,
																	'--joy-palette-neutral-outlinedBorder':'black',
																},
																'& .css-n110n' : {
																	borderRadius:0,
																	'--joy-palette-neutral-outlinedBorder':'black',
																},
															}}
														/>
													</td>
													<td style={{ whiteSpace: 'nowrap', width: 'auto',fontSize:'18px' }}>{row.serialNumber}</td>
													<td style={{ whiteSpace: 'nowrap', width: 'auto',fontSize:'18px' }}>{row.acReg}</td>
													<td style={{ whiteSpace: 'nowrap', width: 'auto',fontSize:'18px' }}>{row.family}</td>
													<td style={{ whiteSpace: 'nowrap', width: 'auto',fontSize:'18px' }}>{row.acType}</td>
													<td style={{ whiteSpace: 'nowrap', width: 'auto',fontSize:'18px' }}>{row.icao}</td>
													<td style={{ whiteSpace: 'nowrap', width: 'auto',fontSize:'18px' }}>{row.mro}</td>
													<td style={{ whiteSpace: 'nowrap', width: 'auto',fontSize:'18px' }}>{row.staLocation}</td>
												</tr>
											))}
										</tbody>
									</Table>     
								</Sheet>
                            </Box>
							<Box
                                sx={{
									display:'flex',
									justifyContent: 'space-between',
									alignItems:'center'
                                }}
                            >
								<Button
									onClick={handleClose}
									sx={{
										m:1,
										width:'130px',
										bgcolor:'#ff8273',
										boxShadow:'0px 0px 6px black',
										borderRadius:'3px'
									}}
								>
									Cancel
								</Button>
								<Button
									onClick={handleUpdate}
									sx={{
										m:1,
										width:'130px',
										bgcolor:'#008200',
										boxShadow:'0px 0px 6px black',
										borderRadius:'3px'
									}}
								>
									Confirm
								</Button>
                            </Box>
						</Box>
					</Box>
				</Sheet>
		</React.Fragment>
	);
}