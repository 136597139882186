import * as React from 'react'
import { isFunction } from '../../helpers/typeCheckers.ts'
import { defaultProps } from '../../helpers/defaultProps.ts'
import { Arrow } from '../Arrow/index.tsx'
import { Loader } from '../Loader/index.tsx'
import { DefaultNodeProps } from '../../types.ts'
import styles from './style.scss'

const baseUrl = './';

export const DefaultNode: React.FC<DefaultNodeProps> = ({
    displayedName = defaultProps.displayedName,
    node,
    onSelect,
    onToggle
}) => (
    <div 
        onClick={onSelect}
        style={{
            display: 'flex',
            alignItems: 'center'
        }}>
        {(node.hasChildren() || node.options.async) && !node.isLoading() && (
            <Arrow onClick={onToggle} opened={node.isOpened() && !!node.hasChildren()} isRoot={node?.data?.isRootData} />
        )}
        {node.isLoading() && <Loader />}

        {node.data.image != null 
            ?   <img 
                    src={require("" + baseUrl + node.data.image.source)}
                    style={{
                        height: node.data.image.height,
                        marginLeft: 3,
                        width: node.data.image.width + 15,
                        paddingRight: 15
                    }} 
                />
            :   <img />
        }
        {
        Object.keys(node.data.header).map(key => 
        {
            var value = JSON.stringify(node.data?.header[key]?.value)?.replace(/['"]+/g, '');
            return <label style={{
                color: node.isSelected() ? 'white' : 'black',
                fontFamily: 'Courier New',                 
                fontSize: 12, 
                paddingRight: 5, 
                paddingLeft: 5, 
                minWidth: node.data?.header[key]?.minWidth ?? 50, 
                width: node.data?.header[key]?.width ?? 200, 
                overflow: 'hidden', 
                textOverflow: 'ellipsis', 
                textWrap: 'nowrap'
            }}
            title={value}>
                {value}
            </label>;
        })}
    </div>
)