import * as React from 'react';

import Box from '@mui/joy/Box';
import Tab from '@mui/joy/Tab';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import TabPanel from '@mui/joy/TabPanel';
import CssBaseline from '@mui/joy/CssBaseline';
import { CssVarsProvider } from '@mui/joy/styles';

import Welcome from './Welcome/Welcome.tsx';
import Aircraft_Actual from './Aircraft_Actual/Aircraft_Actual.tsx';
import Aircraft_Registration from './Aircraft_Registration/Aircraft_Registration.tsx';
import Aircraft_Model from './Aircraft_Model/Aircraft_Model.tsx';
import Aircraft_Type_Family from './Aircraft_Type_Family/Aircraft_Type_Family.tsx';
import Engine_Model from './Engine_Model/Engine_Model.tsx';
import Engine_Type_Family from './Engine_Type_Family/Engine_Type_Family.tsx';
import Manufacturer from './Manufacturer/Manufacturer.tsx';
import ATA from './ATA/ATA.tsx';

import URL from '../../../../URL.js'

export default function Aircrafts_Engines() {
	document.body.style.backgroundColor = '#d6d3ce';
	document.title = 'Administrative'
	const tabStyle = {fontSize:'14px'}

	const [selectedTab, setSelectedTab] = React.useState (sessionStorage.getItem('AE_screen')?sessionStorage.getItem('AE_screen'):'W');
	const handleChangeTab = (newValue) => {
		sessionStorage.setItem('AE_screen', newValue ? newValue : 'W');
		setSelectedTab(newValue);
	} 

	React.useEffect(() => {
		if (!sessionStorage.getItem('AE_screen')) {
			sessionStorage.setItem('AE_screen','W');
		}
	}, []);

	return (
		<CssVarsProvider disableTransitionOnChange>
			<CssBaseline />
			<Box sx={{ display: 'flex', minWidth:'1400px' }}>
				<Box
					component="main"
					className="MainContent"
					sx={{
						flex: 1,
						display: 'flex',
						flexDirection: 'column',
						minWidth: 0,
					
						gap: 1,
					}}
				>
					<Box>
						<Tabs
							value={selectedTab}
							onChange={(event, newValue) => handleChangeTab(newValue)}
							sx={{
								flexDirection: 'column',
								bgcolor:'#d6d3ce',
								border:'1px solid black',
								whiteSpace: 'nowrap',
								minHeight:'850px'
							}}
						>
							<TabList >
								<Tab value="W" sx={tabStyle}>
									Welcome
								</Tab>
								<Tab value="AA" sx={tabStyle}>
									Aircraft Actual
								</Tab>
								<Tab value="AR" sx={tabStyle}>
									Aircraft Registration
								</Tab>
								<Tab value="AM" sx={tabStyle}>
									Aircraft Model
								</Tab>
								<Tab value="ATF" sx={tabStyle}>
									Aircraft Type-Family
								</Tab>
								<Tab value="EM" sx={tabStyle}>
									Engine Module
								</Tab>
								<Tab value="ETF" sx={tabStyle}>
									Engine Type-Family
								</Tab>
								<Tab value="M" sx={tabStyle}>
									Manufacturer
								</Tab>
								<Tab value="ATA" sx={tabStyle}>
									ATA
								</Tab>
							</TabList>
							<TabPanel value="W" sx={{p:'0px'}}>
								<Welcome setSelectedTab={handleChangeTab}/>
							</TabPanel>
							<TabPanel value="AA" sx={{p:'0px'}}>
								<Aircraft_Actual URL={URL}/>
							</TabPanel>
							<TabPanel value="AR" sx={{p:'0px'}}>
								<Aircraft_Registration URL={URL}/>
							</TabPanel>
							<TabPanel value="AM" sx={{p:'0px'}}>
								<Aircraft_Model URL={URL}/>
							</TabPanel>
							<TabPanel value="ATF" sx={{p:'0px'}}>
								<Aircraft_Type_Family URL={URL}/>
							</TabPanel>
							<TabPanel value="EM" sx={{p:'0px'}}>
								<Engine_Model URL={URL}/>
							</TabPanel>
							<TabPanel value="ETF" sx={{p:'0px'}}>
								<Engine_Type_Family URL={URL}/>
							</TabPanel>
							<TabPanel value="M" sx={{p:'0px'}}>
								<Manufacturer URL={URL}/>
							</TabPanel>
							<TabPanel value="ATA" sx={{p:'0px'}}>
								<ATA URL={URL}/>
							</TabPanel>
						</Tabs>
					</Box>		
				</Box>
			</Box>
		</CssVarsProvider>
	);
}
