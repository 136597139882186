import * as React from 'react';

import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Input from '@mui/joy/Input';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';
import Checkbox from '@mui/joy/Checkbox';
import Textarea from '@mui/joy/Textarea';
import FormLabel from '@mui/joy/FormLabel';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import Autocomplete from '@mui/joy/Autocomplete';

import CircularProgress from '@mui/material/CircularProgress';

import QRCode from 'qrcode.react';

import CloseIcon from '@mui/icons-material/Close';

import AdditionalInfoWnd from './Additional_Info_wnd.tsx';
import ConfirmAction from '../../../Components/ConfirmAction.tsx';

export default function AddEditeForm(props) {
	//Стили
	const inputStyle = { borderRadius: 0, px:1, mx: 1, height: 30, minHeight: 0, border:'1px solid black', color:'#000084', fontWeight:'bold', fontSize:'18px', lineHeight:1 };
	const labelStyle = { mx: 1, my: 0, p: 0,fontSize:'16px' };
	const formControlStyle = { my: 0.5 };

	const [showSign, setShowSign] = React.useState(false);
	const [showStamp, setShowStamp] = React.useState(false);
	const [showPhoto, setShowPhoto] = React.useState(false);
	const [showSecurityGroups, setShowSecurityGroups] = React.useState(false);
	const [securityGroups, setSecurityGroups] = React.useState([{}]);
	const [editedSecurityGroups, setEditedSecurityGroups] = React.useState([{}]);
	const [editedEngineerCategories, setEditedEngineerCategories] = React.useState([]);
	const [reportLoading, setReportLoading] = React.useState(false);
	const [acList, setAcList] = React.useState([{}]);
	const [categories, setCategories] = React.useState([{}]);
	const [additionalInfo, setAdditionalInfo] = React.useState({open:false,item:''});
	var selectedSecurityGroupRow;
	const [defSecurityGroups, setDefSecurityGroups] = React.useState([{}]);
	const [isLoginValid, setIsLoginValid] = React.useState(true);

	const [editedData, setEditedData] = React.useState({shift:true, inspector:true, rii:true, bms:true, storeman:true, purchaser:true, reserveFromStore:true, planningRestriction:true});
	const [showConfirm, setShowConfirm] = React.useState(false);
	const [confirmActionType, setConfirmActionType] = React.useState('');
	const windowName = "User"

	//Получение данных о выбранной записи
	React.useEffect(() => {
		let respInfo={}
		let sign={}
		let stamp={}
		let photo={}

		const fetchUserInfo = async () => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_ADMIN}/Users/${props.selectedRow.id}`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {
				const result = await response.json();
				respInfo = result
				//setEditedData(result);
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		}
		
		const fetchSign = async () => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_ADMIN}/Users/GetImage(userId=${props.selectedRow.id},type='sign')`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {
				const result = await response.json();
				sign ={sign:result.image}
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		}

		const fetchStamp = async () => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_ADMIN}/Users/GetImage(userId=${props.selectedRow.id},type='stamp')`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {
				const result = await response.json();
				stamp ={stamp:result.image}
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		}

		const fetchPhoto = async () => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_ADMIN}/Users/GetImage(userId=${props.selectedRow.id},type='engineerphoto')`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {
				const result = await response.json();
				photo ={engineerPhoto:result.image}
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		}

		const fetchData = async () => {
			await fetchUserInfo()
			await fetchSign()
			await fetchStamp()
			await fetchPhoto()
			setEditedData({...respInfo, ...sign, ...stamp, ...photo});
		}
		
		if(props.selectedRow?.id !== 0){
			fetchData()
		} else {
			setEditedData({})
		}
	
		setIsLoginValid(true);
	}, [props.selectedRow]);

	React.useEffect(() => {
		setEditedSecurityGroups(securityGroups);
	}, [securityGroups]);

	React.useEffect(() => {
		const def = editedSecurityGroups.filter((item) => {
			const groupItem = securityGroups.find((group) => group.groupId === item.groupId);
			return groupItem ? item.inGroup !== groupItem.inGroup : true;
		}).map((item) => ({
			userId: props.selectedRow.id,
			groupId: item.groupId,
			addFlag: item.inGroup,
		}));
		setDefSecurityGroups(def)
	}, [editedSecurityGroups]);
	
	//Запрос списка SecurityGroups
	const fetchSecurityGroups = async () => {
		const response = await fetch(`${props.URL}${process.env.REACT_APP_API_ADMIN}/Users/Groups(login='${props.selectedRow.login}')`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {
			const result = await response.json();
			const newGroup = result.map(item => ({
				userId:props.selectedRow.id,
				inGroup:item.inGroup,
				name:item.groupName,
				description:item.groupDescription,
				groupId:item.groupId,
			}));
			setSecurityGroups(newGroup);
		} else {
			props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
		}
	};
	React.useEffect(() => {
		if (props.selectedRow?.login) {
			fetchSecurityGroups();
		}
	}, [props.selectedRow]);

	const updateEditedEngineerCategories = () => {
		let lastNumber = editedEngineerCategories.length;
		if (lastNumber === 0) {
		  	setEditedEngineerCategories([...editedEngineerCategories, {tableId: lastNumber, aircraftTypeId: 0, categoryId: 0, remarks: null}]);
		} else {
			let lastItem = editedEngineerCategories[lastNumber - 1];
			if (!(lastItem.categoryId === 0 && lastItem.aircraftTypeId === 0)) {
				setEditedEngineerCategories([...editedEngineerCategories, {tableId: lastNumber, userId: props.selectedRow.id, aircraftTypeId: 0, categoryId: 0, remarks: null}]);
			}
		}
	};
	
	React.useEffect(() => {updateEditedEngineerCategories()},[editedEngineerCategories])
	
	//Запрос списка Categories юзера
	const fetchCategories = async () => {
		const response = await fetch(`${props.URL}${process.env.REACT_APP_API_ADMIN}/SecurityUsersEngCategories?$filter=userId eq ${props.selectedRow.id}`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {
			const result = await response.json();
			const updatedCategories = result.value.map((category, index) => ({
				...category,
				tableId: index
			}));
			setEditedEngineerCategories(updatedCategories);
		} else {
			props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
		}
	};
	React.useEffect(() => {
		if (props.selectedRow?.id) {
			fetchCategories();
		} else if(props.selectedRow?.id===0) {
			setEditedEngineerCategories([{tableId: 0, aircraftTypeId: 0, categoryId: 0, remarks: null}])
		}
	}, [props.selectedRow]);

	//Запрос списка Ac
	React.useEffect(() => {
		const fetchAc = async () => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_ADMIN}/AircraftTypes?$apply=groupby((id, type))`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {
				const result = await response.json();
				setAcList(result);
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		};
		fetchAc();
	}, []);

	//Запрос списка Categories
	React.useEffect(() => {
		const fetchCategories = async () => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_ADMIN}/EngineeringCategories`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {
				const result = await response.json();
				setCategories(result.value);
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		};
		fetchCategories();
	}, []);

	//Обработчик открытия окна AdditionalInfo
	const handleAdditionalInfo = (event,item) => {
		if (event.code === 'F1') {
			event.preventDefault();  
			if (editedData[item]) {
				setAdditionalInfo({open:true, item:item})
			}
		}
	};

	//Создание PDF отчёта
	const createReport = () => {
		setReportLoading(true);
		return fetch(`${props.URL}/reports/Reports`, {
			method: 'POST',
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/json'
			},
			body:JSON.stringify({
				"templateName": "Admin/UserEditor.frx",
				"isExportAsPicture": false,
				"parameters": [
					{
					"name": 'userId',
					"value": `${props.selectedRow.login}`
					}
				]
			})
		})
		.then((response) => {
			if (response.ok) {
				return response.blob();
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				setReportLoading(false);
			}
		})
		.then((blob) => {
			if (blob) {
				const url = window.URL.createObjectURL(blob); 
				const link = document.createElement('a'); 
				link.href = url;
				link.setAttribute('download', '/UserEditor.pdf'); 
				document.body.appendChild(link); 
				link.click();
				link.remove(); 
				window.URL.revokeObjectURL(url);

				setReportLoading(false);
			}
		})
		.catch((error) => {
			console.error('Ошибка при получении файла:', error);
			setReportLoading(false);
		});
	};

	//Запрос проверки валидности Login
	const checkValidLogin = async () => {
		const response = await fetch(`${props.URL}${process.env.REACT_APP_API_ADMIN}/Users/ValidLogin()?login='${editedData.login}'`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {
			const result = await response.json();
			setIsLoginValid(result.value);
			return result.value;
		} else {
			props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
		}
	};

	const handleAction = async (type) => {
		setConfirmActionType(type);
		if (type === 'Add'){
			if (await checkValidLogin()) {
				setShowConfirm(true);
			}
		} else if (type === 'Update') {
			if(editedData.login === props.selectedRow.login || await checkValidLogin()){
				setIsLoginValid(true);
				setShowConfirm(true);
			}
		} else if (type === 'Delete'){
			setShowConfirm(true);
		} else if (type === 'DeleteSign' || type === 'DeleteStamp' || type === 'DeletePhoto' || type === 'PaswordChange'){
			setShowConfirm(true);
		}
	};

	//Обработчик события добавления новой записи
	const handleAdd = () => {
		fetch(
			`${props.URL}${process.env.REACT_APP_API_ADMIN}/Users`, 
			{
				method: 'POST',
				headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					login: editedData.login,
					name: editedData.name,
					code: editedData.code,
					userGroup: editedData.userGroup,
					//securityGroup: {string},
					userSTA: editedData.userSTA,
					skill: props.skills.find(row => row.skill === editedData.skill)?.id,
					remarks: editedData.remarks,
					licenceNum: editedData.licenceNum,
					shift: editedData.shift,
					inspector: editedData.inspector,
					rii: editedData.rii,
					bms: editedData.shift,
					toolNum: editedData.toolNum,
					activate: editedData.activate,
					//sign: editedData.sign,
					//stamp: editedData.stamp,
					//engineerLicense: {string},
					employedSince: editedData.employedSince,
					validUntil:  editedData.validUntil,
					//engineerPhoto: editedData.engineerPhoto,
					email: editedData.email,
					//expDate: {Date YYYY-MM-DDTHH:MM:SS.000},
					//expDays: {int},
					//pinStatusReseted: {true/false/null},
					//pin: {binary},
					//fingerprintImage: {binary},
					//fingerprintBinImage: {binary},
					//fingerprintBin: {binary},
					//fingerprintFeature: {binary},
					phone: editedData.phone,
					internalNumber: editedData.internalNumber,
					planningRestriction: editedData.planningRestriction,
					//nameRu: {string},
					storeman: editedData.storeman,
					purchaser: editedData.purchaser,
					reserveFromStore: editedData.reserveFromStore
				})
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Add'});
			}
			return response.json();
		})
		.then(data => {
			props.setSelectedRow(data)
			if(editedData.password){
				handleUpdatePassword(data.login)
			}
			props.reloadTable();
			props.focusRow()
		})
		.catch(error => console.error('Ошибка создания группы:', error));
	}

	//Обработчик события обновления выбранной записи
	const handleUpdate = () => {
		fetch(
			`${props.URL}${process.env.REACT_APP_API_ADMIN}/Users/${props.selectedRow.id}`, 
			{
				method: 'PATCH',
				headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					login: editedData.login,
					name: editedData.name,
					code: editedData.code,
					userGroup: editedData.userGroup,
					//securityGroup: {string},
					userSTA: editedData.userSTA,
					skill: props.skills.find(row => row.skill === editedData.skill)?.id,
					remarks: editedData.remarks,
					licenceNum: editedData.licenceNum,
					shift: editedData.shift,
					inspector: editedData.inspector,
					rii: editedData.rii,
					bms: editedData.shift,
					toolNum: editedData.toolNum,
					activate: editedData.activate,
					//sign: editedData.sign,
					//stamp: editedData.stamp,
					//engineerLicense: {string},
					employedSince: editedData.employedSince,
					validUntil:  editedData.validUntil,
					//engineerPhoto: editedData.engineerPhoto,
					email: editedData.email,
					//expDate: {Date YYYY-MM-DDTHH:MM:SS.000},
					//expDays: {int},
					//pinStatusReseted: {true/false/null},
					//pin: {binary},
					//fingerprintImage: {binary},
					//fingerprintBinImage: {binary},
					//fingerprintBin: {binary},
					//fingerprintFeature: {binary},
					phone: editedData.phone,
					internalNumber: editedData.internalNumber,
					planningRestriction: editedData.planningRestriction,
					//nameRu: {string},
					storeman: editedData.storeman,
					purchaser: editedData.purchaser,
					reserveFromStore: editedData.reserveFromStore
				})
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				if(editedData.password){
					handleUpdatePassword(props.selectedRow.login)
				}
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Update'});
			}
			props.reloadTable();
			props.focusRow()
			return response.json();
		})
		.catch(error => console.error('Ошибка обновления группы:', error));
	}

	//Обработчик события удаления выбранной записи
	const handleDelete = () => {
		fetch(
			`${props.URL}${process.env.REACT_APP_API_ADMIN}/Users/${props.selectedRow.id}`, 
			{
				method: 'DELETE',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				}
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Delete'});
			}
			props.setSelectedRow({id:0})
			setEditedData({})
			props.reloadTable();
			return response.json();
		})
		.catch(error => console.error('Ошибка удаления группы:', error));
	}

	//обновление пароля для выбранного пользователя
	const handleUpdatePassword = (userId) => {
		fetch(
			`${props.URL}${process.env.REACT_APP_API_ADMIN}/Users/OverridePassword(userId='${userId}')`, 
			{
				method: 'PATCH',
				headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					password: editedData.password,
				})
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} 
			return response.json();
		})
		.catch(error => console.error('Ошибка обновления группы:', error));
	}

	//обновление пароля для выбранного пользователя
	const handleUpdatePasswordPolicy = () => {
		fetch(
			`${props.URL}${process.env.REACT_APP_API_ADMIN}/Users/${props.selectedRow.id}`, 
			{
				method: 'PATCH',
				headers: {
					'Authorization': `Bearer ${localStorage.getItem('asid')}`,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					passwordChangePolicy:true
				})
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} 
			return response.json();
		})
		.catch(error => console.error('Ошибка обновления группы:', error));
	}

	const handleEditSecurityGroups = () => {
		fetch(
			`${props.URL}${process.env.REACT_APP_API_ADMIN}/UsersGroups/ModifyGroupsAccesses`, 
			{
				method: 'POST',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				},
				body: JSON.stringify(defSecurityGroups)
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Update'});
			}
			fetchSecurityGroups()
			return response.json();
		})
		.catch(error => console.error('Ошибка удаления группы:', error));
	}

	const handleUpdateCategories = (row) => {
		if (row.id) {
			fetch(
				`${props.URL}${process.env.REACT_APP_API_ADMIN}/SecurityUsersEngCategories/${row.id}`, 
				{
					method: 'PATCH',
					headers: {
					  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
					  'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						aircraftTypeId: row.aircraftTypeId,
						categoryId: row.categoryId,
						remarks: row.remarks
					})
				}
			)
			.then((response) => {
				if (!response.ok){
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				} else {
					fetchCategories();
					props.setResponseStatus({...props.responseStatus, successfulStatus:'Update'});
				}
				return response.json();
			})
			.catch(error => console.error('Ошибка удаления группы:', error));
		} else {
			fetch(
				`${props.URL}${process.env.REACT_APP_API_ADMIN}/SecurityUsersEngCategories`, 
				{
					method: 'POST',
					headers: {
					  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
					  'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						userId: props.selectedRow.id,
						aircraftTypeId: row.aircraftTypeId,
						categoryId: row.categoryId,
						remarks: row.remarks
					})
				}
			)
			.then((response) => {
				if (!response.ok){
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				} else {
					fetchCategories();
					props.setResponseStatus({...props.responseStatus, successfulStatus:'Add'});
				}
				return response.json();
			})
			.catch(error => console.error('Ошибка удаления группы:', error));
		}
	}

	const handleDeleteCategories = (row) => {
		fetch(
			`${props.URL}${process.env.REACT_APP_API_ADMIN}/SecurityUsersEngCategories/${row.id}`, 
			{
				method: 'DELETE',
				headers: {
					'Authorization': `Bearer ${localStorage.getItem('asid')}`,
					'Content-Type': 'application/json'
				},
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				fetchCategories();
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Delete'});
			}
			return response.json();
		})
		.catch(error => console.error('Ошибка удаления группы:', error));
	}

	const handleGrouopCheckboxChange = (event) => {
		setEditedSecurityGroups(prevState => prevState.map(item => item.groupId === selectedSecurityGroupRow.groupId ? { ...item, inGroup: event.target.checked }: item))
	};
	
	//Внесение изменений в data
	const handleChange = (property, value) => {
		setEditedData({ ...editedData, [property]: value });
	};

	//Обработчик события сохранения картинок
	const savePhotos = (type, file) => {
		const reader = new FileReader();
		reader.onloadend = () => {
			const base64String = reader.result;
			setEditedData({ ...editedData, [type]: base64String.split(',')[1] });
		};
		if (file) {
			reader.readAsDataURL(file);
		}

		if(props.selectedRow.id !== 0) {
			fetch(
				`${props.URL}${process.env.REACT_APP_API_ADMIN}/Users/UploadImage(userId=${props.selectedRow.id},type='${type.toLowerCase()}')`, 
				{		
					method: 'POST',										
					headers: {
					  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
					 
					},
					body: file
				}
			)
			.then((response) => {
				return response.json();
			})
			.catch(error => console.error('Ошибка обновления группы:', error));
		}
	}

	//Обработчик события удаления картинок
	const deletePhotos = (type) => {
		handleChange(type, '')
		if(props.selectedRow.id !== 0) {
			fetch(
				`${props.URL}${process.env.REACT_APP_API_ADMIN}/Users/DeleteImage(userId=${props.selectedRow.id},type='${type.toLowerCase()}')`, 
				{
					method: 'DELETE',
					headers: {
					  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
					 
					},
				}
			)
			.then((response) => {
				return response.json();
			})
			.catch(error => console.error('Ошибка обновления группы:', error));
		}
	}
	
	//Внесение изменений в Engineer Categories
	const handleChangeEngineerCategories = (id, property, value) => {
		setEditedEngineerCategories(editedEngineerCategories.map(item => {
		  if (item.tableId === id) {
			return { ...item, [property]: value };
		  }
		  return item;
		}));
	};

	//Открытие и закрытие Security Group List
	const toggleSecurityGroup = () => {
		setShowSecurityGroups(!showSecurityGroups);
	}

	const handleOpenSign = () => {
		setShowSign(!showSign);
		setShowStamp(false);
		setShowPhoto(false);
	};

	const handleOpenStamp = () => {
		setShowSign(false);
		setShowStamp(!showStamp);
		setShowPhoto(false);
	};

	const handleOpenPhoto = () => {
		setShowSign(false);
		setShowStamp(false);
		setShowPhoto(!showPhoto);
	};


	return (
		<>
			<ConfirmAction 
				actionType={confirmActionType} 
				windowName={windowName} 
				onConfirmAdd={handleAdd} 
				onConfirmUpdate={handleUpdate} 
				onConfirmDelete={handleDelete} 
				onConfirmDeleteSign={() => deletePhotos('sign')}
				onConfirmDeleteStamp={() => deletePhotos('stamp')}
				onConfirmDeletePhoto={() => deletePhotos('engineerphoto')}
				onComfirmPasswordPolicy={handleUpdatePasswordPolicy}
				setShowConfirm={setShowConfirm}
				showConfirm={showConfirm}
			/>
			{additionalInfo.open && (
				<AdditionalInfoWnd item={additionalInfo.item} value={editedData[additionalInfo.item]} setAdditionalInfo={setAdditionalInfo} URL={props.URL}/>
			)}
			{showSign && (
				<Box
					sx={{
						border:'1px solid black',
						height:'100%',
						mr:1,
						display:'flex',
						alignItems:'center'
					}}
					onClick={handleOpenSign}
				>
					{editedData.sign && (
						<img
							src={`data:image/png;base64,${editedData.sign}`}
							alt="Selected Sign"
							style={{
								width: '100%',
								objectFit: 'cover',
							}}
						/>
					)}	
				</Box>
			)}
			{showStamp && (
				<Box
					sx={{
						border:'1px solid black',
						height:'100%',
						mr:1,
						display:'flex',
						alignItems:'center'
					}}
					onClick={handleOpenStamp}
				>
					{editedData.stamp && (
						<img
							src={`data:image/png;base64,${editedData.stamp}`}
							alt="Selected Stamp"
							style={{
								width: '100%',
								objectFit: 'cover',
							}}
						/>
					)}	
				</Box>
			)}
			{showPhoto && (
				<Box
					sx={{
						border:'1px solid black',
						height:'100%',
						mr:1,
						display:'flex',
						alignItems:'center'
					}}
					onClick={handleOpenPhoto}
				>
					{editedData.engineerPhoto && (
						<img
							src={`data:image/png;base64,${editedData.engineerPhoto}`}
							alt="Selected Photo"
							style={{
								width: '100%',
								objectFit: 'cover',
							}}
						/>
					)}	
				</Box>
			)}
			{!showSign && !showStamp && !showPhoto && (
				<Box>
					<Typography 
						sx={{
							fontSize:'18px',
							fontWeight:'bold',
							color:'#000078',
							fontFamily:'Arial, sans-serif',
							ml:1
						}}
					>
						User Editor:
					</Typography>
					<Box
						sx={{
							display:'flex',
							flexDirection:'row'
						}}
					>
					<Tooltip title='Add'>
						<IconButton
							variant='plain'
							disabled={editedData.login && editedData.name && editedData.userSTA && (editedData.password || editedData.email) ? false : true}
							onClick={()=>handleAction('Add')}
							sx={{
								m:0,
								'&:disabled img': {
									opacity: 0.5,
								}
							}}
						>
							<img 
								width='35px' 
								height='35px' 
								src="images/svg/add.svg" 
								alt="" 
							/>
						</IconButton>
					</Tooltip>
					<Tooltip title='Update'>
						<IconButton 
							variant='plain'
							disabled={props.selectedRow.id && editedData.login && editedData.name && editedData.userSTA ? false : true}
							onClick={()=>handleAction('Update')}
							sx={{
								m:0,
								'&:disabled img': {
									opacity: 0.5,
								}
							}}
						>
							<img 
								width='35px' 
								height='35px' 
								src="images/svg/save.svg" 
								alt="" 
							/>
						</IconButton>
					</Tooltip>
					<Tooltip title='Delete'>
						<IconButton 
							variant='plain'
							disabled={props.selectedRow.id ? false : true}
							onClick={()=>handleAction('Delete')}
							sx={{
								m:0,
								'&:disabled img': {
									opacity: 0.5,
								}
							}}
						>
							<img 
								width='35px' 
								height='35px'  
								src="images/svg/trashcan.svg" 
								alt="" 
							/>
						</IconButton>
					</Tooltip>
					<Tooltip 
						title='View Report'
						sx={{
							position:'relative'
						}}
					>
						<IconButton 
							variant='plain'
							disabled={props.selectedRow.id ? false : true}
							onClick={createReport}
							sx={{
								m:0,
								'&:disabled img': {
									opacity: 0.5,
								}
							}}
						>
							<img 
								width='35px' 
								height='35px' 
								src="images/svg/report.svg" 
								alt="" 
							/>
							{reportLoading && (
								<CircularProgress
									size={36}
									sx={{
										position: 'absolute',
										top: 0,
										left: 3.5,
										zIndex: 5,
									}}
								/>
							)}
						</IconButton>
					</Tooltip>
					<Tooltip title='Require the user to change their password upon login?'>
						<IconButton 
							variant='plain'
							disabled={props.selectedRow.id ? false : true}
							onClick={()=>handleAction('PaswordChange')}
							sx={{
								m:0,
								ml:'auto',
								'&:disabled img': {
									opacity: 0.5,
								}
							}}
						>
							<img 
								width='35px' 
								height='35px'  
								src="images/svg/password.svg" 
								alt="" 
							/>
						</IconButton>
					</Tooltip>
				
					</Box>
					<Grid container spacing={0} sx={{ flexGrow: 1 }}>
						<Grid xs={8}>
							<Box>
								<Grid container spacing={0} sx={{ flexGrow: 1 }}>
									<Grid xs={5}>
										<Tooltip 
											title='Login is already exist'
											color="danger"
											variant="outlined"
											placement="left"
											open={!isLoginValid}
										>
											<FormControl sx={formControlStyle} required>
												<FormLabel sx={labelStyle}>User ID:</FormLabel>
												<Input 
													color={!isLoginValid ? 'danger' : 'primary'}
													sx={{...inputStyle, border:isLoginValid ? '1px solid black':'1px solid #C41C1C', color: isLoginValid ? '#000084':'#C41C1C'}}
													value={editedData?.login || ''}
													onChange={(event) => handleChange('login', event.target.value)}
												/>
											</FormControl>
										</Tooltip>
									</Grid>
									<Grid xs={7}>
										<FormControl sx={formControlStyle}>
											<FormLabel sx={labelStyle}>Engineer License:</FormLabel>
											<Input 
												sx={inputStyle} 
												//value={editedData.}
												//onChange={(event) => handleChange('login', event.target.value)}
											/>
										</FormControl>
									</Grid>

									<Grid xs={12}>
										<FormControl sx={formControlStyle} required>
											<FormLabel sx={labelStyle}>User Name:</FormLabel>
											<Input  
												sx={inputStyle} 
												value={editedData?.name || ''}
												onChange={(event) => handleChange('name', event.target.value)}
											/>
										</FormControl>
									</Grid>
								</Grid>
							</Box>
						</Grid>
						<Grid xs={4}>
							<Checkbox 
								label="Activate"
								color='danger'
								variant='outlined' 
								sx={{
									color:'#840000',
									m:1
								}}
								checked={editedData.activate == 'Y' ? true : false}
								onChange={(event) => handleChange('activate',event.target.checked ? 'Y' : 'N')}
							/>
							<Box sx={{display:'flex', justifyContent:'center'}}>
								{props.selectedRow.id != 0 && (
									<div>
										<QRCode 
											value={`AR|USERID=${props.selectedRow.id}|USERLIC=${props.selectedRow.licenceNum}`}  
											style={{height:'75px', width:'75px'}}
										/>
									</div>
								)}
							</Box>
						</Grid>
						<Grid xs={12}>
							<Box>
								<Grid container spacing={0} sx={{ flexGrow: 1 }}>
									<Grid xs={3}>
										<FormControl sx={formControlStyle} required = {!editedData.email}>
											<FormLabel sx={labelStyle}>Password:</FormLabel>
											<Input  
												sx={inputStyle} 
												value={editedData.password || ''}
												onChange={(event) => handleChange('password', event.target.value)}
											/>
										</FormControl>
									</Grid>
									<Grid xs={3}>
										<FormControl sx={formControlStyle}>
											<FormLabel sx={labelStyle}>Code:</FormLabel>
											<Input 
												sx={inputStyle} 
												value={editedData?.code || ''}
												onChange={(event) => handleChange('code', event.target.value)}
											/>
										</FormControl>
									</Grid>
									<Grid xs={3}>
										<FormControl sx={formControlStyle}>
											<FormLabel sx={labelStyle}>Group:</FormLabel>
											<Autocomplete
												disableClearable
												options={props.groups.map(data=> ({ label: data.userGroup }))}
												value={{label: editedData.userGroup || ''}}
												sx={inputStyle}
												onChange={(event, value) => handleChange('userGroup', value ? value.label : '')}
											/>
										</FormControl>
									</Grid>
									<Grid xs={3}>
										<FormControl sx={formControlStyle} required>
											<FormLabel sx={labelStyle}>STA:</FormLabel>
											<Autocomplete 
												disableClearable
												options={props.sta.map(data=> ({ label: data.code }))}
												value={{label: editedData.userSTA || ''}}
												sx={{...inputStyle, backgroundColor:'#c6ffff'}}
												onChange={(event, value) => handleChange('userSTA', value ? value.label : '')}
												onKeyDown={(event) => handleAdditionalInfo(event, 'userSTA')}
											/>
										</FormControl>
									</Grid>
									
									<Grid xs={4}>
										<FormControl sx={formControlStyle}>
											<FormLabel sx={labelStyle}>Security Group:</FormLabel>
											<Button 
												onClick={toggleSecurityGroup}
												disabled={!props.selectedRow.id}
												sx={{
													...inputStyle, 
													backgroundColor: '#ffffe7',
													'&:hover': {
														backgroundColor:'#ebeba9',
													}
												}}
											>
												<img 
													width='35px' 
													height='35px' 
													src="images/svg/users.svg" 
													alt="" 
												/>
											</Button>
										</FormControl>
									</Grid>
									<Grid xs={4}>
										<FormControl sx={formControlStyle} required = {!editedData.password}>
											<FormLabel sx={labelStyle}>E-mail:</FormLabel>
											<Input 
												sx={inputStyle} 
												value={editedData?.email || ''}
												onChange={(event) => handleChange('email', event.target.value)}
											/>
										</FormControl>
									</Grid>
									<Grid xs={4}>
										<FormControl sx={formControlStyle}>
											<FormLabel sx={labelStyle}>Internat Number:</FormLabel>
											<Input 
												sx={inputStyle} 
												value={editedData?.internalNumber || ''}
												onChange={(event) => handleChange('internalNumber', event.target.value)}
											/>
										</FormControl>
									</Grid>
								</Grid>
							</Box>
						</Grid>
						{showSecurityGroups && props.selectedRow.id != 0? (
							<Box
								sx={{
									border:'1px solid black',
									m:1,
									width:'100%',
									height:'482px',
									display:'grid',
									gridTemplateRows:'1fr 30px',
								}}
							>
								<Sheet
									variant="outlined"
									sx={{
										display:'initial',
										width: '100%',
										borderRadius: '0px',
										flexShrink: 1,
										overflow: 'auto',
										borderBottom: '1px solid black',
										bgcolor:'#848284',
										
									}}
								>
									<Table
										borderAxis="both"
										stickyHeader
										hoverRow
										sx={{
											'--TableCell-headBackground': '#d6d3ce',
											'--Table-headerUnderlineThickness': '1px',
											'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
											'--TableCell-paddingY': '1px',
											"--TableCell-height": "29px",
											'--TableCell-paddingX': '5px',
											'--TableCell-borderColor': '#939293',
											tableLayout: 'auto', 
											width:'auto',
											borderRadius:'0px',
										}}
									>
										<thead>
											<tr>
												<th style={{width: 'auto',fontWeight:'100',fontSize:'14px',color:'black',textAlign:'center'}}>In</th>
												<th style={{width: 'auto',fontWeight:'100',fontSize:'14px',color:'black'}}>Security Group Name</th>
												<th style={{width: 'auto',fontWeight:'100',fontSize:'14px',color:'black'}}>Security Group Description</th>
											</tr>
										</thead>
										<tbody>
											{editedSecurityGroups.map((row) => (
												<tr
													key={row.groupId}
													onClick={() => selectedSecurityGroupRow = row}
													style={{ 
														backgroundColor: row.inGroup ? '#e0e0ff' : 'white',
													}}
												>
													<td style={{ whiteSpace: 'nowrap', width: 'auto',fontSize:'10px',textAlign: 'center' }}>
														<Checkbox 
															variant="outlined"
															color='neutral'
															checked={row.inGroup}
															onChange={handleGrouopCheckboxChange}
															sx={{
																border:'1px solid black',
																fontWeight:'bold',
																'--Checkbox-size':'12px',
															}}
														/>
													</td>
													<td style={{ whiteSpace: 'nowrap', width: 'auto',fontSize:'14px' }}>{row.name}</td>
													<td style={{ whiteSpace: 'nowrap', width: 'auto',fontSize:'14px' }}>{row.description}</td>
												</tr>
											))}
										</tbody>
									</Table>
								</Sheet>
								<Box
									sx={{
										mx:0.2,
										display:'flex',
										justifyContent:'space-between',
										alignItems:'center'
									}}
								>
									<Button
										disabled={!props.selectedRow.id}
										onClick={handleEditSecurityGroups}
										sx={{
											minHeight:0,
											minWidth:0,
											borderRadius:0,
											height:26,
											width:60,
											fontSize:10,
											fontWeight:'100',
											border:'1px solid black',
											color:'black',
											bgcolor:'#dcdcf3'
										}}
									>
										Apply
									</Button>
									<Button
										onClick={toggleSecurityGroup}
										sx={{
											minHeight:0,
											minWidth:0,
											borderRadius:0,
											height:26,
											width:60,
											fontSize:10,
											fontWeight:'100',
											border:'1px solid black',
											color:'black',
											bgcolor:'#ffc0c0'
										}}
									>
										Cancel
									</Button>
								</Box>
							</Box>
						):(
							<>
								<Grid xs={12}>
									<Box>
										<Grid container spacing={0} sx={{ flexGrow: 1 }}>
											<Grid xs={4}>
												<Box 
													sx={{
														display:'flex',
														flexDirection:'column'
													}}
												>
													<FormControl sx={formControlStyle}>
														<FormLabel sx={labelStyle}>Licence Number:</FormLabel>
														<Input 
															sx={inputStyle} 
															value={editedData?.licenceNum || ''}
															onChange={(event) => handleChange('licenceNum', event.target.value)}
														/>
													</FormControl>
													<Box sx={{ml:1}}>
														<Grid container spacing={0} sx={{ flexGrow: 1 }}>
															<Grid xs={8}>
																<Tooltip title='Shift'>
																	<Checkbox 
																		label="Shift"
																		variant="outlined"
																		color="neutral"
																		sx={{
																			fontSize:'14px',
																			'--Checkbox-size':'15px',
																			gap:0.4,
																			'& .css-r4cpcz-JoyCheckbox-checkbox': {
																				borderRadius:0,
																				'--joy-palette-neutral-outlinedBorder':'black',
																			},
																			'& .css-n110n' : {
																				borderRadius:0,
																				'--joy-palette-neutral-outlinedBorder':'black',
																			},
																		}}
																		checked={editedData.shift}
																		onChange={(event) => handleChange('shift', event.target.checked)}
																	/>
																</Tooltip>
															</Grid>
															<Grid xs={4}>
																<Tooltip title='RII'>
																	<Checkbox 
																		label="RII" 
																		variant="outlined"
																		color="neutral"
																		sx={{
																			fontSize:'14px',
																			'--Checkbox-size':'15px',
																			gap:0.4,
																			'& .css-r4cpcz-JoyCheckbox-checkbox': {
																				borderRadius:0,
																				'--joy-palette-neutral-outlinedBorder':'black',
																			},
																			'& .css-n110n' : {
																				borderRadius:0,
																				'--joy-palette-neutral-outlinedBorder':'black',
																			},
																		}}
																		checked={editedData.rii}
																		onChange={(event) => handleChange('rii', event.target.checked)}
																	/>
																</Tooltip>
															</Grid>

															<Grid xs={8}>
																<Tooltip title='Inspector'>
																	<Checkbox 
																		label="Inspector" 
																		variant="outlined"
																		color="neutral"
																		sx={{
																			fontSize:'14px',
																			'--Checkbox-size':'15px',
																			gap:0.4,
																			'& .css-r4cpcz-JoyCheckbox-checkbox': {
																				borderRadius:0,
																				'--joy-palette-neutral-outlinedBorder':'black',
																			},
																			'& .css-n110n' : {
																				borderRadius:0,
																				'--joy-palette-neutral-outlinedBorder':'black',
																			},
																		}}
																		checked={editedData.inspector}
																		onChange={(event) => handleChange('inspector', event.target.checked)}
																	/>
																</Tooltip>
															</Grid>
															<Grid xs={4}>
																<Tooltip title='Special Permission'>
																	<Checkbox 
																		label="SP" 
																		variant="outlined"
																		color="neutral"
																		sx={{
																			fontSize:'14px',
																			'--Checkbox-size':'15px',
																			gap:0.4,
																			'& .css-r4cpcz-JoyCheckbox-checkbox': {
																				borderRadius:0,
																				'--joy-palette-neutral-outlinedBorder':'black',
																			},
																			'& .css-n110n' : {
																				borderRadius:0,
																				'--joy-palette-neutral-outlinedBorder':'black',
																			},
																		}}
																		checked={editedData.bms}
																		onChange={(event) => handleChange('bms', event.target.checked)}
																	/>
																</Tooltip>
															</Grid>

															<Grid xs={8}>
																<Tooltip title='Purchaser'>
																	<Checkbox 
																		label="Purchaser" 
																		variant="outlined"
																		color="neutral"
																		sx={{
																			fontSize:'14px',
																			'--Checkbox-size':'15px',
																			gap:0.4,
																			'& .css-r4cpcz-JoyCheckbox-checkbox': {
																				borderRadius:0,
																				'--joy-palette-neutral-outlinedBorder':'black',
																			},
																			'& .css-n110n' : {
																				borderRadius:0,
																				'--joy-palette-neutral-outlinedBorder':'black',
																			},
																		}}
																		checked={editedData.purchaser}
																		onChange={(event) => handleChange('purchaser', event.target.checked)}
																	/>
																</Tooltip>
															</Grid>
															<Grid xs={4}>
																<Tooltip title='Storeman'>
																	<Checkbox 
																		label="SM" 
																		variant="outlined"
																		color="neutral"
																		sx={{
																			fontSize:'14px',
																			'--Checkbox-size':'15px',
																			gap:0.4,
																			'& .css-r4cpcz-JoyCheckbox-checkbox': {
																				borderRadius:0,
																				'--joy-palette-neutral-outlinedBorder':'black',
																			},
																			'& .css-n110n' : {
																				borderRadius:0,
																				'--joy-palette-neutral-outlinedBorder':'black',
																			},
																		}}
																		checked={editedData.storeman}
																		onChange={(event) => handleChange('storeman', event.target.checked)}
																	/>
																</Tooltip>
															</Grid>

															<Grid xs={8}>
																<Tooltip title='Allow Reservation From Store'>
																	<Checkbox 
																		label="Reserve" 
																		variant="outlined"
																		color="neutral"
																		sx={{
																			fontSize:'14px',
																			'--Checkbox-size':'15px',
																			gap:0.4,
																			'& .css-r4cpcz-JoyCheckbox-checkbox': {
																				borderRadius:0,
																				'--joy-palette-neutral-outlinedBorder':'black',
																			},
																			'& .css-n110n' : {
																				borderRadius:0,
																				'--joy-palette-neutral-outlinedBorder':'black',
																			},
																		}}
																		checked={editedData.reserveFromStore}
																		onChange={(event) => handleChange('reserveFromStore', event.target.checked)}
																	/>
																</Tooltip>	
															</Grid>
															<Grid xs={4}>
																<Tooltip title='Planning Restriction'>
																	<Checkbox 
																		label="PR" 
																		variant="outlined"
																		color="neutral"
																		sx={{
																			fontSize:'14px',
																			'--Checkbox-size':'15px',
																			gap:0.4,
																			'& .css-r4cpcz-JoyCheckbox-checkbox': {
																				borderRadius:0,
																				'--joy-palette-neutral-outlinedBorder':'black',
																			},
																			'& .css-n110n' : {
																				borderRadius:0,
																				'--joy-palette-neutral-outlinedBorder':'black',
																			},
																		}}
																		checked={editedData.planningRestriction}
																		onChange={(event) => handleChange('planningRestriction', event.target.checked)}
																	/>
																</Tooltip>
															</Grid>
														</Grid>
													</Box>
												</Box>
											</Grid>
											<Grid xs={8}>
												<Box>
													<Grid container spacing={0} sx={{ flexGrow: 1 }}>
														<Grid xs={6}>
															<FormControl sx={formControlStyle}>
																<FormLabel sx={labelStyle}>Tool Number:</FormLabel>
																<Input
																	sx={inputStyle}
																	value={editedData?.toolNum || ''}
																	onChange={(event) => handleChange('toolNum', event.target.value)}
																/>
															</FormControl>
														</Grid>
														<Grid xs={6}>
															<FormControl sx={formControlStyle}>
																<FormLabel sx={labelStyle}>Employed Since:</FormLabel>
																<Input
																	type="date"
																	sx={inputStyle}
																	value={editedData?.employedSince?.slice(0, 10) || ''}
																	onChange={(event) => handleChange('employedSince', event.target.value? event.target.value + 'T00:00:00Z' : null)}
																/>
															</FormControl>
														</Grid>

														<Grid xs={6}>
															<FormControl sx={formControlStyle}>
																<FormLabel sx={labelStyle}>Phone Number:</FormLabel>
																<Input 
																	sx={inputStyle}
																	value={editedData?.phone || ''}
																	onChange={(event) => handleChange('phone', event.target.value)}
																/>
															</FormControl>
														</Grid>
														<Grid xs={6}>
															<FormControl sx={formControlStyle}>
																<FormLabel sx={labelStyle}>Valid Until:</FormLabel>
																<Input
																	type="date"
																	sx={inputStyle}
																	value={editedData?.validUntil?.slice(0, 10) || ''}
																	onChange={(event) => handleChange('validUntil', event.target.value? event.target.value + 'T00:00:00Z' : null)}
																/>
															</FormControl>
														</Grid>

														<Grid xs={4}>
															<FormControl sx={formControlStyle}>
																<FormLabel sx={labelStyle}>Skill:</FormLabel>
																<Autocomplete
																	disableClearable
																	options={props.skills.map(data=> ({ label: data.skill }))}
																	value={{label: props.skills.find(row => row.id === editedData.skill)?.skill || ''}}
																	sx={inputStyle}
																	onChange={(event, value) => handleChange('skill', props.skills.find(row => row.skill === value?.label)?.id || '')}
																/>
															</FormControl>
														</Grid>
														<Grid xs={8}>
															<FormControl sx={formControlStyle}>
																<FormLabel sx={{...labelStyle, color:'#d6d3ce'}}>'</FormLabel>
																<Input 
																	size="sm" 
																	disabled
																	sx={{
																		...inputStyle, 
																		'--variant-outlinedDisabledColor':'black',
																		'--variant-outlinedDisabledBorder':'black',
																		bgcolor:'#d6d3ce',
																	}} 
																	value={editedData.skill ? props.skills.find(item => item.id === editedData.skill)?.description : ''}
																/>
															</FormControl>
														</Grid>
													</Grid>
												</Box>
											</Grid>
										</Grid>
									</Box>
								</Grid>
								<Grid xs={12}>
									<Box sx={{ display:'flex', ml:1, mt:1 }}>
										<Typography 
											sx={labelStyle}
										>
											Remarks:
										</Typography>
										<Textarea 
											maxRows={1}
											defaultValue={props.selectedRow.description}
											sx={{...inputStyle,color:'#000078',width:'100%'}}
											value={editedData?.remarks || ''}
											onChange={(event) => handleChange('remarks', event.target.value)}
										/>
									</Box>
								</Grid>
								<Grid xs={4}>
									<FormControl sx={formControlStyle}>
										<FormLabel sx={labelStyle}>Sign</FormLabel>
										<Box 
											sx={{
												display:'flex',
												flexDirection:'row'
											}}
										>
											<Box
												onClick={handleOpenSign}
												sx={{
													bgcolor:'#d6d3ce',
													border:'1px solid black',
													width:'80px',
													height:'80px',
													mx:1,
													display:'flex',
													alignItems:'center',
													overflow:'auto'
												}}
												
											>
												{editedData.sign && (
													<img
														src={`data:image/png;base64,${editedData.sign}`}
														alt="Selected Sign"
														style={{
															width: '100%',
															objectFit: 'cover',
														}}
													/>
												)}
											</Box>
											<Box
												sx={{
													display:'flex',
													flexDirection:'column',
													justifyContent:'space-between'
												}}
											>
												<Tooltip title={props.selectedRow.id === 0 ?'User Not Selected':'Browse Sign Image'}>
													<div>
														<label htmlFor="sign-upload">
															<img 
																width='35px' 
																height='35px' 
																src="images/svg/folder.svg" 
																alt="" 
																style={{
																	borderRadius: '0px',
																	border: '1px solid black',
																	'&:hover': {
																		opacity:0.6
																	}
																}}
															/>
														</label>
														<input
															disabled={props.selectedRow.id === 0}
															id="sign-upload"
															type="file"
															accept=".png, .jpeg"
															style={{ display: 'none' }}
															onChange={(event) => savePhotos('sign', event.target.files[0])}
														/>
													</div>
												</Tooltip>
											
												<Tooltip title='Delete Sign Image'>
													<CloseIcon
														onClick={()=>handleAction('DeleteSign')}
														sx={{
															bgcolor:'#d6d3ce',
															borderRadius:'0px',
															border:'1px solid black',
															width:'35px',
															height:'35px',
															color:'red'
														}}
													/>
												</Tooltip>
											</Box>
										</Box>
									</FormControl>
								</Grid>
								<Grid xs={4}>
									<FormControl sx={formControlStyle}>
										<FormLabel sx={labelStyle}>Stamp</FormLabel>
										<Box 
											sx={{
												display:'flex',
												flexDirection:'row'
											}}
										>
											<Box
												sx={{
													bgcolor:'#d6d3ce',
													border:'1px solid black',
													width:'80px',
													height:'80px',
													mx:1,
													display:'flex',
													alignItems:'center',
													overflow:'auto'
												}}
												onClick={handleOpenStamp}
											>
												{editedData.stamp && (
													<img
														src={`data:image/png;base64,${editedData.stamp}`}
														alt="Selected Stamp"
														style={{
															width: '100%',
															objectFit: 'cover',
														}}
													/>
												)}
											</Box>
											<Box
												sx={{
													display:'flex',
													flexDirection:'column',
													justifyContent:'space-between'
												}}
											>
												<Tooltip title={props.selectedRow.id === 0 ?'User Not Selected':'Browse Stamp Image'}>
													<div>
														<label htmlFor="stamp-upload">
															<img 
																width='35px' 
																height='35px' 
																src="images/svg/folder.svg" 
																alt="" 
																style={{
																	borderRadius: '0px',
																	border: '1px solid black',
																	'&:hover': {
																		opacity:0.6
																	}
																}}
															/>
														</label>
														<input
															disabled={props.selectedRow.id === 0}
															id="stamp-upload"
															type="file"
															accept=".png, .jpeg"
															style={{ 
																display: 'none', 
															}}
															onChange={(event) => savePhotos('stamp', event.target.files[0])}
														/>
													</div>
												</Tooltip>
												<Tooltip title='Delete Stamp Image'>
													<CloseIcon
														onClick={()=>handleAction('DeleteStamp')}
														sx={{
															bgcolor:'#d6d3ce',
															borderRadius:'0px',
															border:'1px solid black',
															width:'35px',
															height:'35px',
															color:'red'
														}}
													/>
												</Tooltip>
											</Box>
										</Box>
									</FormControl>
								</Grid>
								<Grid xs={4}>
									<FormControl sx={formControlStyle}>
										<FormLabel sx={labelStyle}>Photo</FormLabel>
										<Box 
											sx={{
												display:'flex',
												flexDirection:'row'
											}}
										>
											<Box
												sx={{
													bgcolor:'#d6d3ce',
													border:'1px solid black',
													width:'80px',
													height:'80px',
													mx:1,
													display:'flex',
													alignItems:'center',
													overflow:'auto'
												}}
												onClick={handleOpenPhoto}
											>
												{editedData.engineerPhoto && (
													<img
														src={`data:image/png;base64,${editedData.engineerPhoto}`}
														alt="Selected Photo"
														style={{
															width: '100%',
															objectFit: 'cover',
														}}
													/>
												)}
											</Box>
											<Box
												sx={{
													display:'flex',
													flexDirection:'column',
													justifyContent:'space-between'
												}}
											>
												<Tooltip title={props.selectedRow.id === 0 ?'User Not Selected':'Browse Photo Image'}>
													<div>
														<label htmlFor="photo-upload">
															<img 
																width='35px' 
																height='35px' 
																src="images/svg/folder.svg" 
																alt="" 
																style={{
																	borderRadius: '0px',
																	border: '1px solid black',
																	'&:hover': {
																		opacity:0.6
																	}
																}}
															/>
								
														</label>
														<input
															disabled={props.selectedRow.id === 0}
															id="photo-upload"
															type="file"
															accept=".png, .jpeg"
															style={{ 
																display: 'none', 
															}}
															onChange={(event) => savePhotos('engineerPhoto', event.target.files[0])}
		
														/>
													</div>
												</Tooltip>
												<Tooltip title='Delete Photo Image'>
													<CloseIcon
														onClick={()=>handleAction('DeletePhoto')}
														sx={{
															bgcolor:'#d6d3ce',
															borderRadius:'0px',
															border:'1px solid black',
															width:'35px',
															height:'35px',
															color:'red'
														}}
													/>
												</Tooltip>
											</Box>
										</Box>
									</FormControl>
								</Grid>
								<Grid xs={12}>
									<Sheet
										sx={{
											bgcolor:'#848284',
											border:'1px solid black',
											height:'150px',
											m:1,
											borderRadius: '0px',
											flexShrink: 1,
											overflow: 'auto',
										}}
									>
										<Table
											borderAxis="both"
											stickyHeader
											hoverRow
											sx={{
												'--TableCell-headBackground': '#d6d3ce',
												'--Table-headerUnderlineThickness': '1px',
												'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
												'--TableCell-paddingY': '0px',
												'--TableCell-height': '22px',
												'--TableCell-paddingX': '5px',
												'--TableCell-borderColor': '#939293',
												tableLayout: 'auto', 
												width:'auto',
												borderRadius:'0px'
											}}
										>
											<thead>
												<tr>
													<th>
														Upd.
													</th>
													<th>
														Del.
													</th>
													<th>
														ID
													</th>
													<th>
														Ac
													</th>
													<th>
														Category
													</th>
													<th>
														Remarks
													</th>
												</tr>
											</thead>
											<tbody>
												{editedEngineerCategories.map((row) => ( 		
													<tr
														key={row.id}
														style={{ 
															padding:0
														}}
													>
														<td 
															style={{
																padding:0, 
																textAlign:'center',
																backgroundColor:'#5a9ea5',
																position:'relative'
															}}
														>
															<Button
																onClick={() => handleUpdateCategories(row)}
																sx={{
																	position:'absolute',
																	top:0,
																	left:0,
																	backgroundColor:'#5a9ea5',
																	borderRadius:0,
																	minHeight:0,
																	height:'100%',
																	width:'100%',
																	'&:hover':{
																		backgroundColor:'#458a83',
																	}
																}}
															>
																{row.categoryId && row.aircraftTypeId ? 'U' : ''}
															</Button>
														</td>
														<td 
															style={{
																padding:0, 
																backgroundColor:'#8c28e7', 
																textAlign:'center',
																position:'relative'
															}}
														>
															<Button
																onClick={() => handleDeleteCategories(row)}
																sx={{
																	position:'absolute',
																	top:0,
																	left:0,
																	backgroundColor:'#8c28e7', 
																	borderRadius:0,
																	minHeight:0,
																	height:'100%',
																	width:'100%',
																	'&:hover':{
																		backgroundColor:'#6c1ea3',
																	}
																}}
															>
																{row.categoryId && row.aircraftTypeId ? 'D' : ''}
															</Button>
														</td>
														<td 
															style={{
																padding:0,
																position:'relative'
															}}
														>
															<Input 
																disabled
																sx={{
																	position:'absolute',
																	top:0,
																	left:0,
																	border:'0px',
																	borderRadius:'0px',
																	minWidth:'0px',
																	minHeight:'0px',
																	width:'100%',
																	height:'100%',
																	p:0.5,
																	'--variant-outlinedDisabledColor':'black',
																	'--variant-outlinedDisabledBorder':'black',
																	bgcolor:'#d6d3ce',
																	fontSize:'14px'
																}} 
																value={row.id}
															/>
														</td>
														<td 
															style={{
																padding:0,
																position:'relative',
																minWidth:'100px'
															}}
														>
															<Autocomplete
																disableClearable
																options={acList.map(data=> ({ label: data.Type }))}
																value={acList.find(item=>item.Id === row.aircraftTypeId)?.Type}
																onChange={(event, value) => handleChangeEngineerCategories(row.tableId, 'aircraftTypeId', value ? acList.find(item => item.Type === value.label)?.Id : '')}
																sx={{
																	position:'absolute',
																	top:0,
																	left:0,
																	border:'0px',
																	borderRadius:'0px',
																	minWidth:'0px',
																	minHeight:'0px',
																	width:'100%',
																	height:'100%',
																	m:0,
																	p:0,
																	'--variant-outlinedDisabledColor':'black',
																	'--variant-outlinedDisabledBorder':'black',
																	'--Input-decoratorChildHeight':'24px',
																	bgcolor:'white',
																	fontSize:'14px',
																	'&:hover button':{
																		backgroundColor:'transparent',
																	},
																}} 
															/>
														</td>
														<td 
															style={{
																padding:0,
																position:'relative',
																minWidth:'100px'
															}}
														>
															<Autocomplete
																disableClearable
																options={categories.map(data=> ({ label: data.category }))}
																value={categories.find(item=>item.id === row.categoryId)?.category}
																onChange={(event, value) => handleChangeEngineerCategories(row.tableId, 'categoryId', value ? categories.find(item => item.category === value.label)?.id : '')}
																sx={{
																	position:'absolute',
																	top:0,
																	left:0,
																	border:'0px',
																	borderRadius:'0px',
																	minWidth:'0px',
																	minHeight:'0px',
																	width:'100%',
																	height:'100%',
																	m:0,
																	p:0,
																	'--variant-outlinedDisabledColor':'black',
																	'--variant-outlinedDisabledBorder':'black',
																	'--Input-decoratorChildHeight':'24px',
																	bgcolor:'white',
																	fontSize:'14px',
																	'&:hover button':{
																		backgroundColor:'transparent',
																	},
																}} 
															/>
														</td>
														<td style={{padding:0}}>
															<Input 
																sx={{
																	border:'0px',
																	borderRadius:'0px',
																	minWidth:'0px',
																	minHeight:'0px',
																	width:'80px',
																	height:'22px',
																	m:0,
																	p:0,
																	'--variant-outlinedDisabledColor':'black',
																	'--variant-outlinedDisabledBorder':'black',
																	bgcolor:'white',
																}} 
																//value={editedData.skill ? props.skills.find(item => item.skill === editedData.skill)?.description : ''}
															/>
														</td>
													</tr>
													
												))}
											</tbody>
										</Table>
									</Sheet>
								</Grid>
							</>
						)}
					</Grid>
				</Box>
			)}
		</>
	);
}
