import * as React from 'react';

//Компоненты библиотеки @mui/joy
import Box from '@mui/joy/Box';

//Компоненты 
import Aircraft_Type from './Aircraft_Type/Aircraft_Type.tsx';
import Aircraft_Family from './Aircraft_Family/Aircraft_Family.tsx'

export default function Aircraft_Type_Family(props) {
	return (
		<React.Fragment>
			<Box
				sx={{
					display:'grid',
					gridTemplateRows:'1fr 1fr',
					height:'85vh'
				}}
			>
				<Aircraft_Type URL={props.URL}/>
				<Aircraft_Family URL={props.URL}/>
			</Box>
		</React.Fragment>
	);
}
