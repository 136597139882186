import * as React from 'react'
import { defaultProps } from '../../helpers/defaultProps.ts'
import styles from './style.scss'

interface RenderHorizontalLine {
    depth: number
    depthGap: number
    verticalLineOffset?: number
    horizontalLineStyles?: React.CSSProperties
    gapMode?: 'margin' | 'padding'
}

export const HorizontalLine = ({
    depth,
    depthGap,
    verticalLineOffset = defaultProps.verticalLineOffset,
    horizontalLineStyles = defaultProps.horizontalLineStyles,
    gapMode
}: RenderHorizontalLine) => (
    <div
        className={styles.horizontalLine}
        style={{
            position: 'absolute',
            top: 0,
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            width: `${depthGap + 17 - verticalLineOffset}px`,
            left:
                gapMode === 'padding'
                    ? `${verticalLineOffset - (depthGap + 17) + depth * (depthGap + 17)}px`
                    : `${verticalLineOffset - depthGap + 17}px`
        }}
    >
        <svg
            style={{
                height: `${horizontalLineStyles.strokeWidth}px`,
                width: '100%'
            }}
        >
            <line x1="0%" y1="0%" x2="100%" y2="0%" style={horizontalLineStyles} />
        </svg>
    </div>
)
