import * as React from 'react';
import {useNavigate, useLocation } from 'react-router-dom';

import Box from '@mui/joy/Box';
import Menu from '@mui/joy/Menu';
import Link from '@mui/joy/Link';
import Stack from '@mui/joy/Stack';
import Input from '@mui/joy/Input';
import Avatar from '@mui/joy/Avatar';
import Dropdown from '@mui/joy/Dropdown';
import MenuItem from '@mui/joy/MenuItem';
import Typography from '@mui/joy/Typography';
import MenuButton from '@mui/joy/MenuButton';
import ListDivider from '@mui/joy/ListDivider';

import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';

import URL from '../URL.js'

interface HeaderProps {
	setSearchText: React.Dispatch<React.SetStateAction<string>>;
}
  
export default function Header(props: HeaderProps) {
	const { setSearchText } = props;
	const navigate = useNavigate();
  
    React.useEffect(() => {
		if (!localStorage.getItem('asid')) {
			navigate('/SignIn');
			localStorage.removeItem('name');
			localStorage.removeItem('login');
		}
	}, []);
  	
	const handleLogout = async () => {
		try {
			const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/Self`, { method: 'DELETE', headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {
				navigate('/SignIn');
				localStorage.removeItem('name');
				localStorage.removeItem('login');
				localStorage.removeItem('asid');
			} else {
				console.error('Ошибка выполнения запроса');
			}
		} catch (error) {
			console.error('Произошла ошибка', error);
		}
	};
  
	return (
		<Box
			sx={{
			display: 'flex',
			flexGrow: 1,
			justifyContent: 'space-between',
			}}
		>
			<Stack
				direction="row"
				justifyContent="center"
				alignItems="center"
				spacing={1}
				sx={{ display: { xs: 'none', sm: 'flex' },marginRight: '107px'}}
			>
				<Link href="/">
					<img width="180" src="images/logo.svg" alt="" />
				</Link>
			</Stack>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					gap: 1.5,
					alignItems: 'center',
				}}
			>
				<Input
					size="sm"
					variant="outlined"
					placeholder="Search module…"
					startDecorator={<SearchRoundedIcon color="primary" />}
					onChange={(event) => setSearchText(event.target.value)}
				/>
				<Dropdown>
					<MenuButton
						variant="plain"
						size="sm"
						sx={{ maxWidth: '50px', maxHeight: '50px', borderRadius: '9999999px' }}
					>
						<Avatar sx={{ maxWidth: '50px', maxHeight: '50px' }} />
					</MenuButton>
					<Menu
						placement="bottom-end"
						size="sm"
						sx={{
							zIndex: '99999',
							p: 1,
							gap: 1,
							'--ListItem-radius': 'var(--joy-radius-sm)',
						}}
					>
						<MenuItem>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<Avatar sx={{ borderRadius: '50%' }} />
								<Box sx={{ ml: 1.5 }}>
									<Typography level="title-sm" textColor="text.primary">
										{localStorage.getItem('name')}
									</Typography>
									<Typography level="body-xs" textColor="text.tertiary">
										{localStorage.getItem('login')}
									</Typography>
								</Box>
							</Box>
						</MenuItem>
						<ListDivider />
						<Link href="https://docs.alaskar.tech" target="_blank" underline="none">
							<MenuItem>
								<HelpRoundedIcon />
								Help
							</MenuItem>
						</Link>
						<Link href="/Settings" underline="none">
							<MenuItem>
								<SettingsRoundedIcon />
								Settings
							</MenuItem>
						</Link>
						<ListDivider />
						<MenuItem onClick={handleLogout}>
							<LogoutRoundedIcon />
							Log out
						</MenuItem>
					</Menu>
				</Dropdown>
			</Box>
		</Box>
	);
}