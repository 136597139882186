import { AmpPosIcons } from "./Dictionaries/AmpPosIcons.ts";
import { AmpPlanMaintIconContext } from "./Context/AmpPlanMaintIconContext.ts";
import { Interpreter } from "../../../../../../Base/TreeView/IconInterpterer/Interpreter.ts";
import { ITreeViewIcon } from "../../../../../../Base/TreeView/IconInterpterer/ITreeViewIcon.ts";

export class AmpPlanMaintIconInterpreter extends Interpreter<AmpPlanMaintIconContext> {
    public Interpret(context: AmpPlanMaintIconContext): ITreeViewIcon | undefined {
        if (context.isRoot)
            return AmpPosIcons.Clock02;

        return this.isGreenBox(context.positionEffectivity) 
            ? AmpPosIcons.GreenBox 
            : AmpPosIcons.BlueBox;
    }

    private isGreenBox(positionEffectivity : string | null) {
        return positionEffectivity == 'Y';
    }
}