import * as React from 'react';

import Box from '@mui/joy/Box';
import Tab from '@mui/joy/Tab';
import Tabs from '@mui/joy/Tabs';
import Input from '@mui/joy/Input'
import TabList from '@mui/joy/TabList';
import TabPanel from '@mui/joy/TabPanel';
import Typography from '@mui/joy/Typography';
import CssBaseline from '@mui/joy/CssBaseline';
import { CssVarsProvider } from '@mui/joy/styles';

import LockIcon from '@mui/icons-material/Lock';

import Header from '../Components/Header.tsx';
import Password from './Password.tsx'


export default function Settings() {
	document.body.style.backgroundColor = 'white';
	document.title = 'Settings'
	const tabStyle = {fontSize:'14px'}
	const inputStyle = { borderRadius: 0, px:1, mx: 1, height: 30, minHeight: 0, border:'1px solid black', color:'#000084', fontWeight:'bold', fontSize:'14px' };

	const [selectedTab, setSelectedTab] = React.useState ('Password'); 

	return (
		<CssVarsProvider disableTransitionOnChange>
			<CssBaseline />
			<Box sx={{ display: 'flex', minHeight: '100dvh', minWidth:'1100px' }}>
				<Header title="Settings"/>
				<Box
					component="main"
					className="MainContent"
					sx={{
						pt:'calc(var(--Header-height))',
						flex: 1,
						display: 'flex',
						flexDirection: 'column',
						minWidth: 0,
						height: '100dvh',
					}}
				>
					<Tabs
						value={selectedTab}
						onChange={(event, newValue) => setSelectedTab(newValue)}
						orientation="vertical"
						sx={{
							flexDirection: 'row',
							whiteSpace: 'nowrap',
							position:'relative',
							height:'100%'
						}}
					>
						<TabList >
							<Tab value="Password" sx={tabStyle}>
								<LockIcon/>
								Password
							</Tab>
						</TabList>
						<TabPanel value="Password" sx={{p:'0px'}}>
							<Password/>
						</TabPanel>
					</Tabs>		
				</Box>
			</Box>
		</CssVarsProvider>
	);
}
