import * as React from 'react';

import Box from '@mui/joy/Box';
import Tab from '@mui/joy/Tab';
import Tabs from '@mui/joy/Tabs';
import Grid from '@mui/joy/Grid';
import Table from '@mui/joy/Table';
import Input from '@mui/joy/Input';
import Radio from '@mui/joy/Radio';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';
import TabList from '@mui/joy/TabList';
import TabPanel from '@mui/joy/TabPanel';
import Textarea from '@mui/joy/Textarea';
import Checkbox from '@mui/joy/Checkbox';
import FormLabel from '@mui/joy/FormLabel';
import RadioGroup from '@mui/joy/RadioGroup';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import Autocomplete from '@mui/joy/Autocomplete';

export default function AddEditeForm(props) {
	//Стили
	const inputStyle = { borderRadius: 0, px:1, mx: 0.5, height: 26, minHeight: 0, border:'1px solid black', color:'#000084', fontWeight:'bold', fontSize:'14px',"--Input-decoratorChildHeight": "24px", lineHeight:1 };
	const labelStyle = { mx: 1, my: 0.5, p: 0, fontSize:'14px', alignSelf:'center', lineHeight:1 };
	const formControlStyle = { my: 0.2 };
	const tabStyle = {fontSize:'14px', lineHeight:1,'--ListItem-minHeight':1};

	const [editedData, setEditedData] = React.useState({});
	const [isAtaValid, setIsAtaValid] = React.useState(true);
	const [meta, setMeta] = React.useState([{name:'',max:0}]);

	React.useEffect(() => {
		setEditedData(props.selectedRow);
		setIsAtaValid(true);
	}, [props.selectedRow]);

	//Запрос ограничений для полей
	React.useEffect(() => {
		const fetchMeta = async () => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_ADMIN}/Atas/Meta()`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setMeta(result);
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		};
		fetchMeta();
	}, []);

	//Запрос проверки валидности ATA
	const checkValidATA = async () => {
		const response = await fetch(`${props.URL}${process.env.REACT_APP_API_ADMIN}/Atas/ValidAta()?ata='${editedData.ataCode}'`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {
			const result = await response.json();
			setIsAtaValid(result.value);
			return result.value;
		} else {
			props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
		}
	};

	//Обработчик события добавления новой записи
	const handleAdd = async () =>  {
		if (await checkValidATA()) {
			fetch(
				`${props.URL}${process.env.REACT_APP_API_ADMIN}/Atas`, 
				{
					method: 'POST',
					headers: {
					'Authorization': `Bearer ${localStorage.getItem('asid')}`,
					'Content-Type': 'application/json'
					},
					body: JSON.stringify(editedData)
				}
			)
			.then((response) => {
				if (!response.ok){
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				} else {
					props.setResponseStatus({...props.responseStatus, successfulStatus:'Add'});
				}
				props.reloadTable();
				return response.json();
			})
			.catch(error => console.error('Ошибка создания группы:', error));
		}
	}

	//Обработчик события обновления выбранной записи
	const handleUpdate = async () => {
		if(editedData.ataCode === props.selectedRow.ataCode || await checkValidATA()){
			setIsAtaValid(true);
			fetch(
				`${props.URL}${process.env.REACT_APP_API_ADMIN}/Atas/${props.selectedRow.id}`, 
				{
					method: 'PATCH',
					headers: {
					'Authorization': `Bearer ${localStorage.getItem('asid')}`,
					'Content-Type': 'application/json'
					},
					body: JSON.stringify(editedData)
				}
			)
			.then((response) => {
				if (!response.ok){
					props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
				} else {
					props.setResponseStatus({...props.responseStatus, successfulStatus:'Update'});
				}
				props.reloadTable();
				return response.json();
			})
			.catch(error => console.error('Ошибка обновления группы:', error));
		}
	}

	//Обработчик события удаления выбранной записи
	const handleDelete = () => {
		fetch(
			`${props.URL}${process.env.REACT_APP_API_ADMIN}/Atas/${props.selectedRow.id}`, 
			{
				method: 'DELETE',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				}
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Delete'});
			}
			props.reloadTable();
			return response.json();
		})
		.catch(error => console.error('Ошибка удаления группы:', error));
	}
	
	//Внесение изменений в data
	const handleChange = (property, value) => {

		//Проверка максимальной длинны строки
		const maxLengthDefault = 255;
		let maxLength = (meta.find(item => item.name.toLowerCase() === property.toLowerCase())?.max ?? "") || maxLengthDefault;
		if (value.length > maxLength) return;	
		
		setEditedData({ ...editedData, [property]: value });
	};

	const IntervalEditore = () => {
		return(
			<Box sx={{display:'flex', flexDirection:'column'}}>
				<Box 
					sx={{
						position:'relative',
						border:'1px solid black',
						mt:1,
						pb:1
					}}
				>
					<Box 
						sx={{
							display:'flex', 
							position:'absolute',
							top:-12,
							left:5
						}}
					>
						<Typography
							sx={{
								bgcolor:'#d6d3ce',
								fontWeight:'600',
								color:'black',
								fontSize:14
							}}
						>
							Interval:
						</Typography>
						<Typography
							sx={{
								ml:3,
								bgcolor:'#d6d3ce',
								color:'red'
							}}
						>
							*
						</Typography>
					</Box>
					
						<Grid container spacing={0}>
							<Grid xs={4}></Grid>
							<Grid xs={1}>
								<FormControl sx={{...formControlStyle,alignItems:'center'}}>
									<FormLabel sx={labelStyle}>DY:</FormLabel>
									<Tooltip title='Days Selector'>
										<Checkbox 
											variant="outlined"
											color="neutral"
											sx={{
												fontSize:'14px',
												'--Checkbox-size':'15px',
												gap:0.4,
												'& .css-r4cpcz-JoyCheckbox-checkbox': {
													borderRadius:0,
													'--joy-palette-neutral-outlinedBorder':'black',
												},
												'& .css-n110n' : {
													borderRadius:0,
													'--joy-palette-neutral-outlinedBorder':'black',
												},
											}}
											//checked={editedData.shift}
											//onChange={(event) => handleChange('shift', event.target.checked)}
										/>
									</Tooltip>
								</FormControl>
							</Grid>
							<Grid xs={1}>
								<FormControl sx={{...formControlStyle,alignItems:'center'}}>
									<FormLabel sx={labelStyle}>MO:</FormLabel>
									<Tooltip title='Months Selector'>
										<Checkbox 
											variant="outlined"
											color="neutral"
											sx={{
												fontSize:'14px',
												'--Checkbox-size':'15px',
												gap:0.4,
												'& .css-r4cpcz-JoyCheckbox-checkbox': {
													borderRadius:0,
													'--joy-palette-neutral-outlinedBorder':'black',
												},
												'& .css-n110n' : {
													borderRadius:0,
													'--joy-palette-neutral-outlinedBorder':'black',
												},
											}}
											//checked={editedData.shift}
											//onChange={(event) => handleChange('shift', event.target.checked)}
										/>
									</Tooltip>
								</FormControl>
							</Grid>
							<Grid xs={1}>
								<FormControl sx={{...formControlStyle,alignItems:'center'}}>
									<FormLabel sx={labelStyle}>YR:</FormLabel>
									<Tooltip title='Years Selector'>
										<Checkbox 
											variant="outlined"
											color="neutral"
											sx={{
												fontSize:'14px',
												'--Checkbox-size':'15px',
												gap:0.4,
												'& .css-r4cpcz-JoyCheckbox-checkbox': {
													borderRadius:0,
													'--joy-palette-neutral-outlinedBorder':'black',
												},
												'& .css-n110n' : {
													borderRadius:0,
													'--joy-palette-neutral-outlinedBorder':'black',
												},
											}}
											//checked={editedData.shift}
											//onChange={(event) => handleChange('shift', event.target.checked)}
										/>
									</Tooltip>
								</FormControl>
							</Grid>
							<Grid xs={5}>
								<FormControl sx={{mt:2}}>
									<Tooltip title='Tasks for Replacements or Other Tasks are required to print in WP Y/N. Specify Task Card Number below and copy task in pdf to TC Location'>
										<Checkbox 
											label=':Task Card Required'
											variant="outlined"
											color="neutral"
											sx={{
												fontSize:'14px',
												'--Checkbox-size':'15px',
												fontWeight:'bold',
												gap:0.4,
												'& .css-r4cpcz-JoyCheckbox-checkbox': {
													borderRadius:0,
													'--joy-palette-neutral-outlinedBorder':'black',
												},
												'& .css-n110n' : {
													borderRadius:0,
													'--joy-palette-neutral-outlinedBorder':'black',
												},
											}}
											//checked={editedData.shift}
											//onChange={(event) => handleChange('shift', event.target.checked)}
										/>
									</Tooltip>
								</FormControl>
							</Grid>				
							
							<Grid xs={2}>
								<FormControl>
									<FormLabel sx={labelStyle}>FH:</FormLabel>
									<Input  
										sx={inputStyle} 
										value={editedData?.title ?? ''}
										onChange={(event) => handleChange('title', event.target.value)}
									/>
								</FormControl>
							</Grid>
							<Grid xs={2}>
								<FormControl>
									<FormLabel sx={labelStyle}>FC:</FormLabel>
									<Input  
										sx={inputStyle} 
										value={editedData?.title ?? ''}
										onChange={(event) => handleChange('title', event.target.value)}
									/>
								</FormControl>
							</Grid>
							<Grid xs={1}></Grid>
							<Grid xs={2}>
								<FormLabel sx={{...labelStyle, color:'transparent'}}>.</FormLabel>
								<FormControl>
									<Input  
										sx={inputStyle} 
										value={editedData?.title ?? ''}
										onChange={(event) => handleChange('title', event.target.value)}
									/>
								</FormControl>
							</Grid>
							<Grid xs={5}>
								<FormControl>
									<FormLabel sx={labelStyle}>AMM Reference:</FormLabel>
									<Input  
										sx={inputStyle} 
										value={editedData?.title ?? ''}
										onChange={(event) => handleChange('title', event.target.value)}
									/>
								</FormControl>
							</Grid>

							<Grid xs={2}>
								<FormControl sx={{...formControlStyle, ml:0.4}}>
									<Tooltip title='APU Flight Data Utilization'>
										<Checkbox 
											label=':APU Data'
											variant="outlined"
											color="neutral"
											sx={{
												fontSize:'14px',
												'--Checkbox-size':'15px',
												gap:0.4,
												'& .css-r4cpcz-JoyCheckbox-checkbox': {
													borderRadius:0,
													'--joy-palette-neutral-outlinedBorder':'black',
												},
												'& .css-n110n' : {
													borderRadius:0,
													'--joy-palette-neutral-outlinedBorder':'black',
												},
											}}
											//checked={editedData.shift}
											//onChange={(event) => handleChange('shift', event.target.checked)}
										/>
									</Tooltip>
								</FormControl>
							</Grid>
							<Grid xs={5}>
								<FormControl>
									<FormLabel sx={labelStyle}>DOC. Reference Data:</FormLabel>
									<Input  
										sx={inputStyle} 
										value={editedData?.title ?? ''}
										onChange={(event) => handleChange('title', event.target.value)}
									/>
								</FormControl>
							</Grid>
							<Grid xs={5}>
								<FormControl>
									<FormLabel sx={labelStyle}>Associated TC Reference:</FormLabel>
									<Input  
										sx={inputStyle} 
										value={editedData?.title ?? ''}
										onChange={(event) => handleChange('title', event.target.value)}
									/>
								</FormControl>
							</Grid>
						</Grid>
				</Box>
				<Box 
					sx={{
						position:'relative',
						border:'1px solid black',
						mt:1
					}}
				>
					<Box 
						sx={{
							display:'flex', 
							position:'absolute',
							top:-8,
							left:5
						}}
					>
						<Typography
							sx={{
								bgcolor:'#d6d3ce',
								fontWeight:'600',
								color:'black',
								fontSize:14,
								lineHeight:1
							}}
						>
							Part Effectivity:
						</Typography>
					</Box>
					<Grid container spacing={0} sx={{ flexGrow: 1, height:125, pt:0.6, px:1 }}>
						<Grid xs={12}>
							<Box
								sx={{
									border:'1px solid #d2dbe4',
									height:'110px',
									mx:0.5,
									mt:0.5,
									borderRadius:'0px',
									bgcolor:'white',
									overflow:'auto'
								}}
							>
								<Table
									aria-labelledby="tableTitle"
									borderAxis="none"
									stickyHeader
									hoverRow
									sx={{
										'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
										'--TableCell-paddingY': '1px',
										"--TableCell-height": "24px",
										'--TableCell-paddingX': '5px',
										tableLayout: 'auto', 
										width:'auto',
										borderRadius:'0px'
									}}
								>
									<tbody>
										{/** 
										{moduleList.map((row) => (
											<tr
												key={row.id}
												onClick={() => setSelectedGroupForModule(row)}
												style={{ 
													backgroundColor: selectedGroupForModule === row ? '#00cfd6' : 'white',
													color: selectedGroupForModule === row ? 'white' : 'black',
												}}
											>
												<td style={rowStyle}>{row.id}</td>
												<td style={rowStyle}>{row.groupName}</td>
												<td style={rowStyle}>{row.groupSta}</td>
												<td style={rowStyle}>{row.permission}</td>
												<td style={rowStyle}>{row.activate}</td>
												<td style={rowStyle}>{row.remarks}</td>
											</tr>
										))}*/}
									</tbody>
								</Table>
							</Box>
						</Grid>
					</Grid>
				</Box>
				<Box 
					sx={{
						position:'relative',
						border:'1px solid black',
						mt:1
					}}
				>
					<Box 
						sx={{
							display:'flex', 
							position:'absolute',
							top:-8,
							left:5
						}}
					>
						<Typography
							sx={{
								bgcolor:'#d6d3ce',
								fontWeight:'600',
								color:'black',
								fontSize:14,
								lineHeight:1
							}}
						>
							Associated Treatments:
						</Typography>
					</Box>
					<Grid container spacing={0} sx={{ flexGrow: 1, height:125, pt:0.6, px:1 }}>
						<Grid xs={12}>
							<Box
								sx={{
									border:'1px solid #d2dbe4',
									height:'110px',
									mx:0.5,
									mt:0.5,
									borderRadius:'0px',
									bgcolor:'white',
									overflow:'auto'
								}}
							>
								<Table
									aria-labelledby="tableTitle"
									borderAxis="none"
									stickyHeader
									hoverRow
									sx={{
										'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
										'--TableCell-paddingY': '1px',
										"--TableCell-height": "24px",
										'--TableCell-paddingX': '5px',
										tableLayout: 'auto', 
										width:'auto',
										borderRadius:'0px'
									}}
								>
									<tbody>
										{/** 
										{moduleList.map((row) => (
											<tr
												key={row.id}
												onClick={() => setSelectedGroupForModule(row)}
												style={{ 
													backgroundColor: selectedGroupForModule === row ? '#00cfd6' : 'white',
													color: selectedGroupForModule === row ? 'white' : 'black',
												}}
											>
												<td style={rowStyle}>{row.id}</td>
												<td style={rowStyle}>{row.groupName}</td>
												<td style={rowStyle}>{row.groupSta}</td>
												<td style={rowStyle}>{row.permission}</td>
												<td style={rowStyle}>{row.activate}</td>
												<td style={rowStyle}>{row.remarks}</td>
											</tr>
										))}*/}
									</tbody>
								</Table>
							</Box>
						</Grid>
					</Grid>
				</Box>
				<Box 
					sx={{
						position:'relative',
						border:'1px solid black',
						mt:1
					}}
				>
					<Box 
						sx={{
							display:'flex', 
							position:'absolute',
							top:-8,
							left:5
						}}
					>
						<Typography
							sx={{
								bgcolor:'#d6d3ce',
								fontWeight:'600',
								color:'black',
								fontSize:14,
								lineHeight:1
							}}
						>
							Activated Task Cards or EC:
						</Typography>
					</Box>
					<Grid container spacing={0} sx={{ flexGrow: 1, height:125, pt:0.6, px:1 }}>
						<Grid xs={4} sx={{display:'flex', alignItems:'center'}}>
							<RadioGroup orientation="horizontal">
								<Radio 
									value="Task" 
									label="Task" 
									variant="outlined" 
									color='neutral'
									sx={{
										'--Radio-size':'14px',
										gap:0.5,
										fontSize:14
									}}
								/>
								<Radio 
									value="EC" 
									label="EC" 
									variant="outlined" 
									color='neutral'
									sx={{
										'--Radio-size':'14px',
										gap:0.5,
										fontSize:14
									}}
								/>
							</RadioGroup>
						</Grid>
						<Grid xs={2} sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
							<Typography>
								Filter:
							</Typography>
						</Grid>
						<Grid xs={3.5} sx={{display:'flex', alignItems:'center'}}>
							<Input  
								sx={inputStyle} 
								value={editedData?.title ?? ''}
								onChange={(event) => handleChange('title', event.target.value)}
							/>
						</Grid>
						<Grid xs={2} sx={{display:'flex', alignItems:'center'}}>
							<Button
								//onClick={handleConfirm}
								color='neutral'
								sx={{
									borderRadius:0,
									height: 25, 
									minHeight: 0,
									bgcolor:'#d6d3ce',
									color:'black',
									border:'2px outset grey',
								}}
							>
								Add
							</Button>
						</Grid>

						<Grid xs={12}>
							<Box
								sx={{
									border:'1px solid #d2dbe4',
									height:'86px',
									mx:0.5,
									mt:0.1,
									borderRadius:'0px',
									bgcolor:'white',
									overflow:'auto'
								}}
							>
								<Table
									aria-labelledby="tableTitle"
									borderAxis="none"
									stickyHeader
									hoverRow
									sx={{
										'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
										'--TableCell-paddingY': '1px',
										"--TableCell-height": "24px",
										'--TableCell-paddingX': '5px',
										tableLayout: 'auto', 
										width:'auto',
										borderRadius:'0px'
									}}
								>
									<tbody>
										{/** 
										{moduleList.map((row) => (
											<tr
												key={row.id}
												onClick={() => setSelectedGroupForModule(row)}
												style={{ 
													backgroundColor: selectedGroupForModule === row ? '#00cfd6' : 'white',
													color: selectedGroupForModule === row ? 'white' : 'black',
												}}
											>
												<td style={rowStyle}>{row.id}</td>
												<td style={rowStyle}>{row.groupName}</td>
												<td style={rowStyle}>{row.groupSta}</td>
												<td style={rowStyle}>{row.permission}</td>
												<td style={rowStyle}>{row.activate}</td>
												<td style={rowStyle}>{row.remarks}</td>
											</tr>
										))}*/}
									</tbody>
								</Table>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Box>
		)
	}

	const StartThresholdEditore = () => {
		return (
			<Box sx={{display:'flex', flexDirection:'column'}}>
				<Box 
					sx={{
						position:'relative',
						border:'1px solid black',
						mt:1,
						pb:1
					}}
				>
					<Box 
						sx={{
							display:'flex', 
							position:'absolute',
							top:-12,
							left:5
						}}
					>
						<Typography
							sx={{
								bgcolor:'#d6d3ce',
								fontWeight:'600',
								color:'black',
								fontSize:14
							}}
						>
							Interval:
						</Typography>
						<Typography
							sx={{
								ml:3,
								bgcolor:'#d6d3ce',
								color:'red'
							}}
						>
							*
						</Typography>
					</Box>
					
						<Grid container spacing={0}>
							<Grid xs={4}></Grid>
							<Grid xs={1}>
								<FormControl sx={{...formControlStyle,alignItems:'center'}}>
									<FormLabel sx={labelStyle}>DY:</FormLabel>
									<Tooltip title='Days Selector'>
										<Checkbox 
											variant="outlined"
											color="neutral"
											sx={{
												fontSize:'14px',
												'--Checkbox-size':'15px',
												gap:0.4,
												'& .css-r4cpcz-JoyCheckbox-checkbox': {
													borderRadius:0,
													'--joy-palette-neutral-outlinedBorder':'black',
												},
												'& .css-n110n' : {
													borderRadius:0,
													'--joy-palette-neutral-outlinedBorder':'black',
												},
											}}
											//checked={editedData.shift}
											//onChange={(event) => handleChange('shift', event.target.checked)}
										/>
									</Tooltip>
								</FormControl>
							</Grid>
							<Grid xs={1}>
								<FormControl sx={{...formControlStyle,alignItems:'center'}}>
									<FormLabel sx={labelStyle}>MO:</FormLabel>
									<Tooltip title='Months Selector'>
										<Checkbox 
											variant="outlined"
											color="neutral"
											sx={{
												fontSize:'14px',
												'--Checkbox-size':'15px',
												gap:0.4,
												'& .css-r4cpcz-JoyCheckbox-checkbox': {
													borderRadius:0,
													'--joy-palette-neutral-outlinedBorder':'black',
												},
												'& .css-n110n' : {
													borderRadius:0,
													'--joy-palette-neutral-outlinedBorder':'black',
												},
											}}
											//checked={editedData.shift}
											//onChange={(event) => handleChange('shift', event.target.checked)}
										/>
									</Tooltip>
								</FormControl>
							</Grid>
							<Grid xs={1}>
								<FormControl sx={{...formControlStyle,alignItems:'center'}}>
									<FormLabel sx={labelStyle}>YR:</FormLabel>
									<Tooltip title='Years Selector'>
										<Checkbox 
											variant="outlined"
											color="neutral"
											sx={{
												fontSize:'14px',
												'--Checkbox-size':'15px',
												gap:0.4,
												'& .css-r4cpcz-JoyCheckbox-checkbox': {
													borderRadius:0,
													'--joy-palette-neutral-outlinedBorder':'black',
												},
												'& .css-n110n' : {
													borderRadius:0,
													'--joy-palette-neutral-outlinedBorder':'black',
												},
											}}
											//checked={editedData.shift}
											//onChange={(event) => handleChange('shift', event.target.checked)}
										/>
									</Tooltip>
								</FormControl>
							</Grid>
							<Grid xs={5}/>			
							
							<Grid xs={2}>
								<FormControl>
									<FormLabel sx={labelStyle}>FH:</FormLabel>
									<Input  
										sx={inputStyle} 
										value={editedData?.title ?? ''}
										onChange={(event) => handleChange('title', event.target.value)}
									/>
								</FormControl>
							</Grid>
							<Grid xs={2}>
								<FormControl>
									<FormLabel sx={labelStyle}>FC:</FormLabel>
									<Input  
										sx={inputStyle} 
										value={editedData?.title ?? ''}
										onChange={(event) => handleChange('title', event.target.value)}
									/>
								</FormControl>
							</Grid>
							<Grid xs={1}></Grid>
							<Grid xs={2}>
								<FormLabel sx={{...labelStyle, color:'transparent'}}>.</FormLabel>
								<FormControl>
									<Input  
										sx={inputStyle} 
										value={editedData?.title ?? ''}
										onChange={(event) => handleChange('title', event.target.value)}
									/>
								</FormControl>
							</Grid>
							<Grid xs={5}>
								<FormControl sx={{mt:2}}>
									<Tooltip title='Tasks for Replacements or Other Tasks are required to print in WP Y/N. Specify Task Card Number below and copy task in pdf to TC Location'>
										<Checkbox 
											label=':Task Card Required'
											variant="outlined"
											color="neutral"
											sx={{
												fontSize:'14px',
												'--Checkbox-size':'15px',
												fontWeight:'bold',
												gap:0.4,
												'& .css-r4cpcz-JoyCheckbox-checkbox': {
													borderRadius:0,
													'--joy-palette-neutral-outlinedBorder':'black',
												},
												'& .css-n110n' : {
													borderRadius:0,
													'--joy-palette-neutral-outlinedBorder':'black',
												},
											}}
											//checked={editedData.shift}
											//onChange={(event) => handleChange('shift', event.target.checked)}
										/>
									</Tooltip>
								</FormControl>
							</Grid>

							<Grid xs={2}>
								<FormControl sx={{...formControlStyle, ml:0.4}}>
									<Tooltip title='APU Flight Data Utilization'>
										<Checkbox 
											label=':APU Data'
											variant="outlined"
											color="neutral"
											sx={{
												fontSize:'14px',
												'--Checkbox-size':'15px',
												gap:0.4,
												'& .css-r4cpcz-JoyCheckbox-checkbox': {
													borderRadius:0,
													'--joy-palette-neutral-outlinedBorder':'black',
												},
												'& .css-n110n' : {
													borderRadius:0,
													'--joy-palette-neutral-outlinedBorder':'black',
												},
											}}
											//checked={editedData.shift}
											//onChange={(event) => handleChange('shift', event.target.checked)}
										/>
									</Tooltip>
								</FormControl>
							</Grid>
							<Grid xs={5}>
								<FormControl>
									<FormLabel sx={labelStyle}>DOC. Reference Data:</FormLabel>
									<Input  
										sx={inputStyle} 
										value={editedData?.title ?? ''}
										onChange={(event) => handleChange('title', event.target.value)}
									/>
								</FormControl>
							</Grid>
							<Grid xs={5}>
								<FormControl>
									<FormLabel sx={labelStyle}>AMM Reference:</FormLabel>
									<Input  
										sx={inputStyle} 
										value={editedData?.title ?? ''}
										onChange={(event) => handleChange('title', event.target.value)}
									/>
								</FormControl>
							</Grid>
						</Grid>
				</Box>
				<Box 
					sx={{
						position:'relative',
						border:'1px solid black',
						mt:1
					}}
				>
					<Box 
						sx={{
							display:'flex', 
							position:'absolute',
							top:-8,
							left:5
						}}
					>
						<Typography
							sx={{
								bgcolor:'#d6d3ce',
								fontWeight:'600',
								color:'black',
								fontSize:14,
								lineHeight:1
							}}
						>
							Part Effectivity:
						</Typography>
					</Box>
					<Grid container spacing={0} sx={{ flexGrow: 1, height:125, pt:0.6, px:1 }}>
						<Grid xs={12}>
							<Box
								sx={{
									border:'1px solid #d2dbe4',
									height:'110px',
									mx:0.5,
									mt:0.5,
									borderRadius:'0px',
									bgcolor:'white',
									overflow:'auto'
								}}
							>
								<Table
									aria-labelledby="tableTitle"
									borderAxis="none"
									stickyHeader
									hoverRow
									sx={{
										'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
										'--TableCell-paddingY': '1px',
										"--TableCell-height": "24px",
										'--TableCell-paddingX': '5px',
										tableLayout: 'auto', 
										width:'auto',
										borderRadius:'0px'
									}}
								>
									<tbody>
										{/** 
										{moduleList.map((row) => (
											<tr
												key={row.id}
												onClick={() => setSelectedGroupForModule(row)}
												style={{ 
													backgroundColor: selectedGroupForModule === row ? '#00cfd6' : 'white',
													color: selectedGroupForModule === row ? 'white' : 'black',
												}}
											>
												<td style={rowStyle}>{row.id}</td>
												<td style={rowStyle}>{row.groupName}</td>
												<td style={rowStyle}>{row.groupSta}</td>
												<td style={rowStyle}>{row.permission}</td>
												<td style={rowStyle}>{row.activate}</td>
												<td style={rowStyle}>{row.remarks}</td>
											</tr>
										))}*/}
									</tbody>
								</Table>
							</Box>
						</Grid>
					</Grid>
				</Box>
				<Box 
					sx={{
						position:'relative',
						border:'1px solid black',
						mt:1
					}}
				>
					<Box 
						sx={{
							display:'flex', 
							position:'absolute',
							top:-8,
							left:5
						}}
					>
						<Typography
							sx={{
								bgcolor:'#d6d3ce',
								fontWeight:'600',
								color:'black',
								fontSize:14,
								lineHeight:1
							}}
						>
							Associated Treatments:
						</Typography>
					</Box>
					<Grid container spacing={0} sx={{ flexGrow: 1, height:125, pt:0.6, px:1 }}>
						<Grid xs={12}>
							<Box
								sx={{
									border:'1px solid #d2dbe4',
									height:'110px',
									mx:0.5,
									mt:0.5,
									borderRadius:'0px',
									bgcolor:'white',
									overflow:'auto'
								}}
							>
								<Table
									aria-labelledby="tableTitle"
									borderAxis="none"
									stickyHeader
									hoverRow
									sx={{
										'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
										'--TableCell-paddingY': '1px',
										"--TableCell-height": "24px",
										'--TableCell-paddingX': '5px',
										tableLayout: 'auto', 
										width:'auto',
										borderRadius:'0px'
									}}
								>
									<tbody>
										{/** 
										{moduleList.map((row) => (
											<tr
												key={row.id}
												onClick={() => setSelectedGroupForModule(row)}
												style={{ 
													backgroundColor: selectedGroupForModule === row ? '#00cfd6' : 'white',
													color: selectedGroupForModule === row ? 'white' : 'black',
												}}
											>
												<td style={rowStyle}>{row.id}</td>
												<td style={rowStyle}>{row.groupName}</td>
												<td style={rowStyle}>{row.groupSta}</td>
												<td style={rowStyle}>{row.permission}</td>
												<td style={rowStyle}>{row.activate}</td>
												<td style={rowStyle}>{row.remarks}</td>
											</tr>
										))}*/}
									</tbody>
								</Table>
							</Box>
						</Grid>
					</Grid>
				</Box>
				<Box 
					sx={{
						position:'relative',
						border:'1px solid black',
						mt:1
					}}
				>
					<Box 
						sx={{
							display:'flex', 
							position:'absolute',
							top:-8,
							left:5
						}}
					>
						<Typography
							sx={{
								bgcolor:'#d6d3ce',
								fontWeight:'600',
								color:'black',
								fontSize:14,
								lineHeight:1
							}}
						>
							Activated Task Cards or EC:
						</Typography>
					</Box>
					<Grid container spacing={0} sx={{ flexGrow: 1, height:125, pt:0.6, px:1 }}>
						<Grid xs={4} sx={{display:'flex', alignItems:'center'}}>
							<RadioGroup orientation="horizontal">
								<Radio 
									value="Task" 
									label="Task" 
									variant="outlined" 
									color='neutral'
									sx={{
										'--Radio-size':'14px',
										gap:0.5,
										fontSize:14
									}}
								/>
								<Radio 
									value="EC" 
									label="EC" 
									variant="outlined" 
									color='neutral'
									sx={{
										'--Radio-size':'14px',
										gap:0.5,
										fontSize:14
									}}
								/>
							</RadioGroup>
						</Grid>
						<Grid xs={2} sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
							<Typography>
								Filter:
							</Typography>
						</Grid>
						<Grid xs={3.5} sx={{display:'flex', alignItems:'center'}}>
							<Input  
								sx={inputStyle} 
								value={editedData?.title ?? ''}
								onChange={(event) => handleChange('title', event.target.value)}
							/>
						</Grid>
						<Grid xs={2} sx={{display:'flex', alignItems:'center'}}>
							<Button
								//onClick={handleConfirm}
								color='neutral'
								sx={{
									borderRadius:0,
									height: 25, 
									minHeight: 0,
									bgcolor:'#d6d3ce',
									color:'black',
									border:'2px outset grey',
								}}
							>
								Add
							</Button>
						</Grid>

						<Grid xs={12}>
							<Box
								sx={{
									border:'1px solid #d2dbe4',
									height:'86px',
									mx:0.5,
									mt:0.1,
									borderRadius:'0px',
									bgcolor:'white',
									overflow:'auto'
								}}
							>
								<Table
									aria-labelledby="tableTitle"
									borderAxis="none"
									stickyHeader
									hoverRow
									sx={{
										'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
										'--TableCell-paddingY': '1px',
										"--TableCell-height": "24px",
										'--TableCell-paddingX': '5px',
										tableLayout: 'auto', 
										width:'auto',
										borderRadius:'0px'
									}}
								>
									<tbody>
										{/** 
										{moduleList.map((row) => (
											<tr
												key={row.id}
												onClick={() => setSelectedGroupForModule(row)}
												style={{ 
													backgroundColor: selectedGroupForModule === row ? '#00cfd6' : 'white',
													color: selectedGroupForModule === row ? 'white' : 'black',
												}}
											>
												<td style={rowStyle}>{row.id}</td>
												<td style={rowStyle}>{row.groupName}</td>
												<td style={rowStyle}>{row.groupSta}</td>
												<td style={rowStyle}>{row.permission}</td>
												<td style={rowStyle}>{row.activate}</td>
												<td style={rowStyle}>{row.remarks}</td>
											</tr>
										))}*/}
									</tbody>
								</Table>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Box>
		)
	}

	const FinishThresholdEditore = () => {
		return (
			<Box sx={{display:'flex', flexDirection:'column'}}>
				<Box 
					sx={{
						position:'relative',
						border:'1px solid black',
						mt:1,
						pb:1
					}}
				>
					<Box 
						sx={{
							display:'flex', 
							position:'absolute',
							top:-12,
							left:5
						}}
					>
						<Typography
							sx={{
								bgcolor:'#d6d3ce',
								fontWeight:'600',
								color:'black',
								fontSize:14
							}}
						>
							Interval:
						</Typography>
						<Typography
							sx={{
								ml:3,
								bgcolor:'#d6d3ce',
								color:'red'
							}}
						>
							*
						</Typography>
					</Box>
					
						<Grid container spacing={0}>
							<Grid xs={4}></Grid>
							<Grid xs={1}>
								<FormControl sx={{...formControlStyle,alignItems:'center'}}>
									<FormLabel sx={labelStyle}>DY:</FormLabel>
									<Tooltip title='Days Selector'>
										<Checkbox 
											variant="outlined"
											color="neutral"
											sx={{
												fontSize:'14px',
												'--Checkbox-size':'15px',
												gap:0.4,
												'& .css-r4cpcz-JoyCheckbox-checkbox': {
													borderRadius:0,
													'--joy-palette-neutral-outlinedBorder':'black',
												},
												'& .css-n110n' : {
													borderRadius:0,
													'--joy-palette-neutral-outlinedBorder':'black',
												},
											}}
											//checked={editedData.shift}
											//onChange={(event) => handleChange('shift', event.target.checked)}
										/>
									</Tooltip>
								</FormControl>
							</Grid>
							<Grid xs={1}>
								<FormControl sx={{...formControlStyle,alignItems:'center'}}>
									<FormLabel sx={labelStyle}>MO:</FormLabel>
									<Tooltip title='Months Selector'>
										<Checkbox 
											variant="outlined"
											color="neutral"
											sx={{
												fontSize:'14px',
												'--Checkbox-size':'15px',
												gap:0.4,
												'& .css-r4cpcz-JoyCheckbox-checkbox': {
													borderRadius:0,
													'--joy-palette-neutral-outlinedBorder':'black',
												},
												'& .css-n110n' : {
													borderRadius:0,
													'--joy-palette-neutral-outlinedBorder':'black',
												},
											}}
											//checked={editedData.shift}
											//onChange={(event) => handleChange('shift', event.target.checked)}
										/>
									</Tooltip>
								</FormControl>
							</Grid>
							<Grid xs={1}>
								<FormControl sx={{...formControlStyle,alignItems:'center'}}>
									<FormLabel sx={labelStyle}>YR:</FormLabel>
									<Tooltip title='Years Selector'>
										<Checkbox 
											variant="outlined"
											color="neutral"
											sx={{
												fontSize:'14px',
												'--Checkbox-size':'15px',
												gap:0.4,
												'& .css-r4cpcz-JoyCheckbox-checkbox': {
													borderRadius:0,
													'--joy-palette-neutral-outlinedBorder':'black',
												},
												'& .css-n110n' : {
													borderRadius:0,
													'--joy-palette-neutral-outlinedBorder':'black',
												},
											}}
											//checked={editedData.shift}
											//onChange={(event) => handleChange('shift', event.target.checked)}
										/>
									</Tooltip>
								</FormControl>
							</Grid>
							<Grid xs={5}/>			
							
							<Grid xs={2}>
								<FormControl>
									<FormLabel sx={labelStyle}>FH:</FormLabel>
									<Input  
										sx={inputStyle} 
										value={editedData?.title ?? ''}
										onChange={(event) => handleChange('title', event.target.value)}
									/>
								</FormControl>
							</Grid>
							<Grid xs={2}>
								<FormControl>
									<FormLabel sx={labelStyle}>FC:</FormLabel>
									<Input  
										sx={inputStyle} 
										value={editedData?.title ?? ''}
										onChange={(event) => handleChange('title', event.target.value)}
									/>
								</FormControl>
							</Grid>
							<Grid xs={1}></Grid>
							<Grid xs={2}>
								<FormLabel sx={{...labelStyle, color:'transparent'}}>.</FormLabel>
								<FormControl>
									<Input  
										sx={inputStyle} 
										value={editedData?.title ?? ''}
										onChange={(event) => handleChange('title', event.target.value)}
									/>
								</FormControl>
							</Grid>
							<Grid xs={5}>
								<FormControl sx={{mt:2}}>
									<Tooltip title='Tasks for Replacements or Other Tasks are required to print in WP Y/N. Specify Task Card Number below and copy task in pdf to TC Location'>
										<Checkbox 
											label=':Task Card Required'
											variant="outlined"
											color="neutral"
											sx={{
												fontSize:'14px',
												'--Checkbox-size':'15px',
												fontWeight:'bold',
												gap:0.4,
												'& .css-r4cpcz-JoyCheckbox-checkbox': {
													borderRadius:0,
													'--joy-palette-neutral-outlinedBorder':'black',
												},
												'& .css-n110n' : {
													borderRadius:0,
													'--joy-palette-neutral-outlinedBorder':'black',
												},
											}}
											//checked={editedData.shift}
											//onChange={(event) => handleChange('shift', event.target.checked)}
										/>
									</Tooltip>
								</FormControl>
							</Grid>

							<Grid xs={2}>
								<FormControl sx={{...formControlStyle, ml:0.4}}>
									<Tooltip title='APU Flight Data Utilization'>
										<Checkbox 
											label=':APU Data'
											variant="outlined"
											color="neutral"
											sx={{
												fontSize:'14px',
												'--Checkbox-size':'15px',
												gap:0.4,
												'& .css-r4cpcz-JoyCheckbox-checkbox': {
													borderRadius:0,
													'--joy-palette-neutral-outlinedBorder':'black',
												},
												'& .css-n110n' : {
													borderRadius:0,
													'--joy-palette-neutral-outlinedBorder':'black',
												},
											}}
											//checked={editedData.shift}
											//onChange={(event) => handleChange('shift', event.target.checked)}
										/>
									</Tooltip>
								</FormControl>
							</Grid>
							<Grid xs={5}>
								<FormControl>
									<FormLabel sx={labelStyle}>DOC. Reference Data:</FormLabel>
									<Input  
										sx={inputStyle} 
										value={editedData?.title ?? ''}
										onChange={(event) => handleChange('title', event.target.value)}
									/>
								</FormControl>
							</Grid>
							<Grid xs={5}>
								<FormControl>
									<FormLabel sx={labelStyle}>AMM Reference:</FormLabel>
									<Input  
										sx={inputStyle} 
										value={editedData?.title ?? ''}
										onChange={(event) => handleChange('title', event.target.value)}
									/>
								</FormControl>
							</Grid>
						</Grid>
				</Box>
				<Box 
					sx={{
						position:'relative',
						border:'1px solid black',
						mt:1
					}}
				>
					<Box 
						sx={{
							display:'flex', 
							position:'absolute',
							top:-8,
							left:5
						}}
					>
						<Typography
							sx={{
								bgcolor:'#d6d3ce',
								fontWeight:'600',
								color:'black',
								fontSize:14,
								lineHeight:1
							}}
						>
							Part Effectivity:
						</Typography>
					</Box>
					<Grid container spacing={0} sx={{ flexGrow: 1, height:125, pt:0.6, px:1 }}>
						<Grid xs={12}>
							<Box
								sx={{
									border:'1px solid #d2dbe4',
									height:'110px',
									mx:0.5,
									mt:0.5,
									borderRadius:'0px',
									bgcolor:'white',
									overflow:'auto'
								}}
							>
								<Table
									aria-labelledby="tableTitle"
									borderAxis="none"
									stickyHeader
									hoverRow
									sx={{
										'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
										'--TableCell-paddingY': '1px',
										"--TableCell-height": "24px",
										'--TableCell-paddingX': '5px',
										tableLayout: 'auto', 
										width:'auto',
										borderRadius:'0px'
									}}
								>
									<tbody>
										{/** 
										{moduleList.map((row) => (
											<tr
												key={row.id}
												onClick={() => setSelectedGroupForModule(row)}
												style={{ 
													backgroundColor: selectedGroupForModule === row ? '#00cfd6' : 'white',
													color: selectedGroupForModule === row ? 'white' : 'black',
												}}
											>
												<td style={rowStyle}>{row.id}</td>
												<td style={rowStyle}>{row.groupName}</td>
												<td style={rowStyle}>{row.groupSta}</td>
												<td style={rowStyle}>{row.permission}</td>
												<td style={rowStyle}>{row.activate}</td>
												<td style={rowStyle}>{row.remarks}</td>
											</tr>
										))}*/}
									</tbody>
								</Table>
							</Box>
						</Grid>
					</Grid>
				</Box>
				<Box 
					sx={{
						position:'relative',
						border:'1px solid black',
						mt:1
					}}
				>
					<Box 
						sx={{
							display:'flex', 
							position:'absolute',
							top:-8,
							left:5
						}}
					>
						<Typography
							sx={{
								bgcolor:'#d6d3ce',
								fontWeight:'600',
								color:'black',
								fontSize:14,
								lineHeight:1
							}}
						>
							Associated Treatments:
						</Typography>
					</Box>
					<Grid container spacing={0} sx={{ flexGrow: 1, height:125, pt:0.6, px:1 }}>
						<Grid xs={12}>
							<Box
								sx={{
									border:'1px solid #d2dbe4',
									height:'110px',
									mx:0.5,
									mt:0.5,
									borderRadius:'0px',
									bgcolor:'white',
									overflow:'auto'
								}}
							>
								<Table
									aria-labelledby="tableTitle"
									borderAxis="none"
									stickyHeader
									hoverRow
									sx={{
										'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
										'--TableCell-paddingY': '1px',
										"--TableCell-height": "24px",
										'--TableCell-paddingX': '5px',
										tableLayout: 'auto', 
										width:'auto',
										borderRadius:'0px'
									}}
								>
									<tbody>
										{/** 
										{moduleList.map((row) => (
											<tr
												key={row.id}
												onClick={() => setSelectedGroupForModule(row)}
												style={{ 
													backgroundColor: selectedGroupForModule === row ? '#00cfd6' : 'white',
													color: selectedGroupForModule === row ? 'white' : 'black',
												}}
											>
												<td style={rowStyle}>{row.id}</td>
												<td style={rowStyle}>{row.groupName}</td>
												<td style={rowStyle}>{row.groupSta}</td>
												<td style={rowStyle}>{row.permission}</td>
												<td style={rowStyle}>{row.activate}</td>
												<td style={rowStyle}>{row.remarks}</td>
											</tr>
										))}*/}
									</tbody>
								</Table>
							</Box>
						</Grid>
					</Grid>
				</Box>
				<Box 
					sx={{
						position:'relative',
						border:'1px solid black',
						mt:1
					}}
				>
					<Box 
						sx={{
							display:'flex', 
							position:'absolute',
							top:-8,
							left:5
						}}
					>
						<Typography
							sx={{
								bgcolor:'#d6d3ce',
								fontWeight:'600',
								color:'black',
								fontSize:14,
								lineHeight:1
							}}
						>
							Activated Task Cards or EC:
						</Typography>
					</Box>
					<Grid container spacing={0} sx={{ flexGrow: 1, height:125, pt:0.6, px:1 }}>
						<Grid xs={4} sx={{display:'flex', alignItems:'center'}}>
							<RadioGroup orientation="horizontal">
								<Radio 
									value="Task" 
									label="Task" 
									variant="outlined" 
									color='neutral'
									sx={{
										'--Radio-size':'14px',
										gap:0.5,
										fontSize:14
									}}
								/>
								<Radio 
									value="EC" 
									label="EC" 
									variant="outlined" 
									color='neutral'
									sx={{
										'--Radio-size':'14px',
										gap:0.5,
										fontSize:14
									}}
								/>
							</RadioGroup>
						</Grid>
						<Grid xs={2} sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
							<Typography>
								Filter:
							</Typography>
						</Grid>
						<Grid xs={3.5} sx={{display:'flex', alignItems:'center'}}>
							<Input  
								sx={inputStyle} 
								value={editedData?.title ?? ''}
								onChange={(event) => handleChange('title', event.target.value)}
							/>
						</Grid>
						<Grid xs={2} sx={{display:'flex', alignItems:'center'}}>
							<Button
								//onClick={handleConfirm}
								color='neutral'
								sx={{
									borderRadius:0,
									height: 25, 
									minHeight: 0,
									bgcolor:'#d6d3ce',
									color:'black',
									border:'2px outset grey',
								}}
							>
								Add
							</Button>
						</Grid>

						<Grid xs={12}>
							<Box
								sx={{
									border:'1px solid #d2dbe4',
									height:'86px',
									mx:0.5,
									mt:0.1,
									borderRadius:'0px',
									bgcolor:'white',
									overflow:'auto'
								}}
							>
								<Table
									aria-labelledby="tableTitle"
									borderAxis="none"
									stickyHeader
									hoverRow
									sx={{
										'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
										'--TableCell-paddingY': '1px',
										"--TableCell-height": "24px",
										'--TableCell-paddingX': '5px',
										tableLayout: 'auto', 
										width:'auto',
										borderRadius:'0px'
									}}
								>
									<tbody>
										{/** 
										{moduleList.map((row) => (
											<tr
												key={row.id}
												onClick={() => setSelectedGroupForModule(row)}
												style={{ 
													backgroundColor: selectedGroupForModule === row ? '#00cfd6' : 'white',
													color: selectedGroupForModule === row ? 'white' : 'black',
												}}
											>
												<td style={rowStyle}>{row.id}</td>
												<td style={rowStyle}>{row.groupName}</td>
												<td style={rowStyle}>{row.groupSta}</td>
												<td style={rowStyle}>{row.permission}</td>
												<td style={rowStyle}>{row.activate}</td>
												<td style={rowStyle}>{row.remarks}</td>
											</tr>
										))}*/}
									</tbody>
								</Table>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Box>
		)
	}

	const ToleranceEditore = () => {
		return (
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-12,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14
						}}
					>
						Tolerance:
					</Typography>
					<Typography
						sx={{
							ml:3,
							bgcolor:'#d6d3ce',
							color:'red',
						}}
					>
						*
					</Typography>
				</Box>
				<Grid container spacing={0} sx={{ flexGrow: 1, height:140 }}>
					<Grid container spacing={0} xs={6}>
						<Grid xs={12} sx={{display:'flex',justifyContent:'center'}}>
							<Typography 
								sx={{
									fontWeight:'bold',
									mt:1.5
								}}
							>
								Early Rescheduled Method:
							</Typography>
						</Grid>

						<Grid xs={1}></Grid>
						<Grid xs={4}>
							<Typography>
								Completion:
							</Typography>
						</Grid>
						<Grid xs={7}>
								<Tooltip title='Completion + Interval Method'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
						</Grid>

						<Grid xs={1}></Grid>
						<Grid xs={4}>
							<Typography>
								When Due:
							</Typography>
						</Grid>
						<Grid xs={7}>
								<Tooltip title='When Due + Interval Method'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
						</Grid>

					</Grid>
					<Grid container spacing={0} xs={6}>
						<Grid xs={12} sx={{display:'flex',justifyContent:'center'}}>
							<Typography 
								sx={{
									fontWeight:'bold',
									mt:1.5
								}}
							>
								Late Rescheduled Method:
							</Typography>
						</Grid>

						<Grid xs={1}></Grid>
						<Grid xs={4}>
							<Typography>
								Completion:
							</Typography>
						</Grid>
						<Grid xs={7}>
								<Tooltip title='Completion + Interval Method'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
						</Grid>

						<Grid xs={1}></Grid>
						<Grid xs={4}>
							<Typography>
								When Due:
							</Typography>
						</Grid>
						<Grid xs={7}>
								<Tooltip title='When Due + Interval Method'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
						</Grid>

					</Grid>
				</Grid>
			</Box>
		)
	}

	const InstructionsEditore = () => {
		return (
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-12,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14
						}}
					>
						INSTRUCTIONS:
					</Typography>
				</Box>
				<Grid container spacing={0} sx={{ flexGrow: 1, height:100, pt:1 }}>
					<Grid xs={1}></Grid>
					<Grid xs={2}>
						<Tooltip title='ETOPS Y or N'>
							<Checkbox 
								label=':ETOPS'
								variant="outlined"
								color="neutral"
								sx={{
									fontSize:'14px',
									'--Checkbox-size':'15px',
									gap:0.4,
									'& .css-r4cpcz-JoyCheckbox-checkbox': {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
									'& .css-n110n' : {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
								}}
								//checked={editedData.shift}
								//onChange={(event) => handleChange('shift', event.target.checked)}
							/>
						</Tooltip>
					</Grid>
					<Grid xs={2}>
						<Tooltip title='Double Inspection Y or N'>
							<Checkbox 
								label=':DI (RII)'
								variant="outlined"
								color="neutral"
								sx={{
									fontSize:'14px',
									'--Checkbox-size':'15px',
									gap:0.4,
									'& .css-r4cpcz-JoyCheckbox-checkbox': {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
									'& .css-n110n' : {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
								}}
								//checked={editedData.shift}
								//onChange={(event) => handleChange('shift', event.target.checked)}
							/>
						</Tooltip>
					</Grid>
					<Grid xs={2}>
						<Tooltip title='Airworthiness Limitation Instruction Y or N'>
							<Checkbox 
								label=':ALI'
								variant="outlined"
								color="neutral"
								sx={{
									fontSize:'14px',
									'--Checkbox-size':'15px',
									gap:0.4,
									'& .css-r4cpcz-JoyCheckbox-checkbox': {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
									'& .css-n110n' : {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
								}}
								//checked={editedData.shift}
								//onChange={(event) => handleChange('shift', event.target.checked)}
							/>
						</Tooltip>
					</Grid>
					<Grid xs={5}>
						<Tooltip title='CPCP'>
							<Checkbox 
								label=':CPCP'
								variant="outlined"
								color="neutral"
								sx={{
									fontSize:'14px',
									'--Checkbox-size':'15px',
									gap:0.4,
									'& .css-r4cpcz-JoyCheckbox-checkbox': {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
									'& .css-n110n' : {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
								}}
								//checked={editedData.shift}
								//onChange={(event) => handleChange('shift', event.target.checked)}
							/>
						</Tooltip>
					</Grid>
					
					<Grid xs={1}></Grid>
					<Grid xs={2}>
						<Tooltip title='ETOPS II Y or N'>
							<Checkbox 
								label=':ETOPS II'
								variant="outlined"
								color="neutral"
								sx={{
									fontSize:'14px',
									'--Checkbox-size':'15px',
									gap:0.4,
									'& .css-r4cpcz-JoyCheckbox-checkbox': {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
									'& .css-n110n' : {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
								}}
								//checked={editedData.shift}
								//onChange={(event) => handleChange('shift', event.target.checked)}
							/>
						</Tooltip>
					</Grid>
					<Grid xs={2}>
						<Tooltip title='Critical Task Y or N'>
							<Checkbox 
								label=':CR'
								variant="outlined"
								color="neutral"
								sx={{
									fontSize:'14px',
									'--Checkbox-size':'15px',
									gap:0.4,
									'& .css-r4cpcz-JoyCheckbox-checkbox': {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
									'& .css-n110n' : {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
								}}
								//checked={editedData.shift}
								//onChange={(event) => handleChange('shift', event.target.checked)}
							/>
						</Tooltip>
					</Grid>
					<Grid xs={2}>
						<Tooltip title='EWIS Y or N'>
							<Checkbox 
								label=':EWIS'
								variant="outlined"
								color="neutral"
								sx={{
									fontSize:'14px',
									'--Checkbox-size':'15px',
									gap:0.4,
									'& .css-r4cpcz-JoyCheckbox-checkbox': {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
									'& .css-n110n' : {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
								}}
								//checked={editedData.shift}
								//onChange={(event) => handleChange('shift', event.target.checked)}
							/>
						</Tooltip>
					</Grid>
					<Grid xs={5}>
						<Tooltip title='AD Instruction Y or N'>
							<Checkbox 
								label=':AD'
								variant="outlined"
								color="neutral"
								sx={{
									fontSize:'14px',
									'--Checkbox-size':'15px',
									gap:0.4,
									'& .css-r4cpcz-JoyCheckbox-checkbox': {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
									'& .css-n110n' : {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
								}}
								//checked={editedData.shift}
								//onChange={(event) => handleChange('shift', event.target.checked)}
							/>
						</Tooltip>
					</Grid>

					<Grid xs={1}></Grid>
					<Grid xs={2}>
						<Tooltip title='Category III approach capability affected Y or N'>
							<Checkbox 
								label=':CAT 3'
								variant="outlined"
								color="neutral"
								sx={{
									fontSize:'14px',
									'--Checkbox-size':'15px',
									gap:0.4,
									'& .css-r4cpcz-JoyCheckbox-checkbox': {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
									'& .css-n110n' : {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
								}}
								//checked={editedData.shift}
								//onChange={(event) => handleChange('shift', event.target.checked)}
							/>
						</Tooltip>
					</Grid>
					<Grid xs={2}>
						<Tooltip title='Critical Design Configuration Control Limitation Y or N'>
							<Checkbox 
								label=':CDCCL'
								variant="outlined"
								color="neutral"
								sx={{
									fontSize:'14px',
									'--Checkbox-size':'15px',
									gap:0.4,
									'& .css-r4cpcz-JoyCheckbox-checkbox': {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
									'& .css-n110n' : {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
								}}
								//checked={editedData.shift}
								//onChange={(event) => handleChange('shift', event.target.checked)}
							/>
						</Tooltip>
					</Grid>
					<Grid xs={2}>
						<Tooltip title='Certificated Maintenance Requirements Y or N'>
							<Checkbox 
								label=':CMR'
								variant="outlined"
								color="neutral"
								sx={{
									fontSize:'14px',
									'--Checkbox-size':'15px',
									gap:0.4,
									'& .css-r4cpcz-JoyCheckbox-checkbox': {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
									'& .css-n110n' : {
										borderRadius:0,
										'--joy-palette-neutral-outlinedBorder':'black',
									},
								}}
								//checked={editedData.shift}
								//onChange={(event) => handleChange('shift', event.target.checked)}
							/>
						</Tooltip>
					</Grid>
					<Grid xs={5}/>
				</Grid>
			</Box>
		)
	}

	const PostThresholdEditore = () => {
		return (
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-12,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14
						}}
					>
						POST THRESHOLD INTERVALS FOR STRUCTURES AND ZONAL TASKS:
					</Typography>
				</Box>
				<Grid container spacing={0} sx={{ flexGrow: 1, height:140, pt:1 }}>
					<Grid container spacing={0} xs={2}>
						<Grid xs={12}/>

						<Grid xs={12}>
							<FormControl>
								<FormLabel sx={labelStyle}>Switching FH:</FormLabel>
								<Input  
									sx={inputStyle} 
									value={editedData?.title ?? ''}
									onChange={(event) => handleChange('title', event.target.value)}
								/>
							</FormControl>
						</Grid>

						<Grid xs={12}>
							<FormControl>
								<FormLabel sx={labelStyle}>Switching FC:</FormLabel>
								<Input  
									sx={inputStyle} 
									value={editedData?.title ?? ''}
									onChange={(event) => handleChange('title', event.target.value)}
								/>
							</FormControl>
						</Grid>
					</Grid>
					<Grid container spacing={0} xs={3}>
						<Grid xs={12} sx={{display:'flex', justifyContent:'center'}}>
							Switching Calendar:
						</Grid>

						<Grid xs={4}>
							<FormControl sx={{...formControlStyle,alignItems:'center'}}>
								<FormLabel sx={labelStyle}>DY:</FormLabel>
								<Tooltip title='Days Selector'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						<Grid xs={4}>
							<FormControl sx={{...formControlStyle,alignItems:'center'}}>
								<FormLabel sx={labelStyle}>MO:</FormLabel>
								<Tooltip title='Months Selector'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						<Grid xs={4}>
							<FormControl sx={{...formControlStyle,alignItems:'center'}}>
								<FormLabel sx={labelStyle}>YR:</FormLabel>
								<Tooltip title='Years Selector'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						
						<Grid xs={12}>
							<FormControl>
								<Input  
									sx={inputStyle} 
									value={editedData?.title ?? ''}
									onChange={(event) => handleChange('title', event.target.value)}
								/>
							</FormControl>
						</Grid>

					</Grid>
					<Grid container spacing={0} xs={5}>
						<Grid xs={12} sx={{display:'flex', justifyContent:'center'}}> 
							POST Switching Interval:
						</Grid>

						<Grid xs={5}>
							<FormControl>
								<FormLabel sx={labelStyle}>FH:</FormLabel>
								<Input  
									sx={inputStyle} 
									value={editedData?.title ?? ''}
									onChange={(event) => handleChange('title', event.target.value)}
								/>
							</FormControl>
						</Grid>
						<Grid xs={1}/>
						<Grid xs={2}>
							<FormControl sx={{...formControlStyle,alignItems:'center'}}>
								<FormLabel sx={labelStyle}>DY:</FormLabel>
								<Tooltip title='Days Selector'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						<Grid xs={2}>
							<FormControl sx={{...formControlStyle,alignItems:'center'}}>
								<FormLabel sx={labelStyle}>MO:</FormLabel>
								<Tooltip title='Months Selector'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						<Grid xs={2}>
							<FormControl sx={{...formControlStyle,alignItems:'center'}}>
								<FormLabel sx={labelStyle}>YR:</FormLabel>
								<Tooltip title='Years Selector'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						
						<Grid xs={5}>
							<FormControl>
								<FormLabel sx={labelStyle}>FC:</FormLabel>
								<Input  
									sx={inputStyle} 
									value={editedData?.title ?? ''}
									onChange={(event) => handleChange('title', event.target.value)}
								/>
							</FormControl>
						</Grid>
						<Grid xs={1}></Grid>
						<Grid xs={6}>
							<FormControl>
								<Input  
									sx={inputStyle} 
									value={editedData?.title ?? ''}
									onChange={(event) => handleChange('title', event.target.value)}
								/>
							</FormControl>
						</Grid>
					</Grid>
					<Grid container spacing={0} xs={2} sx={{display:'flex', alignItems:'center'}}>
						<Grid xs={12}/>
						<Grid xs={12} sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
							<Button
								//onClick={handleConfirm}
								sx={{
									borderRadius:0,
									height: 25, 
									minHeight: 0,
									bgcolor:'#d6d3ce',
									color:'black',
									border:'2px outset grey',
									width:'70%'
								}}
							>
								Save
							</Button>
						</Grid>
						<Grid xs={12} sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
							<Button
								//onClick={handleConfirm}
								sx={{
									borderRadius:0,
									height: 25, 
									minHeight: 0,
									bgcolor:'#d6d3ce',
									color:'black',
									border:'2px outset grey',
									width:'70%'
								}}
							>
								Delete
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		)
	}

	const LUMPEditore = () => {
		return(
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-12,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14
						}}
					>
						Interval - Low Utilization MP:
					</Typography>
				</Box>
				<Grid container spacing={0} sx={{ flexGrow: 1, height:140 }}>
					<Grid container spacing={0} xs={7}>
						<Grid xs={9}></Grid>
						<Grid xs={1}>
							<FormControl sx={{...formControlStyle,alignItems:'center'}}>
								<FormLabel sx={labelStyle}>DY:</FormLabel>
								<Tooltip title='Days Selector'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						<Grid xs={1}>
							<FormControl sx={{...formControlStyle,alignItems:'center'}}>
								<FormLabel sx={labelStyle}>MO:</FormLabel>
								<Tooltip title='Months Selector'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						<Grid xs={1}>
							<FormControl sx={{...formControlStyle,alignItems:'center'}}>
								<FormLabel sx={labelStyle}>YR:</FormLabel>
								<Tooltip title='Years Selector'>
									<Checkbox 
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						
						<Grid xs={4}>
							<FormControl>
								<FormLabel sx={labelStyle}>FH:</FormLabel>
								<Input  
									sx={inputStyle} 
									value={editedData?.title ?? ''}
									onChange={(event) => handleChange('title', event.target.value)}
								/>
							</FormControl>
						</Grid>
						<Grid xs={4}>
							<FormControl>
								<FormLabel sx={labelStyle}>FC:</FormLabel>
								<Input  
									sx={inputStyle} 
									value={editedData?.title ?? ''}
									onChange={(event) => handleChange('title', event.target.value)}
								/>
							</FormControl>
						</Grid>
						<Grid xs={1}></Grid>
						<Grid xs={3}>
							<FormLabel sx={{...labelStyle, color:'transparent'}}>.</FormLabel>
							<FormControl>
								<Input  
									sx={inputStyle} 
									value={editedData?.title ?? ''}
									onChange={(event) => handleChange('title', event.target.value)}
								/>
							</FormControl>
						</Grid>

						<Grid xs={4}></Grid>
						<Grid xs={8}>
							<FormControl>
								<FormLabel sx={labelStyle}>DOC. Reference Data:</FormLabel>
								<Input  
									sx={inputStyle} 
									value={editedData?.title ?? ''}
									onChange={(event) => handleChange('title', event.target.value)}
								/>
							</FormControl>
						</Grid>

					</Grid>
					<Grid container spacing={0} xs={5}>
						<Grid xs={12} sx={{ml:1}}>
							<FormControl sx={{mt:2}}>
								<Tooltip title='Whichever Comes Last (Intervals Value)'>
									<Checkbox 
										label=':Whichever Comes Last'
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						<Grid xs={12} sx={{ml:1}}>
							<FormControl>
								<Tooltip title='Completed By Component Replacement'>
									<Checkbox 
										label=':Completed By Component RepIm.'
										variant="outlined"
										color="neutral"
										sx={{
											fontSize:'14px',
											'--Checkbox-size':'15px',
											gap:0.4,
											'& .css-r4cpcz-JoyCheckbox-checkbox': {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
											'& .css-n110n' : {
												borderRadius:0,
												'--joy-palette-neutral-outlinedBorder':'black',
											},
										}}
										//checked={editedData.shift}
										//onChange={(event) => handleChange('shift', event.target.checked)}
									/>
								</Tooltip>
							</FormControl>
						</Grid>
						<Grid xs={12} sx={{ml:1}}>
							<FormControl sx={formControlStyle}>
								<FormLabel sx={labelStyle}>Reference:</FormLabel>
								<Textarea 
									maxRows={1}
									defaultValue={props.selectedRow.description}
									sx={{...inputStyle,color:'#000078', height:60}}
									value={editedData?.remarks || ''}
									onChange={(event) => handleChange('remarks', event.target.value)}
								/>
							</FormControl>
						</Grid>
					</Grid>
					<Button
						//onClick={handleConfirm}
						color='neutral'
						sx={{
							borderRadius:0,
							height: 25, 
							minHeight: 0,
							bgcolor:'#d6d3ce',
							color:'black',
							border:'2px outset grey',
							position:'absolute',
							right:0,
							m:0.2
						}}
					>
						Save
					</Button>
				</Grid>
			</Box>
		)
	}

	const ReplmMaterials = () => {
		return(
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1,
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-12,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14
						}}
					>
						Materials:
					</Typography>
				</Box>
				<Grid container spacing={0} sx={{ flexGrow: 1, height:380 }}>
					<Grid xs={12}>
						<Box
							sx={{
								border:'1px solid #d2dbe4',
								height:'350px',
								mx:1,
								mt:2.5,
								borderRadius:'0px',
								bgcolor:'white',
								overflow:'auto'
							}}
						>
							<Table
								aria-labelledby="tableTitle"
								borderAxis="none"
								stickyHeader
								hoverRow
								sx={{
									'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
									'--TableCell-paddingY': '1px',
									"--TableCell-height": "24px",
									'--TableCell-paddingX': '5px',
									tableLayout: 'auto', 
									width:'auto',
									borderRadius:'0px'
								}}
							>
								<tbody>
									{/** 
									{moduleList.map((row) => (
										<tr
											key={row.id}
											onClick={() => setSelectedGroupForModule(row)}
											style={{ 
												backgroundColor: selectedGroupForModule === row ? '#00cfd6' : 'white',
												color: selectedGroupForModule === row ? 'white' : 'black',
											}}
										>
											<td style={rowStyle}>{row.id}</td>
											<td style={rowStyle}>{row.groupName}</td>
											<td style={rowStyle}>{row.groupSta}</td>
											<td style={rowStyle}>{row.permission}</td>
											<td style={rowStyle}>{row.activate}</td>
											<td style={rowStyle}>{row.remarks}</td>
										</tr>
									))}*/}
								</tbody>
							</Table>
						</Box>
					</Grid>
					<Button
						//onClick={handleConfirm}
						color='neutral'
						sx={{
							borderRadius:0,
							height: 25, 
							minHeight: 0,
							bgcolor:'#d6d3ce',
							color:'black',
							border:'2px outset grey',
							position:'absolute',
							right:9,
							top:-7
						}}
					>
						Edit
					</Button>
				</Grid>
			</Box>
		)
	}

	const ReplmTools = () => {
		return(
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1,
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-12,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14
						}}
					>
						Tools:
					</Typography>
				</Box>
				<Grid container spacing={0} sx={{ flexGrow: 1, height:380 }}>
					<Grid xs={12}>
						<Box
							sx={{
								border:'1px solid #d2dbe4',
								height:'350px',
								mx:1,
								mt:2.5,
								borderRadius:'0px',
								bgcolor:'white',
								overflow:'auto'
							}}
						>
							<Table
								aria-labelledby="tableTitle"
								borderAxis="none"
								stickyHeader
								hoverRow
								sx={{
									'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
									'--TableCell-paddingY': '1px',
									"--TableCell-height": "24px",
									'--TableCell-paddingX': '5px',
									tableLayout: 'auto', 
									width:'auto',
									borderRadius:'0px'
								}}
							>
								<tbody>
									{/** 
									{moduleList.map((row) => (
										<tr
											key={row.id}
											onClick={() => setSelectedGroupForModule(row)}
											style={{ 
												backgroundColor: selectedGroupForModule === row ? '#00cfd6' : 'white',
												color: selectedGroupForModule === row ? 'white' : 'black',
											}}
										>
											<td style={rowStyle}>{row.id}</td>
											<td style={rowStyle}>{row.groupName}</td>
											<td style={rowStyle}>{row.groupSta}</td>
											<td style={rowStyle}>{row.permission}</td>
											<td style={rowStyle}>{row.activate}</td>
											<td style={rowStyle}>{row.remarks}</td>
										</tr>
									))}*/}
								</tbody>
							</Table>
						</Box>
					</Grid>
					<Button
						//onClick={handleConfirm}
						color='neutral'
						sx={{
							borderRadius:0,
							height: 25, 
							minHeight: 0,
							bgcolor:'#d6d3ce',
							color:'black',
							border:'2px outset grey',
							position:'absolute',
							right:9,
							top:-7
						}}
					>
						Edit
					</Button>
				</Grid>
			</Box>
		)
	}

	const ReplmJIC = () => {
		return(
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-8,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14,
							lineHeight:1
						}}
					>
						JIC Procedure Editor:
					</Typography>
				</Box>
				<Grid container spacing={0} sx={{ flexGrow: 1, height:570, pt:0.6, px:1 }}>
					<Grid xs={4} sx={{display:'flex', alignItems:'center'}}>
						<FormControl sx={formControlStyle}>
							<FormLabel sx={labelStyle}>Treatment:</FormLabel>
							<Autocomplete
								disableClearable
								options={props.acFamilies.map(data=> ({ label: data.acFamily }))}
								value={{ label: editedData.acFamily ?? ''}}
								sx={inputStyle}
								onChange={(event, value) => handleChange('acFamily', value ? value.label : '')}
							/>
						</FormControl>
					</Grid>
					<Grid xs={6}/>
					<Grid xs={2} sx={{display:'flex', alignItems:'center'}}>
						<Button
							//onClick={handleConfirm}
							color='neutral'
							sx={{
								borderRadius:0,
								height: 25, 
								minHeight: 0,
								bgcolor:'#d6d3ce',
								color:'black',
								border:'2px outset grey',
							}}
						>
							Save
						</Button>
					</Grid>

					<Grid xs={12}>
						<Box
							sx={{
								border:'1px solid #d2dbe4',
								height:'500px',
								mx:0.5,
								mt:0.1,
								borderRadius:'0px',
								bgcolor:'white',
								overflow:'auto'
							}}
						>
							<Table
								aria-labelledby="tableTitle"
								borderAxis="none"
								stickyHeader
								hoverRow
								sx={{
									'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
									'--TableCell-paddingY': '1px',
									"--TableCell-height": "24px",
									'--TableCell-paddingX': '5px',
									tableLayout: 'auto', 
									width:'auto',
									borderRadius:'0px'
								}}
							>
								<tbody>
									{/** 
									{moduleList.map((row) => (
										<tr
											key={row.id}
											onClick={() => setSelectedGroupForModule(row)}
											style={{ 
												backgroundColor: selectedGroupForModule === row ? '#00cfd6' : 'white',
												color: selectedGroupForModule === row ? 'white' : 'black',
											}}
										>
											<td style={rowStyle}>{row.id}</td>
											<td style={rowStyle}>{row.groupName}</td>
											<td style={rowStyle}>{row.groupSta}</td>
											<td style={rowStyle}>{row.permission}</td>
											<td style={rowStyle}>{row.activate}</td>
											<td style={rowStyle}>{row.remarks}</td>
										</tr>
									))}*/}
								</tbody>
							</Table>
						</Box>
					</Grid>
				</Grid>
			</Box>
		)
	}

	const SpecialInspEditore = () => {
		return(
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-12,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14
						}}
					>
						Special Inspection:
					</Typography>
				</Box>
				<Grid container spacing={0} sx={{ flexGrow: 1, height:125 }}>
					<Grid xs={12}>
						<Box
							sx={{
								border:'1px solid #d2dbe4',
								height:'100px',
								mx:1,
								mt:2.5,
								borderRadius:'0px',
								bgcolor:'white',
								overflow:'auto'
							}}
						>
							<Table
								aria-labelledby="tableTitle"
								borderAxis="none"
								stickyHeader
								hoverRow
								sx={{
									'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
									'--TableCell-paddingY': '1px',
									"--TableCell-height": "24px",
									'--TableCell-paddingX': '5px',
									tableLayout: 'auto', 
									width:'auto',
									borderRadius:'0px'
								}}
							>
								<tbody>
									{/** 
									{moduleList.map((row) => (
										<tr
											key={row.id}
											onClick={() => setSelectedGroupForModule(row)}
											style={{ 
												backgroundColor: selectedGroupForModule === row ? '#00cfd6' : 'white',
												color: selectedGroupForModule === row ? 'white' : 'black',
											}}
										>
											<td style={rowStyle}>{row.id}</td>
											<td style={rowStyle}>{row.groupName}</td>
											<td style={rowStyle}>{row.groupSta}</td>
											<td style={rowStyle}>{row.permission}</td>
											<td style={rowStyle}>{row.activate}</td>
											<td style={rowStyle}>{row.remarks}</td>
										</tr>
									))}*/}
								</tbody>
							</Table>
						</Box>
					</Grid>
					<Button
						//onClick={handleConfirm}
						color='neutral'
						sx={{
							borderRadius:0,
							height: 25, 
							minHeight: 0,
							bgcolor:'#d6d3ce',
							color:'black',
							border:'2px outset grey',
							position:'absolute',
							right:9,
							top:-7
						}}
					>
						Edit
					</Button>
				</Grid>
			</Box>
		)
	}

	const PanelsEditore = () => {
		return(
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-12,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14
						}}
					>
						Panels:
					</Typography>
				</Box>
				<Grid container spacing={0} sx={{ flexGrow: 1, height:125 }}>
					<Grid xs={12}>
						<Box
							sx={{
								border:'1px solid #d2dbe4',
								height:'100px',
								mx:1,
								mt:2.5,
								borderRadius:'0px',
								bgcolor:'white',
								overflow:'auto'
							}}
						>
							<Table
								aria-labelledby="tableTitle"
								borderAxis="none"
								stickyHeader
								hoverRow
								sx={{
									'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
									'--TableCell-paddingY': '1px',
									"--TableCell-height": "24px",
									'--TableCell-paddingX': '5px',
									tableLayout: 'auto', 
									width:'auto',
									borderRadius:'0px'
								}}
							>
								<tbody>
									{/** 
									{moduleList.map((row) => (
										<tr
											key={row.id}
											onClick={() => setSelectedGroupForModule(row)}
											style={{ 
												backgroundColor: selectedGroupForModule === row ? '#00cfd6' : 'white',
												color: selectedGroupForModule === row ? 'white' : 'black',
											}}
										>
											<td style={rowStyle}>{row.id}</td>
											<td style={rowStyle}>{row.groupName}</td>
											<td style={rowStyle}>{row.groupSta}</td>
											<td style={rowStyle}>{row.permission}</td>
											<td style={rowStyle}>{row.activate}</td>
											<td style={rowStyle}>{row.remarks}</td>
										</tr>
									))}*/}
								</tbody>
							</Table>
						</Box>
					</Grid>
					<Button
						//onClick={handleConfirm}
						color='neutral'
						sx={{
							borderRadius:0,
							height: 25, 
							minHeight: 0,
							bgcolor:'#d6d3ce',
							color:'black',
							border:'2px outset grey',
							position:'absolute',
							right:9,
							top:-7
						}}
					>
						Edit
					</Button>
				</Grid>
			</Box>
		)
	}

	const MaterialsEditore = () => {
		return(
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-12,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14
						}}
					>
						Materials:
					</Typography>
				</Box>
				<Grid container spacing={0} sx={{ flexGrow: 1, height:125 }}>
					<Grid xs={12}>
						<Box
							sx={{
								border:'1px solid #d2dbe4',
								height:'100px',
								mx:1,
								mt:2.5,
								borderRadius:'0px',
								bgcolor:'white',
								overflow:'auto'
							}}
						>
							<Table
								aria-labelledby="tableTitle"
								borderAxis="none"
								stickyHeader
								hoverRow
								sx={{
									'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
									'--TableCell-paddingY': '1px',
									"--TableCell-height": "24px",
									'--TableCell-paddingX': '5px',
									tableLayout: 'auto', 
									width:'auto',
									borderRadius:'0px'
								}}
							>
								<tbody>
									{/** 
									{moduleList.map((row) => (
										<tr
											key={row.id}
											onClick={() => setSelectedGroupForModule(row)}
											style={{ 
												backgroundColor: selectedGroupForModule === row ? '#00cfd6' : 'white',
												color: selectedGroupForModule === row ? 'white' : 'black',
											}}
										>
											<td style={rowStyle}>{row.id}</td>
											<td style={rowStyle}>{row.groupName}</td>
											<td style={rowStyle}>{row.groupSta}</td>
											<td style={rowStyle}>{row.permission}</td>
											<td style={rowStyle}>{row.activate}</td>
											<td style={rowStyle}>{row.remarks}</td>
										</tr>
									))}*/}
								</tbody>
							</Table>
						</Box>
					</Grid>
					<Button
						//onClick={handleConfirm}
						color='neutral'
						sx={{
							borderRadius:0,
							height: 25, 
							minHeight: 0,
							bgcolor:'#d6d3ce',
							color:'black',
							border:'2px outset grey',
							position:'absolute',
							right:75,
							top:-7
						}}
					>
						Update
					</Button>
					<Button
						//onClick={handleConfirm}
						color='neutral'
						sx={{
							borderRadius:0,
							height: 25, 
							minHeight: 0,
							bgcolor:'#d6d3ce',
							color:'black',
							border:'2px outset grey',
							position:'absolute',
							right:9,
							top:-7
						}}
					>
						Edit
					</Button>
				</Grid>
			</Box>
		)
	}

	const ToolsEditore = () => {
		return(
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-12,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14
						}}
					>
						Tools:
					</Typography>
				</Box>
				<Grid container spacing={0} sx={{ flexGrow: 1, height:125 }}>
					<Grid xs={12}>
						<Box
							sx={{
								border:'1px solid #d2dbe4',
								height:'100px',
								mx:1,
								mt:2.5,
								borderRadius:'0px',
								bgcolor:'white',
								overflow:'auto'
							}}
						>
							<Table
								aria-labelledby="tableTitle"
								borderAxis="none"
								stickyHeader
								hoverRow
								sx={{
									'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
									'--TableCell-paddingY': '1px',
									"--TableCell-height": "24px",
									'--TableCell-paddingX': '5px',
									tableLayout: 'auto', 
									width:'auto',
									borderRadius:'0px'
								}}
							>
								<tbody>
									{/** 
									{moduleList.map((row) => (
										<tr
											key={row.id}
											onClick={() => setSelectedGroupForModule(row)}
											style={{ 
												backgroundColor: selectedGroupForModule === row ? '#00cfd6' : 'white',
												color: selectedGroupForModule === row ? 'white' : 'black',
											}}
										>
											<td style={rowStyle}>{row.id}</td>
											<td style={rowStyle}>{row.groupName}</td>
											<td style={rowStyle}>{row.groupSta}</td>
											<td style={rowStyle}>{row.permission}</td>
											<td style={rowStyle}>{row.activate}</td>
											<td style={rowStyle}>{row.remarks}</td>
										</tr>
									))}*/}
								</tbody>
							</Table>
						</Box>
					</Grid>
					<Button
						//onClick={handleConfirm}
						color='neutral'
						sx={{
							borderRadius:0,
							height: 25, 
							minHeight: 0,
							bgcolor:'#d6d3ce',
							color:'black',
							border:'2px outset grey',
							position:'absolute',
							right:9,
							top:-7
						}}
					>
						Edit
					</Button>
				</Grid>
			</Box>
		)
	}

	const JICEditore = () => {
		return(
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-12,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14
						}}
					>
						JIC Procedure:
					</Typography>
				</Box>
				<Grid container spacing={0} sx={{ flexGrow: 1, height:125 }}>
					<Grid xs={12}>
						<Box
							sx={{
								border:'1px solid #d2dbe4',
								height:'100px',
								mx:1,
								mt:2.5,
								borderRadius:'0px',
								bgcolor:'white',
								overflow:'auto'
							}}
						>
							<Table
								aria-labelledby="tableTitle"
								borderAxis="none"
								stickyHeader
								hoverRow
								sx={{
									'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
									'--TableCell-paddingY': '1px',
									"--TableCell-height": "24px",
									'--TableCell-paddingX': '5px',
									tableLayout: 'auto', 
									width:'auto',
									borderRadius:'0px'
								}}
							>
								<tbody>
									{/** 
									{moduleList.map((row) => (
										<tr
											key={row.id}
											onClick={() => setSelectedGroupForModule(row)}
											style={{ 
												backgroundColor: selectedGroupForModule === row ? '#00cfd6' : 'white',
												color: selectedGroupForModule === row ? 'white' : 'black',
											}}
										>
											<td style={rowStyle}>{row.id}</td>
											<td style={rowStyle}>{row.groupName}</td>
											<td style={rowStyle}>{row.groupSta}</td>
											<td style={rowStyle}>{row.permission}</td>
											<td style={rowStyle}>{row.activate}</td>
											<td style={rowStyle}>{row.remarks}</td>
										</tr>
									))}*/}
								</tbody>
							</Table>
						</Box>
					</Grid>
					<Button
						//onClick={handleConfirm}
						color='neutral'
						sx={{
							borderRadius:0,
							height: 25, 
							minHeight: 0,
							bgcolor:'#d6d3ce',
							color:'black',
							border:'2px outset grey',
							position:'absolute',
							right:9,
							top:-7
						}}
					>
						Edit
					</Button>
				</Grid>
			</Box>
		)
	}

	const ControlsEditore = () => {
		return(
			<Box 
				sx={{
					position:'relative',
					border:'1px solid black',
					mt:1
				}}
			>
				<Box 
					sx={{
						display:'flex', 
						position:'absolute',
						top:-12,
						left:5
					}}
				>
					<Typography
						sx={{
							bgcolor:'#d6d3ce',
							fontWeight:'600',
							color:'black',
							fontSize:14
						}}
					>
						Controls:
					</Typography>
				</Box>
				<Grid container spacing={0} sx={{ flexGrow: 1, height:125 }}>
					<Grid container spacing={0} xs={10} sx={{pl:1,pt:1}}>
						<Grid xs={12}>
							<Tooltip title='Quality Engineer'>
								<Checkbox 
									label=':Q - Quality Engineer'
									variant="outlined"
									color="neutral"
									sx={{
										fontSize:'14px',
										'--Checkbox-size':'15px',
										gap:0.4,
										'& .css-r4cpcz-JoyCheckbox-checkbox': {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
										'& .css-n110n' : {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
									}}
									//checked={editedData.shift}
									//onChange={(event) => handleChange('shift', event.target.checked)}
								/>
							</Tooltip>
						</Grid>

						<Grid xs={12}>
							<Tooltip title='A1 Technician'>
								<Checkbox 
									label=':A1 - A1 Technician'
									variant="outlined"
									color="neutral"
									sx={{
										fontSize:'14px',
										'--Checkbox-size':'15px',
										gap:0.4,
										'& .css-r4cpcz-JoyCheckbox-checkbox': {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
										'& .css-n110n' : {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
									}}
									//checked={editedData.shift}
									//onChange={(event) => handleChange('shift', event.target.checked)}
								/>
							</Tooltip>
						</Grid>

						<Grid xs={12}>
							<Tooltip title='B1 Engineer'>
								<Checkbox 
									label=':B1 - B1 Engineer'
									variant="outlined"
									color="neutral"
									sx={{
										fontSize:'14px',
										'--Checkbox-size':'15px',
										gap:0.4,
										'& .css-r4cpcz-JoyCheckbox-checkbox': {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
										'& .css-n110n' : {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
									}}
									//checked={editedData.shift}
									//onChange={(event) => handleChange('shift', event.target.checked)}
								/>
							</Tooltip>
						</Grid>

						<Grid xs={12}>
							<Tooltip title='B2 Engineer'>
								<Checkbox 
									label=':B2 - B2 Engineer'
									variant="outlined"
									color="neutral"
									sx={{
										fontSize:'14px',
										'--Checkbox-size':'15px',
										gap:0.4,
										'& .css-r4cpcz-JoyCheckbox-checkbox': {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
										'& .css-n110n' : {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
									}}
									//checked={editedData.shift}
									//onChange={(event) => handleChange('shift', event.target.checked)}
								/>
							</Tooltip>
						</Grid>

					</Grid>
					<Grid container spacing={0} xs={2} sx={{display:'flex', alignItems:'center'}}>
						<Grid xs={12}/>
						<Grid xs={12} sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
							<Button
								//onClick={handleConfirm}
								sx={{
									borderRadius:0,
									height: 25, 
									minHeight: 0,
									bgcolor:'#d6d3ce',
									color:'black',
									border:'2px outset grey',
									width:'70%'
								}}
							>
								Save
							</Button>
						</Grid>
						<Grid xs={12} sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
							<Button
								//onClick={handleConfirm}
								sx={{
									borderRadius:0,
									height: 25, 
									minHeight: 0,
									bgcolor:'#d6d3ce',
									color:'black',
									border:'2px outset grey',
									width:'70%'
								}}
							>
								Delete
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		)
	}
	
	return (
		<Box>
			<Typography 
				sx={{
					fontSize:'16px',
					fontWeight:'bold',
					color:'#000078',
					fontFamily:'Arial, sans-serif',
					ml:1
				}}
			>
				Aircraft Maintenance Requirements Editor:
			</Typography>
			<Box sx={{display:'flex', alignItems:'center'}}>
				<Tooltip title='Add'>
					<IconButton
						variant='plain'
						disabled={editedData.ataCode && editedData.title && editedData.acFamily ? false : true}
						onClick={handleAdd}
						sx={{
							m:0,
							'&:disabled img': {
								opacity: 0.5,
							}
						}}
					>
						<img 
							width='35px' 
							height='35px' 
							src="/images/svg/add.svg" 
							alt="" 
						/>
					</IconButton>
				</Tooltip>
				<Tooltip title='Update'>
					<IconButton 
						variant='plain'
						disabled={props.selectedRow.id && editedData.ataCode && editedData.title && editedData.acFamily ? false : true}
						onClick={handleUpdate}
						sx={{
							m:0,
							'&:disabled img': {
								opacity: 0.5,
							}
						}}
					>
						<img 
							width='35px' 
							height='35px' 
							src="/images/svg/save.svg" 
							alt="" 
						/>
					</IconButton>
				</Tooltip>
				<Tooltip title='Delete'>
					<IconButton 
						variant='plain'
						disabled={props.selectedRow.id ? false : true}
						onClick={handleDelete}
						sx={{
							m:0,
							'&:disabled img': {
								opacity: 0.5,
							}
						}}
					>
						<img 
							width='35px' 
							height='35px'  
							src="/images/svg/trashcan.svg" 
							alt="" 
						/>
					</IconButton>
				</Tooltip>
			</Box>
			<Box
				sx={{
					position:'absolute',
					height:`calc(100% - 70px)`,
					width:'100%',
					overflow:'auto',

				}}
			>
				<Grid container spacing={0} sx={{ flexGrow: 1 }}>
					<Grid xs={4}>
						<FormControl sx={formControlStyle} required>
							<FormLabel sx={labelStyle}>Treatment:</FormLabel>
							<Autocomplete
								disableClearable
								options={props.acFamilies.map(data=> ({ label: data.acFamily }))}
								value={{ label: editedData.acFamily ?? ''}}
								sx={inputStyle}
								onChange={(event, value) => handleChange('acFamily', value ? value.label : '')}
							/>
						</FormControl>
					</Grid>
					<Grid xs={8}>
						<FormControl sx={formControlStyle} required>
							<FormLabel sx={labelStyle}>Treatment Description:</FormLabel>
							<Textarea 
								disabled
								maxRows={1}
								defaultValue={props.selectedRow.description}
								sx={{
									...inputStyle,
									color:'#000078', 
									height:32,
									'--variant-outlinedDisabledColor':'black',
									'--variant-outlinedDisabledBorder':'black',
									bgcolor:'#d6d3ce',
								}}
								value={editedData?.remarks || ''}
								onChange={(event) => handleChange('remarks', event.target.value)}
							/>
						</FormControl>
					</Grid>

					<Grid xs={12} sx={{ml:0.5}}>
						<FormControl sx={{mt:2}}>
							<Tooltip title='IN SITU Component Maintenance - Replacements Is Not Required'>
								<Checkbox 
									label=':IN SITU Component Maintenance'
									variant="outlined"
									color="neutral"
									sx={{
										fontSize:'14px',
										'--Checkbox-size':'15px',
										fontWeight:'bold',
										gap:0.4,
										'& .css-r4cpcz-JoyCheckbox-checkbox': {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
										'& .css-n110n' : {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
									}}
									//checked={editedData.shift}
									//onChange={(event) => handleChange('shift', event.target.checked)}
								/>
							</Tooltip>
						</FormControl>
					</Grid>

					<Grid xs={12} sx={{mt:1}}>
						<Tabs
							//value={selectedTab}
							//onChange={(event, newValue) => handleChangeTab(newValue)}
							defaultValue="Int"
							sx={{
								flexDirection: 'column',
								bgcolor:'#d6d3ce',
								whiteSpace: 'nowrap',
								ml:0.6,
								'--Tabs-spacing': '5px'
							}}
						>
							<TabList >
								<Tooltip title='Interval'>
									<Tab value="Int" sx={tabStyle}>
										Interval
									</Tab>
								</Tooltip>
								<Tooltip title='Start Threshold'>
									<Tab value="ST" sx={tabStyle}>
										Start Threshold
									</Tab>
								</Tooltip>
								<Tooltip title='Finish Threshold'>
									<Tab value="FT" sx={tabStyle}>
										Finish Threshold
									</Tab>
								</Tooltip>
								<Tooltip title='Materials for Component Replacement'>
									<Tab value="RM" sx={tabStyle}>
										Replm Materials
									</Tab>
								</Tooltip>
								<Tooltip title='Tools and Equipments for Component Replacement'>
									<Tab value="RT" sx={tabStyle}>
										Replm Tools
									</Tab>
								</Tooltip>
								<Tooltip title='JIC procedure for Replacement'>
									<Tab value="RJIC" sx={tabStyle}>
										Replm JIC
									</Tab>
								</Tooltip>
								<Tooltip title='Instraction'>
									<Tab value="Inst" sx={tabStyle}>
										Instraction
									</Tab>
								</Tooltip>
							</TabList>
							<TabPanel value="Int" sx={{p:'0px'}}>
								<IntervalEditore/>
							</TabPanel>
							<TabPanel value="ST" sx={{p:'0px'}}>
								<StartThresholdEditore/>
							</TabPanel>
							<TabPanel value="FT" sx={{p:'0px'}}>
								<FinishThresholdEditore/>
							</TabPanel>
							<TabPanel value="RM" sx={{p:'0px'}}>
								<ReplmMaterials/>
							</TabPanel>
							<TabPanel value="RT" sx={{p:'0px'}}>
								<ReplmTools/>
							</TabPanel>
							<TabPanel value="RJIC" sx={{p:'0px'}}>
								<ReplmJIC/>
							</TabPanel>
							<TabPanel value="Inst" sx={{p:'0px'}}>
								<InstructionsEditore/>
							</TabPanel>
						</Tabs>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
}