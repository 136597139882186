import * as React from 'react'
import { classnames } from '../../helpers/classnames.ts'
import styles from './style.css'

type ArrowProps = {
    onClick?: (e: React.MouseEvent<HTMLOrSVGElement>) => void
    opened?: boolean,
    isRoot?: boolean
}

export const Arrow: React.FC<ArrowProps> = ({ onClick, opened, isRoot }) => {
    return <svg
        className={classnames({ [styles.hyperNodeArrowIcon]: true, [styles.opened]: !!opened })}
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        style={
            {
                transition: 'transform 0.1s ease',
                cursor: 'pointer',
                backgroundColor: 'white',
                marginRight: isRoot ? '4px' : '22px',
                minWidth: '10px',
                marginLeft: isRoot ? '0px' :'-37px',
                zIndex: '1'
            }
        }
        viewBox="0 0 24 24"
        onClick={onClick}
    >
        { opened 
            ? <><rect x="7" y="12" width="12" height="2"/><path transform="translate(-5,-5)" d="M8 7v22h22V8H7zM23 27H9V9h18V27z" /></>
            : <path d="M3 4.281v16.437A1.282 1.282 0 0 0 4.281 22h16.437A1.282 1.282 0 0 0 22 20.718V4.281A1.281 1.281 0 0 0 20.719 3H4.28A1.281 1.281 0 0 0 3 4.281zM20.719 4a.281.281 0 0 1 .281.281V20.72a.281.281 0 0 1-.281.281H4.28a.281.281 0 0 1-.28-.282V4.28A.281.281 0 0 1 4.281 4zM12 13H7v-1h5V7h1v5h5v1h-5v5h-1z"/>
        }
        
    </svg>
}