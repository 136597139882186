import * as React from 'react';

import Box from '@mui/joy/Box';
import Tab from '@mui/joy/Tab';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import TabPanel from '@mui/joy/TabPanel';
import CssBaseline from '@mui/joy/CssBaseline';
import { CssVarsProvider } from '@mui/joy/styles';

import Welcome from './Welcome/Welcome.tsx';
import Users_Registration from './UsersRegistration/Users_Registration.tsx';
import Security_Groups from './SecurityGroups/Security_Groups.tsx';
import User_Operator_Fleet from './UsersOperatorFleet/User_Operator_Fleet.tsx';
import Module_Security from './Module Security/Module_Security.tsx';
import Window_Security from './WindowSecurity/Window_Security.tsx';
import Operator_Registration from './OperatorRegistration/Operator_Registration.tsx';
import MRO_Registration from './MRORegistration/MRO_Registration.tsx';
import STA_Registration from './STARegistration/STA_Registration.tsx';
import Airline_Code from './AirlineCode/Airline_Code.tsx';
import Attachments from './Attachments/Attachments.tsx';
import User_Skill from './UserSkill/User_Skill.tsx'

import URL from '../../../../URL.js'

export default function User_Security() {
	document.body.style.backgroundColor = '#d6d3ce';
	document.title = 'Administrative'
	const tabStyle = {fontSize:'14px'}

	const [selectedTab, setSelectedTab] = React.useState (sessionStorage.getItem('SU_screen')?sessionStorage.getItem('SU_screen'):'W');
	const handleChangeTab = (newValue) => {
		sessionStorage.setItem('SU_screen', newValue ? newValue : 'W');
		setSelectedTab(newValue);
	} 

	React.useEffect(() => {
		if (!sessionStorage.getItem('SU_screen')) {
			sessionStorage.setItem('SU_screen','W');
		}
	}, []);

	return (
		<CssVarsProvider disableTransitionOnChange>
			<CssBaseline />
			<Box sx={{ display: 'flex', minWidth:'1400px' }}>
				<Box
					component="main"
					className="MainContent"
					sx={{
						flex: 1,
						display: 'flex',
						flexDirection: 'column',
						minWidth: 0,
					
						gap: 1,
					}}
				>
					<Box>
						<Tabs
							value={selectedTab}
							onChange={(event, newValue) => handleChangeTab(newValue)}
							sx={{
								flexDirection: 'column',
								bgcolor:'#d6d3ce',
								border:'1px solid black',
								whiteSpace: 'nowrap',
								minHeight:'855px'
							}}
						>
							<TabList >
								<Tab value="W" sx={tabStyle}>
									Welcome
								</Tab>
								<Tab value="UR" sx={tabStyle}>
									Users Registration
								</Tab>
								<Tab value="SG" sx={tabStyle}>
									Security Groups
								</Tab>
								<Tab value="UOF" sx={tabStyle}>
									Users-Operator-Fleet
								</Tab>
								<Tab value="MS" sx={tabStyle}>
									Module Security
								</Tab>
								<Tab value="WS" sx={tabStyle}>
									Window Security
								</Tab>
								<Tab value="OR" sx={tabStyle}>
									Operator Registration
								</Tab>
								<Tab value="MROR" sx={tabStyle}>
									MRO Registration
								</Tab>
								<Tab value="STAR" sx={tabStyle}>
									STA Registration
								</Tab>
								<Tab value="AC" sx={tabStyle}>
									Airline Code
								</Tab>
								<Tab value="A" sx={tabStyle}>
									Attachments
								</Tab>
								<Tab value="US" sx={tabStyle}>
									User Skill
								</Tab>
							</TabList>
							<TabPanel value="W" sx={{p:'0px'}}>
								<Welcome setSelectedTab={handleChangeTab}/>
							</TabPanel>
							<TabPanel value="UR" sx={{p:'0px'}}>
								<Users_Registration URL={URL}/>
							</TabPanel>
							<TabPanel value="SG" sx={{p:'0px'}}>
								<Security_Groups URL={URL}/>
							</TabPanel>
							<TabPanel value="UOF" sx={{p:'0px'}}>
								<User_Operator_Fleet URL={URL}/>
							</TabPanel>
							<TabPanel value="MS" sx={{p:'0px'}}>
								<Module_Security URL={URL}/>
							</TabPanel>
							<TabPanel value="WS" sx={{p:'0px'}}>
								<Window_Security URL={URL}/>
							</TabPanel>
							<TabPanel value="OR" sx={{p:'0px'}}>
								<Operator_Registration URL={URL}/>
							</TabPanel>
							<TabPanel value="MROR" sx={{p:'0px'}}>
								<MRO_Registration URL={URL}/>
							</TabPanel>
							<TabPanel value="STAR" sx={{p:'0px'}}>
								<STA_Registration URL={URL}/>
							</TabPanel>
							<TabPanel value="AC" sx={{p:'0px'}}>
								<Airline_Code URL={URL}/>
							</TabPanel>
							<TabPanel value="A" sx={{p:'0px'}}>
								<Attachments URL={URL}/>
							</TabPanel>
							<TabPanel value="US" sx={{p:'0px'}}>
								<User_Skill URL={URL}/>
							</TabPanel>
						</Tabs>
					</Box>		
				</Box>
			</Box>
		</CssVarsProvider>
	);
}
