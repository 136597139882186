import * as React from 'react';
import { useState} from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/joy/Box';
import Input from '@mui/joy/Input';
import Stack from '@mui/joy/Stack';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import CssBaseline from '@mui/joy/CssBaseline';
import FormControl from '@mui/joy/FormControl';
import GlobalStyles from '@mui/joy/GlobalStyles';
import FormHelperText from '@mui/joy/FormHelperText';
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel';
import { CssVarsProvider, useColorScheme } from '@mui/joy/styles';
import IconButton, { IconButtonProps } from '@mui/joy/IconButton';

import PassIcon from '@mui/icons-material/Lock';
import LoginIcon from '@mui/icons-material/Person';
import OpenEyeIcon from '@mui/icons-material/Visibility';
import CloseEyeIcon from '@mui/icons-material/VisibilityOff';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import EmailIcon from '@mui/icons-material/Email';

import URL from '../URL';
import ErrorSuccessfulResponse from '../components/Modules/Components/ErrorSuccessfullResponse.tsx';

interface FormElements extends HTMLFormControlsCollection {
	login: HTMLInputElement;
	password: HTMLInputElement;
	persistent: HTMLInputElement;
}

interface SignInFormElement extends HTMLFormElement {
  	readonly elements: FormElements;
}

function ColorSchemeToggle(props: IconButtonProps) {
	const { onClick, ...other } = props;
	const { mode, setMode } = useColorScheme();
	const [mounted, setMounted] = React.useState(false);
	React.useEffect(() => {
		setMounted(true);
	}, []);
	if (!mounted) {
		return <IconButton size="sm" variant="outlined" color="neutral" disabled />;
	}

	return (
		<IconButton
		id="toggle-mode"
		size="sm"
		color="neutral"
		aria-label="toggle light/dark mode"
		{...other}
		onClick={(event) => {
			if (mode === 'light') {
			setMode('dark');
			} else {
			setMode('light');
			}
			onClick?.(event);
		}}
		>
		{mode === 'light' ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
		</IconButton>
	);
}

function Logo() {
    const colorScheme = useColorScheme();
    const imageSource = colorScheme.mode === 'light' ? '/images/logo.svg' : '/images/logo_white.svg';

    return (
        <img width='260' src={imageSource} alt="" />
    );
}

export default function ResetPassword() {
	document.body.style.backgroundColor = 'white';
	const [email, setEmail] = useState('');
    const [showPassword, setShowPassword] = React.useState({main:false, copy:false})
    const [password, setPassword] = React.useState({main:'', copy:''})
    const [responseStatus, setResponseStatus] = React.useState({errorStatus:0, successfulStatus:''})
    const [checkMail, setCheckMail] = React.useState({exist:true, message:''})
	const [error, setError] = useState({wrongPass:false, serverError:false});
	const navigate = useNavigate();

	//Проверка наличия "asid" в localStorage
	React.useEffect(() => {
		if (localStorage.getItem('asid')) {
			navigate('/');
		}
	}, []);

	//Отправка запроса на получение линка
	const handleSendMail= async () => {
		fetch(
			`${URL}${process.env.REACT_APP_API_ADMIN}/PasswordRecovery/${email}`, 
			{
				method: 'POST',
				headers: {
				    'Content-Type': 'application/json'
				},
                body: JSON.stringify({page:`${window.location.host}/ResetPassword/`})
			}
		)
		.then((response) => {
			return response.json();
		})
		.then(data => {
            setCheckMail({exist:data.emailChecked, message:data.message})
		})
		.catch(error => console.error('Ошибка создания группы:', error));
	}

    //Запрос для проверки токена
	const fetchCheckToken= async () => {
		const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/PasswordRecovery/${window.location.href.split('/').pop()}`)
		if (!response.ok) {
            if(response.status === 422){
                navigate('/ResetPassword');
            }
			//setResponseStatus({...responseStatus, errorStatus:response.status});
		}
	};

	React.useEffect(() => {
        if(window.location.pathname !== '/ResetPassword'){
            fetchCheckToken();
        }
	}, []);

    //Отправка запроса на получение линка
	const handleResetPassword= async () => {

		fetch(
			`${URL}${process.env.REACT_APP_API_ADMIN}/PasswordRecovery/Reset/${window.location.href.split('/').pop()}`, 
			{
				method: 'PATCH',
				headers: {
				    'Content-Type': 'application/json'
				},
                body: JSON.stringify({password:password.main})
			}
		)
		.then((response) => {
			if(response.ok){
				navigate('/SignIn');
			}
			return response.json();
		})
		.then(data => {
	
		})
		.catch(error => console.error('Ошибка создания группы:', error));
	}

	return (
		<CssVarsProvider defaultMode="light" disableTransitionOnChange>
		<CssBaseline />
        <ErrorSuccessfulResponse responseStatus={responseStatus} setResponseStatus={setResponseStatus} />
		<GlobalStyles
			styles={{
				':root': {
					'--Collapsed-breakpoint': '769px', 
					'--Cover-width': '50vw', 
					'--Form-maxWidth': '1000px',
				},
			}}
		/>
		{error.wrongPass && (
			<Box
				sx={{
					position: 'fixed',
					top: '20px',
					right: '20px',
					display: 'flex',
					alignItems: 'center',
					color: 'red',
					backgroundColor:'#FCE4E4',
					px:1,
					borderRadius:'7px',
					zIndex: 9999,

				}}
			>
				<ErrorOutlineIcon sx={{ width:"24px", color:'red', mr: 1 }}/>
				<Typography sx={{ mt: 0.3, color: 'red' }}>
					Wrong login or password!
				</Typography>
			</Box>
		)}
		{error.serverError && (
			<Box
				sx={{
					position: 'fixed',
					top: '20px',
					right: '20px',
					display: 'flex',
					alignItems: 'center',
					color: 'red',
					backgroundColor:'#FCE4E4',
					px:1,
					borderRadius:'7px',
					zIndex: 9999,

				}}
			>
				<ErrorOutlineIcon sx={{ width:"24px", color:'red', mr: 1 }}/>
				<Typography sx={{ mt: 0.3, color: 'red' }}>
					Server error!
				</Typography>
			</Box>
		)}
		<Box
			sx={(theme) => ({
				width:
					'clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)',
				position: 'relative',
				zIndex: 1,
				display: 'flex',
				justifyContent: 'flex-end',
				backgroundColor: 'rgba(255 255 255)',
				[theme.getColorSchemeSelector('dark')]: {
					backgroundColor: 'rgba(0 0 0)',
				},
			})}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					minHeight: '100dvh',
					width:
						'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
					maxWidth: '100%',
					px: 2,
				}}
			>
				<Box
					component="header"
					sx={{
						pt: 3,
						display: 'flex',
						flexDirection: 'row-reverse',
						alignItems: 'center', 
						justifyContent: 'space-between',
					}}
				>
					<ColorSchemeToggle />
				</Box>
				<Box
					component="main"
					sx={{
						my: 'auto',
						py: 2,
						pb: 5,
						display: 'flex',
						flexDirection: 'column',
						gap: 1,
						width: 400,
						maxWidth: '100%',
						mx: 'auto',
						borderRadius: 'sm',
						'& form': {
							display: 'flex', 
							flexDirection: 'column',
							gap: 2,
						},
						[`& .${formLabelClasses.asterisk}`]: {
							visibility: 'hidden',
						},
					}}
				>
					<Box sx={{ mx: 'auto', gap: 2, display: 'flex', alignItems: 'center', pb: 9}}>
						<Logo/>
					</Box>
                    {window.location.pathname === '/ResetPassword' ?
                        (
                            <Stack gap={4} sx={{ mt: 1 }}>
                                <form
                                    onSubmit={(event: React.FormEvent<SignInFormElement>) => {
                                        event.preventDefault();
                                        handleSendMail()
                                    }}
                                >
                                    <FormControl>
                                        <FormLabel>E-Mail</FormLabel>
                                        <Input 
                                            type="email" 
                                            id="email" 
                                            pattern=".+@example\.com" 
                                            startDecorator={<EmailIcon sx={{ width:"20px" }}/>} 
                                            value={email || ''}
                                            onChange={(event) => setEmail(event.target.value)}
                                        />
										<FormHelperText>Enter your email for sending password recovery link</FormHelperText>
                                    </FormControl>
                                    <Button type="submit" fullWidth>
                                        Send Mail
                                    </Button>
                                </form>
                                <Typography
                                    color={checkMail.exist?'success':'danger'}
                                    variant="soft"
                                    sx={{
                                        display:checkMail.message!==''?'flex':'none'
                                    }}
                                >
                                   {checkMail.message}
                                </Typography>
                            </Stack>
                        ):(
                            <Stack gap={4} sx={{ mt: 1 }}>
                                <form
                                    onSubmit={(event: React.FormEvent<SignInFormElement>) => {
                                        event.preventDefault();
                                        handleResetPassword()
                                    }}
                                >
                                    <FormControl required>
                                        <FormLabel>Password</FormLabel>
                                        <Input
                                            type={showPassword.main ? 'text' : 'password'}
                                            color={error.wrongPass ? 'danger' : 'neutral'}
                                            startDecorator={<PassIcon sx={{ width:"20px" }}/>}
                                            value={password?.main || ''}
                                            onChange={(event) => setPassword({...password, main:event.target.value})}
                                            endDecorator={
                                                <IconButton
                                                    onClick={() => setShowPassword({...showPassword, main:!showPassword.main})}
                                                    aria-label="toggle password visibility"
                                                >
                                                    {showPassword ? <OpenEyeIcon /> : <CloseEyeIcon />}
                                                </IconButton>
                                            }
                                        />
										<FormHelperText>Enter your new password</FormHelperText>
                                    </FormControl>
                                    <FormControl required>
										<FormLabel>Repeat Password</FormLabel>
										<Input
											type={showPassword.copy ? 'text' : 'password'}
											color={password.main !== password.copy ? 'danger' : 'neutral'}
											startDecorator={<PassIcon sx={{ width:"20px" }}/>}
											value={password?.copy || ''}
											onChange={(event) => setPassword({...password, copy:event.target.value})}
											endDecorator={
												<IconButton
													onClick={() => setShowPassword({...showPassword, copy:!showPassword.copy})}
													aria-label="toggle password visibility"
												>
													{showPassword ? <OpenEyeIcon /> : <CloseEyeIcon />}
												</IconButton>
											}
										/>
										
									</FormControl>
                                    <Button 
										type="submit" 
										fullWidth
										disabled={password.main !== password.copy}
									>
                                        Reset Password
                                    </Button>
                                </form>
                            </Stack>
                        )
                    }
				</Box>
				<Box component="footer" sx={{ py: 3 }}>
					<Typography level="body-xs" textAlign="center">
					    © Alaskar Technologies, Inc.{new Date().getFullYear()}
					</Typography>
				</Box>
			</Box>
		</Box>
		<Box
			sx={(theme) => ({
				height: '100%',
				position: 'fixed',
				right: 0,
				top: 0,
				bottom: 0,
				left: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
				backgroundSize: 'cover',
				backgroundPosition: 'right',
				backgroundRepeat: 'no-repeat',
				transform: 'scaleX(-1)',
				backgroundImage:
					'url(/images/engine.webp)',
				[theme.getColorSchemeSelector('dark')]: {
					transform: 'scaleX(1)',
					backgroundPosition: 'center',
					backgroundImage:
					'url(/images/engine_black.avif)',
				},
			})}
		/>
		</CssVarsProvider>
	);
}