import * as React from 'react';
import { useState} from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/joy/Box';
import Input from '@mui/joy/Input';
import LinearProgress from '@mui/joy/LinearProgress';
import Checkbox from '@mui/joy/Checkbox';
import Button from '@mui/joy/Button';
import FormHelperText from '@mui/joy/FormHelperText';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import IconButton from '@mui/joy/IconButton';
import Sheet from '@mui/joy/Sheet'

import OpenEyeIcon from '@mui/icons-material/Visibility';
import CloseEyeIcon from '@mui/icons-material/VisibilityOff';

import URL from '../../../URL.js';
import ErrorSuccessfulResponse from '../Components/ErrorSuccessfullResponse.tsx';
import { Tooltip } from '@mui/joy';

export default function Password() {
	const navigate = useNavigate();
	document.body.style.backgroundColor = 'white';

	//Стили
	const checkboxStyle = {'--Checkbox-size':'16px', fontSize:14, gap:0.7, my:0.5}
	const labelStyle = {m:0, ml:'3px'}
	const formStyle = {mb:3}

	const [error, setError] = useState(false)
	const [password, setPassword] = useState({old:'', main:'', copy:''})
	const [passwordStrength, setPasswordStrength] = useState(0);
	const [passwordСriteria, setPasswordСriteria] = useState({sixCharacters:false, eightСharacters:false, capital:false, lowercase:false, numbers:false, special:false, sequences:true, repeated:true, unique:true})
	const [showPassword, setShowPassword] = useState({old:false, main:false, copy:false})

	//Генерация случайного пароля
	const generateRandomPassword = () => {
		const lowerCase = 'abcdefghijklmnopqrstuvwxyz';
		const upperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
		const numbers = '0123456789';
		const specialChars = '!,@,#,$,%,^,&,*,?,_,~';
		const allChars = lowerCase + upperCase + numbers + specialChars;
	
		const getRandomChar = (chars) => chars[Math.floor(Math.random() * chars.length)];
	
		const createPassword = () => {
			let password = '';
			password += getRandomChar(lowerCase);
			password += getRandomChar(upperCase);
			password += getRandomChar(numbers);
			password += getRandomChar(specialChars);
			for (let i = 0; i < 4; i++) {
				password += getRandomChar(allChars);
			}
			return password.split('').sort(() => Math.random() - 0.5).join('');
		};
	
		const hasSequence = (password) => {
			const sequences = [
				"123456", "123456789", "12345678", "12345", "1234567",
				"qwerty", "abc", "password", "letmein", "111111",
				"123123", "welcome", "admin", "monkey", "iloveyou",
				"sunshine", "123321", "qwertyuiop", "1234", "1q2w3e4r",
				"qazwsx", "zxcvbn", "asdfgh", "qwert", "zxcv"
			];
			return sequences.some(seq => password.includes(seq));
		};
	
		const hasRepeatedChars = (password) => /(.)(?=.*\1{2,})/.test(password);
	
		let password;
		do {
			password = createPassword();
		} while (hasSequence(password) || hasRepeatedChars(password));

		handleChange( 'main', password)
	};
	
	//Расчет сложности пароля
	const calculateStrength = (password) => {
        let score = 0;
		var criteria ={sixCharacters:false, eightСharacters:false, capital:false, lowercase:false, numbers:false, special:false, sequences:true, repeated:true, unique:true}

        // Условия для оценки сложности

		// Минимальная длина
        if (password.length >= 6) { 
			score += 10; 
			criteria.sixCharacters = true;
		} else {
			criteria.sixCharacters = false;
		}
		// Дополнительные баллы за длину
		if (password.length >= 8){
			score += 10;
			criteria.eightСharacters = true;
		} else {
			criteria.eightСharacters = false;
		}

		// Заглавные буквы
        if (/[A-Z]/.test(password)){
			score += 20; 
			criteria.capital = true;
		} else { 
			criteria.capital = false;
		} 

		// Строчные буквы
        if (/[a-z]/.test(password)) {
			score += 20; 
			criteria.lowercase = true;
		} else {
			criteria.lowercase = false;
		} 

		// Цифры
        if (/[0-9]/.test(password)) {
			score += 20; 
			criteria.numbers = true;
		} else {
			criteria.numbers = false;
		}

		// Специальные символы
        if (/(.*[!,@,#,$,%,^,&,*,?,_,~])/.test(password)) {
			score += 20;
			criteria.special = true;
		} else {
			criteria.special = false;
		}  

		// Проверка на последовательности
		const sequences = [
			"123456", 
			"123456789", 
			"12345678", 
			"12345", 
			"1234567",
    		"qwerty", 
			"abc", 
			"password", 
			"letmein", 
			"111111",
    		"123123", 
			"welcome", 
			"admin", 
			"monkey", 
			"iloveyou",
    		"sunshine", 
			"123321", 
			"qwertyuiop", 
			"1234", 
			"1q2w3e4r",
    		"qazwsx",
			"zxcvbn",
			"asdfgh", 
			"qwert", 
			"zxcv"
		];

		for (let seq of sequences) {
			if (password.includes(seq) || seq.includes(password)) {
				score -= 20; // Уменьшаем балл за последовательности
				criteria.sequences = false;
				break;
			} else {
				criteria.sequences = true;
			}
		}
	
		// Проверка на повторяющиеся символы
		const repeatedChars = password.match(/(.)\1{2,}/); // 3 и более одинаковых символа подряд
		if (repeatedChars) {
			score -= 20; // Уменьшаем балл за повторяющиеся символы
			criteria.repeated = false
		} else {
			criteria.repeated = true
		}
	
		// Учет разнообразия символов
		const uniqueChars = new Set(password);
		if (uniqueChars.size < password.length / 2) {
			score -= 10; // Уменьшаем балл за недостаточное разнообразие
			criteria.repeated = false
		} else {
			criteria.repeated = true
		}
	
		setPasswordСriteria(criteria)
		return Math.max(score, 0);
    };

	const handleChange = (type, value) => {
        setPassword({...password, [type]:value});
		if(type === 'main') setPasswordStrength(calculateStrength(value));
    };

	const handleSubmit = () => {
		return fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/Sessions`, {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				login:localStorage.getItem('login'),
				oldPassword:password.old,
				newPassword:password.main
			})
		})
		.then((response) => 
			{
				if (!response.ok){
					if (response.status === 401) {
						setError(true)
						throw new Error("Wrong login or password");
					} else if (response.status === 500) {
						throw new Error("Server error");
					}
				}
				return response.json();
			})
		.then((data) => {
			if (data) {
				localStorage.removeItem('name');
				localStorage.removeItem('login');
				localStorage.removeItem('asid');
				navigate('/SignIn');
			} else {
				throw new Error("Server error");
			}
		})
		.catch((error) => {
			console.error('Ошибка при выполнении запроса /Sessions:', error);
		});
	}

	const PasswordHelper = () => {
		return(
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Typography>
					To increase password strength:
				</Typography>
				<Checkbox
					label='Use at least 6 characters;'
					variant="outlined"
					color={passwordСriteria.sixCharacters?'success':'danger'}
					sx={{...checkboxStyle, color:passwordСriteria.sixCharacters?'green':'red'}}
					checked={passwordСriteria.sixCharacters}
				/>
				<Checkbox
					label='Use at least 8 characters;'
					variant="outlined"
					color={passwordСriteria.eightСharacters?'success':'danger'}
					sx={{...checkboxStyle, color:passwordСriteria.eightСharacters?'green':'red'}}
					checked={passwordСriteria.eightСharacters}
				/>
				<Checkbox
					label='Use lowercase letters [a-z];'
					variant="outlined"
					color={passwordСriteria.lowercase?'success':'danger'}
					sx={{...checkboxStyle, color:passwordСriteria.lowercase?'green':'red'}}
					checked={passwordСriteria.lowercase}
				/>
				<Checkbox
					label='Use capital letters [A-Z];'
					variant="outlined"
					color={passwordСriteria.capital?'success':'danger'}
					sx={{...checkboxStyle, color:passwordСriteria.capital?'green':'red'}}
					checked={passwordСriteria.capital}
				/>
				<Checkbox
					label='Use numbers [0-9];'
					variant="outlined"
					color={passwordСriteria.numbers?'success':'danger'}
					sx={{...checkboxStyle, color:passwordСriteria.numbers?'green':'red'}}
					checked={passwordСriteria.numbers}
				/>
				<Checkbox
					label='Use special characters [! " # $ % & ...];'
					variant="outlined"
					color={passwordСriteria.special?'success':'danger'}
					sx={{...checkboxStyle, color:passwordСriteria.special?'green':'red'}}
					checked={passwordСriteria.special}
				/>
				<Checkbox
					label={
						<span>
							Don't use popular sequences <br />
							(like: '123', 'qwerty', 'admin', etc.);
						</span>
					}
					variant="outlined"
					color={passwordСriteria.sequences?'success':'danger'}
					sx={{...checkboxStyle, color:passwordСriteria.sequences?'green':'red'}}
					checked={passwordСriteria.sequences}
				/>
				<Checkbox
					label={
						<span>
							Do not use more than 2 repeated <br /> 
							characters in a row (like: 'aaa', '111', etc.);
						</span>
					}
					variant="outlined"
					color={passwordСriteria.repeated?'success':'danger'}
					sx={{...checkboxStyle, color:passwordСriteria.repeated?'green':'red'}}
					checked={passwordСriteria.repeated}
				/>
				<Checkbox
					label='Use a variety of symbols;'
					variant="outlined"
					color={passwordСriteria.unique?'success':'danger'}
					sx={{...checkboxStyle, color:passwordСriteria.unique?'green':'red'}}
					checked={passwordСriteria.unique}
				/>
			</Box>
		);
	}

	return (
		<Box
			sx={{
				height:'100%',
				position:'relative',
				display:'flex',
				alignItems:'center',
				justifyContent:'center',
				flexDirection:'column'
			}}
		>
			<Sheet
				color="primary" 
				variant="soft"
				sx={{
					borderRadius:'20px',
					px:'40px',
					py:'20px',
					boxShadow:'0px 0px 20px black',
					display:'flex',
					flexDirection:'column',
					alignItems:'center',
				}}
			>
				<FormControl sx={formStyle}>
					<FormLabel sx={labelStyle}>Current Password</FormLabel>
					<Input 
						placeholder='Enter Your Current Password'
						color={error?'danger':'neutral'}
						value={password.old}
						onChange={(e) => handleChange( 'old', e.target.value)}
						type={showPassword.old ? 'text' : 'password'}
						endDecorator={
							<IconButton
								onClick={() => setShowPassword({...showPassword, old:!showPassword.old})}
								aria-label="toggle password visibility"
							>
								{showPassword.old ? <OpenEyeIcon /> : <CloseEyeIcon />}
							</IconButton>
						}
					/>
					<FormHelperText sx={{display:error? 'flex':'none', color:'#c41c1c', ml:1, mt:0}}>Wrong password</FormHelperText>
				</FormControl>
				<Tooltip 
					title={<PasswordHelper/>}
					placement="right-start"
					variant="outlined"
					color="neutral"
					arrow
				>
					<FormControl>
						<FormLabel sx={labelStyle}>New Password</FormLabel>
						<Input 
							placeholder='Enter New Password'
							value={password.main}
							onChange={(e) => handleChange( 'main', e.target.value)}
							type={showPassword.main ? 'text' : 'password'}
							endDecorator={
								<IconButton
									onClick={() => setShowPassword({...showPassword, main:!showPassword.main})}
									aria-label="toggle password visibility"
								>
									{showPassword.main ? <OpenEyeIcon /> : <CloseEyeIcon />}
								</IconButton>
							}
						/>
						<LinearProgress
							determinate
							size="md"
							value={Math.min(passwordStrength, 120)}
							sx={{ 
								bgcolor: 'background.level3', 
								color: `hsl(${passwordStrength} 80% 30%)`,
								mt:'4px',
								mx:'1px'
							}}
						/>
						<Typography
							level="body-xs"
							sx={{ alignSelf: 'flex-end', color: `hsl(${passwordStrength} 80% 30%)` }}
						>
							{passwordStrength < 20 && 'Very weak'}
							{passwordStrength >= 20 && passwordStrength <= 40 && 'Weak'}
							{passwordStrength > 40 && passwordStrength <= 60 && 'Normal'}
							{passwordStrength > 60 && passwordStrength <= 80 && 'Strong'}
							{passwordStrength > 80 && 'Very strong'}
						</Typography>
					</FormControl>
				</Tooltip>
				<FormControl >
					<FormLabel sx={labelStyle}>Repete Password</FormLabel>
					<Input 
						placeholder='Repete Password'
						value={password.copy}
						onChange={(e) => handleChange( 'copy', e.target.value)}
						type={showPassword.copy ? 'text' : 'password'}
						endDecorator={
							<IconButton
								onClick={() => setShowPassword({...showPassword, copy:!showPassword.copy})}
								aria-label="toggle password visibility"
							>
								{showPassword.copy ? <OpenEyeIcon /> : <CloseEyeIcon />}
							</IconButton>
						}
					/>
				</FormControl>
				<Button
					color="neutral"
					variant="soft"
					onClick={generateRandomPassword}
					sx={{
						mt:2,
						border:'1px solid gray'
					}}
				>
					Generate random password
				</Button>
				<Button
					color="success"
					variant="soft"
					onClick={handleSubmit}
					disabled={password.main !== password.copy}
					sx={{
						mt:2,
						border:'1px solid gray'
					}}
				>
					Submit
				</Button>
			</Sheet>
		</Box>
	);
}