import { ITreeViewIcon } from "../../../../../../../../Base/TreeView/IconInterpterer/ITreeViewIcon.ts";
import { ITreeViewIconCollection } from "../../../../../../../../Base/TreeView/IconInterpterer/ITreeViewIconCollection.ts";

export class AmpPosIcons implements ITreeViewIconCollection {

    static readonly PlaneVertical: ITreeViewIcon = { 
        source: 'plane_vertical.svg', 
        heigth: 14, 
        width: 14 
    };

    static readonly ReportModelProject: ITreeViewIcon = { 
        source: 'report_model_project.svg', 
        heigth: 14, 
        width: 14 
    };

    static readonly Mechanic: ITreeViewIcon = { 
        source: 'mechanic.svg', 
        heigth: 14, 
        width: 14 
    };

    static readonly MechanicPp: ITreeViewIcon = { 
        source: 'mechanic_pp.png', 
        heigth: 14, 
        width: 14 
    };

    static readonly Clock02: ITreeViewIcon = { 
        source: 'clock_02.svg', 
        heigth: 14, 
        width: 14 
    };

    static readonly Settings: ITreeViewIcon = { 
        source: 'clock_02.svg', 
        heigth: 14, 
        width: 14 
    };

    static readonly BlueBox: ITreeViewIcon = { 
        source: 'box_1_blue.svg', 
        heigth: 13, 
        width: 13 
    };

    static readonly YellowBox: ITreeViewIcon = { 
        source: 'box_1_yellow.svg', 
        heigth: 13, 
        width: 13 
    };

    static readonly GreenBox: ITreeViewIcon = { 
        source: 'box_1.svg', 
        heigth: 13, 
        width: 13 
    };

    static readonly GreenHBox: ITreeViewIcon = { 
        source: 'box_1h.svg', 
        heigth: 13, 
        width: 13 
    };

    static readonly GreenDoubleBox: ITreeViewIcon = { 
        source: 'box_2.svg', 
        heigth: 13, 
        width: 19 
    };

    static readonly GreenDoubleHBox: ITreeViewIcon = { 
        source: 'box_2h.svg', 
        heigth: 13, 
        width: 19 
    };
}