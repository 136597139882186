import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/joy/Box';
import Link from '@mui/joy/Link';
import List from '@mui/joy/List';
import Button from '@mui/joy/Button';
import Breadcrumbs from '@mui/joy/Breadcrumbs';
import { CssVarsProvider } from '@mui/joy/styles';

import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

import Header from '../Header.tsx';

import URL from '../../URL.js';

export default function Main() {
	document.body.style.backgroundColor = 'white';
	document.title = 'Alaskar'
	const [permissions, setPermissions] = React.useState<string[]>([]);
	const [searchText, setSearchText] = React.useState('');
	const navigate = useNavigate();

	React.useEffect(() => {
		
		const fetchData = async () => {
			try {
				const response = await fetch(`${URL}${process.env.REACT_APP_API_ADMIN}/Self/Permissions`, { headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
				const data = await response.json();

				const uniqueModules: any = Array.from(new Set(data.value.map((item) => item.module)));

				setPermissions(uniqueModules);
			} catch (error) {
				navigate('/SignIn');
				localStorage.removeItem('name');
				localStorage.removeItem('login');
				localStorage.removeItem('asid');
			}
		};
		fetchData();
	
	}, []);
	
	const handleButtonClick = (module: string) => {
		navigate(`/${module}`);
		{/*window.open(`/${module}`, '_blank');*/}
	};

	return (
		<CssVarsProvider disableTransitionOnChange>
			<Box
				sx={{
					display: 'grid',
					gridTemplateRows: 'minmax(57px, 70px) 10px 1fr',
					minHeight: '100vh',
				}}
			>
				<Box
					sx={{
						p: 2,
						gap: 2,
						bgcolor: 'background.surface',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
						gridColumn: '1 / -1',
						borderBottom: '1px solid',
						borderColor: 'divider',
						position: 'sticky',
						top: 0,
						zIndex: 1100,
					}}
				>
					<Header setSearchText={setSearchText} />
				</Box>

				<Box>
					<List
						sx={{
						display: 'grid',
						gridTemplateColumns: `repeat(auto-fit, minmax(300px, 1fr))`,
						gap: 5,
						m: 3,
						mx: '10%',
						}}
					>
						{permissions
						.filter((data) => data.toLowerCase().includes(searchText.toLowerCase()))
						.map((filteredData: any) => (
							<Button
								key={filteredData}
								variant="outlined"
								color="neutral"
								sx={{
									height: '100px',
									borderRadius: '15px',
									boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.13)',
									fontSize: '18px',
								}}
								onClick={() => handleButtonClick(filteredData.replace(/\s/g, ''))}
							>
								{filteredData}
							</Button>
						))}
					</List>
				</Box>
			</Box>
		</CssVarsProvider>
	);
}
