import * as React from 'react';

import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Input from '@mui/joy/Input';
import Radio from '@mui/joy/Radio';
import Tooltip from '@mui/joy/Tooltip';
import Checkbox from '@mui/joy/Checkbox';
import Textarea from '@mui/joy/Textarea';
import FormLabel from '@mui/joy/FormLabel';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import Autocomplete from '@mui/joy/Autocomplete';

import FolderOpenIcon from '@mui/icons-material/FolderOpen';

import AdditionalInfoWnd from './Additional_Info_wnd.tsx';
import ConfirmAction from '../../../Components/ConfirmAction.tsx';

export default function AddEditeForm(props) {
	//Стили
	const inputStyle = { borderRadius: 0, px:1, mx: 1, height: 30, minHeight: 0, border:'1px solid black', color:'#000084', fontWeight:'bold', fontSize:'18px', lineHeight:1, "--Input-decoratorChildHeight": "24px"};
	const labelStyle = { mx: 1, my: 0, p: 0, fontSize:'16px' };
	const formControlStyle = { my: 0 };

	const [ACData, setACData] = React.useState([{acModel:'', acType:'', apuModel:'', engineModel:''}]);
	const [operatorsData, setOperatorsData] = React.useState([{name:'', staOffice:''}]);
	const [staName, setStaName] = React.useState();
	const [additionalInfo, setAdditionalInfo] = React.useState({open:false,item:''});
	const [tcSource, setTcSource] = React.useState('Attachments');

	const [editedData, setEditedData] = React.useState({});
	const [showConfirm, setShowConfirm] = React.useState(false);
	const [confirmActionType, setConfirmActionType] = React.useState('');
	const windowName = 'Actual Aircraft'
	const [isRegValid, setRegValid] = React.useState(true);
	const [meta, setMeta] = React.useState([{name:'',max:0}]);

	React.useEffect(() => {
		setEditedData(props.selectedRow);
		setRegValid(true);
	}, [props.selectedRow]);

	//Запрос списка ACData
	React.useEffect(() => {
		const fetchACData = async () => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_ADMIN}/AircraftRegistrations?$filter=serialnumber eq '${editedData.serialNumber}'&$select=actype, acmodel, enginemodel, apumodel`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {
				const result = await response.json();
				setACData(result.value[0]);
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		};
		if(editedData.serialNumber){
			fetchACData();
		}
	}, [editedData.serialNumber]);

	//Запрос списка OperatorsData
	React.useEffect(() => {
		const fetchOperatorData = async () => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_ADMIN}/OperatorRegistrations?&filter=icao eq '${editedData.operatorICAO}'&$select=name, staoffice`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {
				const result = await response.json();
				setOperatorsData(result.value[0]);
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		};
		if(editedData.operatorICAO){
			fetchOperatorData();
		}
	}, [editedData.operatorICAO]);

	//Запрос StaName
	React.useEffect(() => {
		const fetchOperatorData = async () => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_ADMIN}/StaRegistrations?$filter=code eq '${operatorsData.staOffice}'&$select=name`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {
				const result = await response.json();
				setStaName(result.value[0].name);
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		};
		if(operatorsData.staOffice){
			fetchOperatorData();
		}
	}, [operatorsData.staOffice]);

	//Запрос ограничений для полей
	React.useEffect(() => {
		const fetchMeta = async () => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_ADMIN}/ActualAircrafts/Meta()`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setMeta(result);
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		};
		fetchMeta();
	}, []);

	//Запрос проверки валидности AC_Reg
	const checkValidReg = async () => {
		const response = await fetch(`${props.URL}${process.env.REACT_APP_API_ADMIN}/ActualAircrafts/ValidReg()?reg='${editedData.reg}'`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {
			const result = await response.json();
			setRegValid(result.value);
			return result.value;
		} else {
			props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
		}
	};

	//Обработчик открытия окна AdditionalInfo
	const handleAdditionalInfo = (event,item) => {
		if (event.code === 'F1') { 
			event.preventDefault(); 
			if (editedData[item]) {
				setAdditionalInfo({open:true, item:item})
			}
		}
	};

	//Создание PDF отчёта
	{/**
	const createReport = () => {
		setReportLoading(true);
		return fetch(`${URL}/reports/Reports`, {
			method: 'POST',
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/json'
			},
			body:JSON.stringify({
				"templateName": "Templates/PostgreSQL/Admin/UserEditor",
				"isExportAsPicture": false,
				"parameters": [
					{
					"name": 'userId',
					"value": `${props.selectedRow.login}`
					}
				]
			})
		})
		.then((response) => {
			if (response.ok) {
				return response.blob();
			} else {
				errors(response.status);
				setReportLoading(false);
			}
		})
		.then((blob) => {
			if (blob) {
				const url = window.URL.createObjectURL(blob); 
				const link = document.createElement('a'); 
				link.href = url;
				link.setAttribute('download', '/UserEditor.pdf'); 
				document.body.appendChild(link); 
				link.click();
				link.remove(); 
				window.URL.revokeObjectURL(url);

				setReportLoading(false);
			} else {
				setReportLoading(false);
			}
		})
		.catch((error) => {
			console.error('Ошибка при получении файла:', error);
			setReportLoading(false);
		});
	};
	*/}

	const handleAction = async (type) => {
		setConfirmActionType(type);
		if (type === 'Add'){
			if (await checkValidReg()) {
				setShowConfirm(true);
			}
		} else if (type === 'Update') {
			if(editedData.reg === props.selectedRow.reg || await checkValidReg()){
				setRegValid(true);;
				setShowConfirm(true);
			}
		} else if (type === 'Delete'){
			setShowConfirm(true);
		}
	};

	//Обработчик события добавления новой записи
	const handleAdd = () => {
		fetch(
			`${props.URL}${process.env.REACT_APP_API_ADMIN}/ActualAircrafts`, 
			{
				method: 'POST',
				headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					serialNumber: editedData?.serialNumber,
					reg: editedData?.reg,
					previousReg: editedData?.previousReg,
					deliverDate: editedData?.deliverDate,
					deliverHours: editedData?.deliverHours,
					deliverCycles: editedData?.deliverCycles,
					pax: editedData?.pax,
					mtow: editedData?.mtow,
					certifiedTOW: editedData?.certifiedTOW,
					mtxw: editedData?.mtxw,
					selcalCode: editedData?.selcalCode,
					modeAddress: editedData?.modeAddress,
					dfdauFrame: editedData?.dfdauFrame,
					atcCode: editedData?.atcCode,
					operatorICAO: editedData.operatorICAO,
					operatorName: editedData.operatorICAO ? operatorsData.name : '',
					operatorStaLocation: editedData.operatorICAO ? operatorsData.staOffice : '',
					aircraftStaLocation: editedData.aircraftStaLocation,
					operatorICAOPrevious: editedData.operatorICAOPrevious,
					operatorNamePrevious: editedData.operatorICAOPrevious? props.operators.find(item => item.icao === editedData.operatorICAOPrevious)?.name: '',
					mroCode: editedData.mroCode,
					mroName: editedData.mroCode ? props.MRO.find(item => item.code === editedData.mroCode)?.name : '',
					staMRO: editedData.mroCode ? props.MRO.find(item => item.code === editedData.mroCode)?.sta : '',
					engineeringCode: editedData.engineeringCode,
					engineeringName: editedData.engineeringCode ? props.MRO.find(item => item.code === editedData.engineeringCode)?.name : '',
					engineeringSta: editedData.engineeringCode ? props.MRO.find(item => item.code === editedData.engineeringCode)?.sta : '',
					engineModel: editedData.serialNumber ? ACData?.engineModel ?? '' : '',
					engineThrust: editedData?.engineThrust,
					apuModel: editedData.serialNumber ? ACData?.apuModel ?? '' : '',
					acAccount: editedData?.acAccount,
					componentAccount: editedData?.componentAccount,
					acOwner: editedData?.acOwner,
					componentOwner: editedData?.componentOwner,
					remarks: editedData?.remarks,
					active: editedData?.active,
					tcDocNumber: editedData?.tcDocNumber,
					manuals: editedData.manuals,
					manualsPath: editedData?.manualsPath,
					isLumpActive: null,
					//reportsLogoName: string
				})
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Add'});
			}
			props.reloadTable();
			return response.json();
		})
		.catch(error => console.error('Ошибка создания группы:', error));
	}

	//Обработчик события обновления выбранной записи
	const handleUpdate = () => {
		fetch(
			`${props.URL}${process.env.REACT_APP_API_ADMIN}/ActualAircrafts/${props.selectedRow.id}`, 
			{
				method: 'PATCH',
				headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					serialNumber: editedData?.serialNumber,
					reg: editedData?.reg,
					previousReg: editedData?.previousReg,
					deliverDate: editedData?.deliverDate,
					deliverHours: editedData?.deliverHours,
					deliverCycles: editedData?.deliverCycles,
					pax: editedData?.pax,
					mtow: editedData?.mtow,
					certifiedTOW: editedData?.certifiedTOW,
					mtxw: editedData?.mtxw,
					selcalCode: editedData?.selcalCode,
					modeAddress: editedData?.modeAddress,
					dfdauFrame: editedData?.dfdauFrame,
					atcCode: editedData?.atcCode,
					operatorICAO: editedData.operatorICAO,
					operatorName: editedData.operatorICAO ? operatorsData.name : '',
					operatorStaLocation: editedData.operatorICAO ? operatorsData.staOffice : '',
					aircraftStaLocation: editedData.aircraftStaLocation,
					operatorICAOPrevious: editedData.operatorICAOPrevious,
					operatorNamePrevious: editedData.operatorICAOPrevious? props.operators.find(item => item.icao === editedData.operatorICAOPrevious)?.name: '',
					mroCode: editedData.mroCode,
					mroName: editedData.mroCode ? props.MRO.find(item => item.code === editedData.mroCode)?.name : '',
					staMRO: editedData.mroCode ? props.MRO.find(item => item.code === editedData.mroCode)?.sta : '',
					engineeringCode: editedData.engineeringCode,
					engineeringName: editedData.engineeringCode ? props.MRO.find(item => item.code === editedData.engineeringCode)?.name : '',
					engineeringSta: editedData.engineeringCode ? props.MRO.find(item => item.code === editedData.engineeringCode)?.sta : '',
					engineModel: editedData.serialNumber ? ACData?.engineModel ?? '' : '',
					engineThrust: editedData?.engineThrust,
					apuModel: editedData.serialNumber ? ACData?.apuModel ?? '' : '',
					acAccount: editedData?.acAccount,
					componentAccount: editedData?.componentAccount,
					acOwner: editedData?.acOwner,
					componentOwner: editedData?.componentOwner,
					remarks: editedData?.remarks,
					active: editedData?.active,
					tcDocNumber: editedData?.tcDocNumber,
					manuals: editedData.manuals,
					manualsPath: editedData?.manualsPath,
					isLumpActive: null,
					//reportsLogoName: string
				})
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Update'});
			}
			props.reloadTable();
			return response.json();
		})
		.catch(error => console.error('Ошибка обновления группы:', error));
	}

	//Обработчик события удаления выбранной записи
	const handleDelete = () => {
		fetch(
			`${props.URL}${process.env.REACT_APP_API_ADMIN}/ActualAircrafts/${props.selectedRow.id}`, 
			{
				method: 'DELETE',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				}
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Delete'});
			}
			props.reloadTable();
			return response.json();
		})
		.catch(error => console.error('Ошибка удаления группы:', error));
	}
	
	//Внесение изменений в data
	const handleChange = (property, value) => {
		//Проверка максимальной длинны строки
		const maxLengthDefault = 255;
		let maxLength = (meta.find(item => item.name.toLowerCase() === property.toLowerCase())?.max ?? "") || maxLengthDefault;
		if (value.length > maxLength) return;	
		
		setEditedData({ ...editedData, [property]: value });	
	};
	
	return (
		<Box>
			{showConfirm && (
				<ConfirmAction 
					actionType={confirmActionType} 
					windowName={windowName} 
					onConfirmAdd={handleAdd} 
					onConfirmUpdate={handleUpdate} 
					onConfirmDelete={handleDelete} 
					setShowConfirm={setShowConfirm}
					showConfirm={showConfirm}
				/>
			)}
			{additionalInfo.open && (
				<AdditionalInfoWnd item={additionalInfo.item} value={editedData[additionalInfo.item]} setAdditionalInfo={setAdditionalInfo} URL={props.URL}/>
			)}
			<Typography 
				sx={{
					fontSize:'18px',
					fontWeight:'bold',
					color:'#000078',
					fontFamily:'Arial, sans-serif',
					ml:1
				}}
			>
				Aircraft Actual Registration Editor:
			</Typography>
			<Tooltip title='Add'>
				<IconButton
					variant='plain'
					disabled={editedData.serialNumber && editedData.reg && editedData.deliverDate && editedData.deliverHours && editedData.deliverCycles && editedData.operatorICAO && editedData.aircraftStaLocation && editedData.mroCode && editedData.engineeringCode ? false : true}
					onClick={()=>handleAction('Add')}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px' 
						src="images/svg/add.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Tooltip title='Update'>
				<IconButton 
					variant='plain'
					disabled={props.selectedRow.id && editedData.serialNumber && editedData.reg && editedData.deliverDate && editedData.deliverHours && editedData.deliverCycles && editedData.operatorICAO && editedData.aircraftStaLocation && editedData.mroCode && editedData.engineeringCode ? false : true}
					onClick={()=>handleAction('Update')}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px' 
						src="images/svg/save.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Tooltip title='Delete'>
				<IconButton 
					variant='plain'
					disabled={props.selectedRow.id ? false : true}
					onClick={()=>handleAction('Delete')}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px'  
						src="images/svg/trashcan.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Grid container spacing={0} sx={{ flexGrow: 1 }}>
				<Grid xs={3}>
					<FormControl sx={formControlStyle} required>
						<FormLabel sx={labelStyle}>AC SN:</FormLabel>
						<Autocomplete
							disableClearable
							options={props.SN.map(data=> ({ label: data }))}
							value={{ label: editedData?.serialNumber ?? '' }}
							sx={{
								...inputStyle,
								backgroundColor:'#c6ffff',
							}}
							onChange={(event, value) => handleChange('serialNumber', value ? value.label : '')}
							onKeyDown={(event) => handleAdditionalInfo(event, 'serialNumber')}
						/>
					</FormControl>
				</Grid>
				<Grid xs={3}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>AC Type:</FormLabel>
						<Input 
							size="sm" 
							disabled
							sx={{
								...inputStyle, 
								'--variant-outlinedDisabledColor':'black',
								'--variant-outlinedDisabledBorder':'black',
								bgcolor:'#d6d3ce',
							}} 
							value={editedData.serialNumber ? ACData?.acType ?? '' : ''}
						/>
					</FormControl>
				</Grid>
				<Grid xs={3}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>AC Model:</FormLabel>
						<Input 
							size="sm" 
							disabled
							sx={{
								...inputStyle, 
								'--variant-outlinedDisabledColor':'black',
								'--variant-outlinedDisabledBorder':'black',
								bgcolor:'#d6d3ce',
							}} 
							value={editedData.serialNumber ? ACData?.acModel ?? '' : ''}
						/>
					</FormControl>
				</Grid>
				<Grid xs={3} sx={{display:'flex', alignItems:'flex-end'}}>
					<Checkbox 
						label="Activate"
						color='danger'
						variant='outlined' 
						sx={{
							color:'#840000',
							m:0.5
						}}
						checked={editedData.active == 'Y' ? true : false}
						onChange={(event) => handleChange('active', event.target.checked ? 'Y' : 'N')}
					/>
				</Grid>
				
				<Grid xs={3}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={{ ...labelStyle, width: '100%', display: 'flex' }}>
							<span style={{ display: 'flex', alignItems: 'center' }}>
								<Typography>AC Reg:</Typography>
								<Typography sx={{ color: '#C41C1C', fontSize: '1em', marginLeft: '4px' }}>*</Typography>
							</span>
							<Typography 
								color='danger'
								sx={{ 
									ml: 'auto', 
									mr:2,
									fontSize:12,
									display:isRegValid === false? 'flex' :'none'
								}}
							>
								AC Reg is already exist
							</Typography>
						</FormLabel>
						<Input 
							color={!isRegValid ? 'danger' : 'primary'}
							sx={{...inputStyle, border:isRegValid ? '1px solid black':'1px solid #C41C1C', color: isRegValid ? '#000084':'#C41C1C'}} 
							value={editedData?.reg ?? ''} 
							onChange={(event) => handleChange('reg', event.target.value)} 
						/>
					</FormControl>
				</Grid>
				<Grid xs={3}>
					<FormControl sx={formControlStyle} required>
						<FormLabel sx={labelStyle}>Deliv Date:</FormLabel>
						<Input
							type="date"
							sx={inputStyle}
							value={editedData.deliverDate?.slice(0, 10)?? ''}
							onChange={(event) => handleChange('deliverDate', event.target.value? event.target.value + 'T00:00:00Z' : null)}
						/>
					</FormControl>
				</Grid>
				<Grid xs={3}>
					<FormControl sx={formControlStyle} required>
						<FormLabel sx={labelStyle}>Deliv Hours:</FormLabel>
						<Input  
							sx={inputStyle} 
							value={editedData?.deliverHours ?? ''}
							onChange={(event) => handleChange('deliverHours', event.target.value)}
						/>
					</FormControl>
				</Grid>
				<Grid xs={3}>
					<FormControl sx={formControlStyle} required>
						<FormLabel sx={labelStyle}>Deliv Cycles:</FormLabel>
						<Input  
							sx={inputStyle} 
							value={editedData?.deliverCycles ?? ''}
							onChange={(event) => handleChange('deliverCycles', event.target.value)}
						/>
					</FormControl>
				</Grid>

				<Grid xs={3}>
					<FormControl sx={formControlStyle} required>
						<FormLabel sx={labelStyle}>Engine Model:</FormLabel>
						<Input 
							size="sm" 
							disabled
							sx={{
								...inputStyle, 
								'--variant-outlinedDisabledColor':'black',
								'--variant-outlinedDisabledBorder':'black',
								bgcolor:'#d6d3ce',
							}} 
							value={editedData.serialNumber ? ACData?.engineModel ?? '' : ''}
						/>
					</FormControl>
				</Grid>
				<Grid xs={3}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>Engine Thrust:</FormLabel>
						<Input 
							sx={inputStyle} 
							value={editedData?.engineThrust ?? ''}
							onChange={(event) => handleChange('engineThrust', event.target.value)}
						/>
					</FormControl>
				</Grid>
				<Grid xs={3}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>APU Model:</FormLabel>
						<Input 
							size="sm" 
							disabled
							sx={{
								...inputStyle, 
								'--variant-outlinedDisabledColor':'black',
								'--variant-outlinedDisabledBorder':'black',
								bgcolor:'#d6d3ce',
							}} 
							value={editedData.serialNumber ? ACData?.apuModel ?? '' : ''}
						/>
					</FormControl>
				</Grid>
				<Grid xs={3}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>PAX:</FormLabel>
						<Input 
							sx={inputStyle} 
							value={editedData?.pax ?? ''}
							onChange={(event) => handleChange('pax', event.target.value)}
						/>
					</FormControl>
				</Grid>
				
				<Grid xs={4}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>MTOW:</FormLabel>
						<Input 
							sx={inputStyle} 
							value={editedData?.mtow ?? ''}
							onChange={(event) => handleChange('mtow', event.target.value)}
						/>
					</FormControl>
				</Grid>
				<Grid xs={4}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>MTXW:</FormLabel>
						<Input 
							sx={inputStyle} 
							value={editedData?.mtxw  ?? ''}
							onChange={(event) => handleChange('mtxw', event.target.value)}
						/>
					</FormControl>
				</Grid>
				<Grid xs={4}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>Certificated TOW:</FormLabel>
						<Input 
							sx={inputStyle} 
							value={editedData?.certifiedTOW  ?? ''}
							onChange={(event) => handleChange('certifiedTOW', event.target.value)}
						/>
					</FormControl>
				</Grid>

				<Grid xs={3}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>SELCAL Code:</FormLabel>
						<Input 
							sx={inputStyle} 
							value={editedData?.selcalCode  ?? ''}
							onChange={(event) => handleChange('selcalCode', event.target.value)}
						/>
					</FormControl>
				</Grid>
				<Grid xs={3}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>Mode S Address:</FormLabel>
						<Input 
							sx={inputStyle} 
							value={editedData?.modeAddress ?? ''}
							onChange={(event) => handleChange('modeAddress', event.target.value)}
						/>
					</FormControl>
				</Grid>
				<Grid xs={3}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>DFDAU Frame:</FormLabel>
						<Input 
							sx={inputStyle} 
							value={editedData?.dfdauFrame  ?? ''}
							onChange={(event) => handleChange('dfdauFrame', event.target.value)}
						/>
					</FormControl>
				</Grid>
				<Grid xs={3}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>ATC Code:</FormLabel>
						<Input 
							sx={inputStyle} 
							value={editedData?.atcCode  ?? ''}
							onChange={(event) => handleChange('atcCode', event.target.value)}
						/>
					</FormControl>
				</Grid>

				<Grid xs={4}>
					<FormControl sx={formControlStyle} required>
						<FormLabel sx={labelStyle}>Operat. Code ICAO:</FormLabel>
						<Autocomplete
							disableClearable
							options={props.operators.map(data=> ({ label: data.icao }))}
							value={{ label: editedData.operatorICAO ?? '' }}
							sx={{
								...inputStyle,
								backgroundColor:'#c6ffff'
							}}
							onChange={(event, value) => handleChange('operatorICAO', value ? value.label : '')}
							onKeyDown={(event) => handleAdditionalInfo(event, 'operatorICAO')}
						/>
					</FormControl>
				</Grid>
				<Grid xs={8}>
					<FormControl sx={formControlStyle} required>
						<FormLabel sx={labelStyle}>Operator Name:</FormLabel>
						<Input 
							size="sm" 
							disabled
							sx={{
								...inputStyle, 
								'--variant-outlinedDisabledColor':'black',
								'--variant-outlinedDisabledBorder':'black',
								bgcolor:'#d6d3ce',
							}} 
							value={editedData.operatorICAO ? operatorsData.name : ''}
						/>
					</FormControl>
				</Grid>

				<Grid xs={3}>
					<FormControl sx={formControlStyle} required>
						<FormLabel sx={labelStyle}>STA - Operator:</FormLabel>
						<Input 
							size="sm" 
							disabled
							sx={{
								...inputStyle, 
								'--variant-outlinedDisabledColor':'black',
								'--variant-outlinedDisabledBorder':'black',
								bgcolor:'#d6d3ce',
							}} 
							value={editedData.operatorICAO ? operatorsData.staOffice : ''}
						/>
					</FormControl>
				</Grid>
				<Grid xs={3}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>STA Name:</FormLabel>
						<Input 
							size="sm" 
							disabled
							sx={{
								...inputStyle, 
								'--variant-outlinedDisabledColor':'black',
								'--variant-outlinedDisabledBorder':'black',
								bgcolor:'#d6d3ce',
							}} 
							value={editedData.operatorICAO ? staName : ''}
						/>
					</FormControl>
				</Grid>
				<Grid xs={3}>
					<FormControl sx={formControlStyle} required>
						<FormLabel sx={labelStyle}>STA - Aircraft:</FormLabel>
						<Autocomplete
							disableClearable
							options={props.sta.map(data=> ({ label: data.code }))}
							value={{ label: editedData.aircraftStaLocation ?? '' }}
							sx={{
								...inputStyle,
								backgroundColor:'#c6ffff',
							}}
							onChange={(event, value) => handleChange('aircraftStaLocation', value ? value.label : '')}
							onKeyDown={(event) => handleAdditionalInfo(event, 'aircraftStaLocation')}
						/>
					</FormControl>
				</Grid>
				<Grid xs={3}>
					<Tooltip title={editedData.aircraftStaLocation? props.sta.find(item => item.code === editedData.aircraftStaLocation)?.name : ''}>
						<FormControl sx={formControlStyle}>
							<FormLabel sx={labelStyle}>STA Name:</FormLabel>
							<Input 
								size="sm" 
								disabled
								sx={{
									...inputStyle, 
									'--variant-outlinedDisabledColor':'black',
									'--variant-outlinedDisabledBorder':'black',
									bgcolor:'#d6d3ce',
								}} 
								value={editedData.aircraftStaLocation? props.sta.find(item => item.code === editedData.aircraftStaLocation)?.name : ''}
							/>
						</FormControl>
					</Tooltip>
				</Grid>
				
				<Grid xs={3}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>Prev. AC Reg:</FormLabel>
						<Input 
							sx={inputStyle} 
							value={editedData?.previousReg ?? ''}
							onChange={(event) => handleChange('previousReg', event.target.value)}
						/>
					</FormControl>
				</Grid>
				<Grid xs={4}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>Prev. Oper. Code ICAO:</FormLabel>
						<Autocomplete
							disableClearable
							options={props.operators.map(data=> ({ label: data.icao }))}
							value={{ label: editedData.operatorICAOPrevious ?? '' }}
							sx={{
								...inputStyle,
								backgroundColor:'#c6ffff',
							}}
							onChange={(event, value) => handleChange('operatorICAOPrevious', value ? value.label : '')}
							onKeyDown={(event) => handleAdditionalInfo(event, 'operatorICAOPrevious')}
						/>
					</FormControl>
				</Grid>
				<Grid xs={5}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>Previous Operator Name:</FormLabel>
						<Input 
							size="sm" 
							disabled
							sx={{
								...inputStyle, 
								'--variant-outlinedDisabledColor':'black',
								'--variant-outlinedDisabledBorder':'black',
								bgcolor:'#d6d3ce',
							}} 
							value={editedData.operatorICAOPrevious? props.operators.find(item => item.icao === editedData.operatorICAOPrevious)?.name : ''}
						/>
					</FormControl>
				</Grid>

				<Grid xs={3}>
					<FormControl sx={formControlStyle} required>
						<FormLabel sx={labelStyle}>MRO Code:</FormLabel>
						<Autocomplete
							disableClearable
							options={props.MRO.map(data=> ({ label: data.code }))}
							value={{ label: editedData.mroCode ?? '' }}
							sx={{
								...inputStyle,
								backgroundColor:'#c6ffff',
							}}
							onChange={(event, value) => handleChange('mroCode', value ? value.label : '')}
							onKeyDown={(event) => handleAdditionalInfo(event, 'mroCode')}
						/>
					</FormControl>
				</Grid>
				<Grid xs={6}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>MRO Provider Name:</FormLabel>
						<Input 
							size="sm" 
							disabled
							sx={{
								...inputStyle, 
								'--variant-outlinedDisabledColor':'black',
								'--variant-outlinedDisabledBorder':'black',
								bgcolor:'#d6d3ce',
							}} 
							value={editedData.mroCode ? props.MRO.find(item => item.code === editedData.mroCode)?.name : ''}
						/>
					</FormControl>
				</Grid>
				<Grid xs={3}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>MRO STA:</FormLabel>
						<Input 
							size="sm" 
							disabled
							sx={{
								...inputStyle, 
								'--variant-outlinedDisabledColor':'black',
								'--variant-outlinedDisabledBorder':'black',
								bgcolor:'#d6d3ce',
							}} 
							value={editedData.mroCode ? props.MRO.find(item => item.code === editedData.mroCode)?.sta : ''}
						/>
					</FormControl>
				</Grid>

				<Grid xs={3}>
					<FormControl sx={formControlStyle} required>
						<FormLabel sx={labelStyle}>Eng. Code:</FormLabel>
						<Autocomplete
							disableClearable
							options={props.MRO.map(data=> ({ label: data.code }))}
							value={{ label: editedData.engineeringCode ?? '' }}
							sx={{
								...inputStyle,
								backgroundColor:'#c6ffff',
							}}
							onChange={(event, value) => handleChange('engineeringCode', value ? value.label : '')}
							onKeyDown={(event) => handleAdditionalInfo(event, 'engineeringCode')}
						/>
					</FormControl>
				</Grid>
				<Grid xs={6}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>Engineering Provider Name:</FormLabel>
						<Input 
							size="sm" 
							disabled
							sx={{
								...inputStyle, 
								'--variant-outlinedDisabledColor':'black',
								'--variant-outlinedDisabledBorder':'black',
								bgcolor:'#d6d3ce',
							}} 
							value={editedData.engineeringCode ? props.MRO.find(item => item.code === editedData.engineeringCode)?.name : ''}
						/>
					</FormControl>
				</Grid>
				<Grid xs={3}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>Eng. STA:</FormLabel>
						<Input 
							size="sm" 
							disabled
							sx={{
								...inputStyle, 
								'--variant-outlinedDisabledColor':'black',
								'--variant-outlinedDisabledBorder':'black',
								bgcolor:'#d6d3ce',
							}} 
							value={editedData.engineeringCode ? props.MRO.find(item => item.code === editedData.engineeringCode)?.sta : ''}
						/>
					</FormControl>
				</Grid>

				<Grid xs={5}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>Aircraft Account:</FormLabel>
						<Input 
							sx={inputStyle} 
							value={editedData?.acAccount ?? ''}
							onChange={(event) => handleChange('acAccount', event.target.value)}
						/>
					</FormControl>
				</Grid>
				<Grid xs={7}>
					<FormControl sx={formControlStyle}>
						<FormLabel sx={labelStyle}>Aircraft Owner:</FormLabel>
						<Input 
							sx={inputStyle} 
							value={editedData?.acOwner ?? ''}
							onChange={(event) => handleChange('acOwner', event.target.value)}
						/>
					</FormControl>
				</Grid>

				<Grid xs={6}>
					<Box sx={{display:'flex', mt:1, ml:1}}>
						<Typography sx={{lineHeight:1}}>
							Component <br/>
							Account:
						</Typography>
						<Input 
							sx={{...inputStyle, width:'100%'}} 
							value={editedData?.componentAccount ?? ''}
							onChange={(event) => handleChange('componentAccount', event.target.value)}
						/>
					</Box>
				</Grid>
				<Grid xs={6}>
					<Box sx={{display:'flex', mt:1, ml:1}}>
						<Typography sx={{lineHeight:1}}>
							Component <br/>
							Owner:
						</Typography>
						<Input 
							sx={{...inputStyle, width:'100%'}} 
							value={editedData?.componentOwner ?? ''}
							onChange={(event) => handleChange('componentOwner', event.target.value)}
						/>
					</Box>
				</Grid>

				<Grid xs={6}>
					<Box sx={{display:'flex', mt:1, ml:1}}>
						<Typography sx={labelStyle}>
							Remarks:
						</Typography>
						<Textarea 
							maxRows={1}
							sx={{...inputStyle,color:'#000078',width:'100%'}}
							value={editedData?.remarks ?? ''}
							onChange={(event) => handleChange('remarks', event.target.value)}
						/>
					</Box>
				</Grid>
				<Grid xs={6}>
					<Box sx={{display:'flex', mt:1, ml:1}}>
						<Typography sx={{lineHeight:1}}>
							TC Doc. <br/>
							Number:
						</Typography>
						<Textarea 
							maxRows={1}
							sx={{...inputStyle,color:'#000078',width:'100%'}}
							value={editedData?.tcDocNumber ?? ''}
							onChange={(event) => handleChange('tcDocNumber', event.target.value)}
						/>
					</Box>
				</Grid>
				<Grid xs={6}>
					<Box sx={{display:'flex', mt:1, ml:1, gap:2}}>
						<Typography >
							TC Source:
						</Typography>
						<Radio
							color='neutral'
							checked={!editedData.manuals}
							onChange={(event) => handleChange('manuals', false)}
							value='Attachments'
							label='Attachments'
						/>
						<Radio
							color='neutral'
							checked={editedData.manuals}
							onChange={(event) => handleChange('manuals', true)}
							value='Manuals'
							label='Manuals'
						/>
					</Box>
				</Grid>
				<Grid xs={5}>
					<Box sx={{display:'flex', mt:1, ml:1}}>
						<Typography sx={labelStyle}>
							Path:
						</Typography>
						<Textarea 
							disabled={tcSource==='Attachments' ? true:false}
							maxRows={3}
							sx={{
								...inputStyle,
								color:'#000078',
								height:'55px',
								lineHeight:1,
								width:'100%',
								'--variant-outlinedDisabledColor':'black',
								'--variant-outlinedDisabledBorder':'black',
								bgcolor:tcSource==='Attachments'?'#d6d3ce':'white',
							}}
							value={editedData?.manualsPath ?? ''}
							onChange={(event) => handleChange('manualsPath', event.target.value)}
						/>
					</Box>
				</Grid>
				<Grid xs={1}>
					<Tooltip title='Browse File'>
						<div>
							<label htmlFor="browse-file">
								<FolderOpenIcon
									sx={{
										
										borderRadius: '0px',
										border: '1px solid black',
										width: '35px',
										height: '35px',
										color: 'black',
										mt:1,
										'&:hover': {
											opacity:0.6
										}
									}}
								/>
							</label>
							<input
								id="browse-file"
								disabled={tcSource==='Attachments' ? true:false}
								type="file"
								style={{ 
									display: 'none', 
								}}
								onChange={(event) => handleChange('manualsPath', event.target.value)}
							/>
						</div>
					</Tooltip>
				</Grid>
			</Grid>
		</Box>
	);
}
