import { AmpPosIcons } from "./Dictionaries/AmpPosIcons.ts";
import { AmpPartEffIconContext } from "./Context/AmpPartEffIconContext.ts";
import { Interpreter } from "../../../../../../Base/TreeView/IconInterpterer/Interpreter.ts";
import { ITreeViewIcon } from "../../../../../../Base/TreeView/IconInterpterer/ITreeViewIcon.ts";

export class AmpPartEffIconInterpreter extends Interpreter<AmpPartEffIconContext> {
    public Interpret(context: AmpPartEffIconContext): ITreeViewIcon | undefined {
        if (context.isStartState)
            return AmpPosIcons.ReportModelProject;
        if (context.isRoot)
            return AmpPosIcons.Mechanic;

        return this.isGreenBox(context.hasTreatment) 
            ? AmpPosIcons.GreenBox 
            : AmpPosIcons.BlueBox;
    }

    private isGreenBox(hasTreatment : boolean | null) {
        return hasTreatment;
    }
}