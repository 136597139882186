import { ITreeViewIcon } from "../../../../../../../Base/TreeView/IconInterpterer/ITreeViewIcon";
import { ITreeViewIconCollection } from "../../../../../../../Base/TreeView/IconInterpterer/ITreeViewIconCollection";

export class AmpModelIcons implements ITreeViewIconCollection {

    static readonly Refresh: ITreeViewIcon = { 
        source: 'refresh.svg', 
        heigth: 15, 
        width: 15 
    };

    static readonly Plane: ITreeViewIcon = { 
        source: 'plane.svg', 
        heigth: 14, 
        width: 14 
    };

    static readonly ReportModelProject: ITreeViewIcon = { 
        source: 'report_model_project.svg', 
        heigth: 14, 
        width: 14 
    };

    static readonly TaskL: ITreeViewIcon = { 
        source: 'task_l.svg', 
        heigth: 15, 
        width: 15 
    };
}