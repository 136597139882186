import * as React from 'react';

import Modal from '@mui/joy/Modal';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import IconButton from '@mui/joy/IconButton';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';

import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export default function ConfirmAction(props) {
    var title
    if (props.actionType === 'Add') {
        title = `Add New ${props.windowName}`
    } else if (props.actionType === 'Update') {
		title = `Update ${props.windowName}`
	} else if (props.actionType === 'Delete') {
		title = `Delete ${props.windowName}`
	} else if (props.actionType === 'DeleteSign'){
		title = `Delete Sign`
	} else if (props.actionType === 'DeleteStamp'){
		title = `Delete Stamp`
	} else if (props.actionType === 'DeletePhoto'){
		title = `Delete Engineer Photo`
	} else if (props.actionType === 'PaswordChange'){
		title = `Mandatory password change`
	}

	const handleYes = () => {
		if (props.actionType === 'Add') {
			props.onConfirmAdd(); 
		} else if (props.actionType === 'Update') {
			props.onConfirmUpdate()
		} else if (props.actionType === 'Delete') {
			props.onConfirmDelete()
		} else if (props.actionType === 'DeleteSign'){
			props.onConfirmDeleteSign()
		} else if (props.actionType === 'DeleteStamp'){
			props.onConfirmDeleteStamp()
		} else if (props.actionType === 'DeletePhoto'){
			props.onConfirmDeletePhoto()
		} else if (props.actionType === 'PaswordChange'){
			props.onComfirmPasswordPolicy()
		}
		props.setShowConfirm(false)
	};
	
    return (
		<Modal 
			open={props.showConfirm} 
			onClose={() => props.setShowConfirm(false)}
			disableRestoreFocus={true}
			disableEnforceFocus={true}
		>
			<ModalDialog 
				variant="outlined" 
				role="alertdialog"
				sx={{
					p:0,
					borderRadius:0
				}}
			>
				<DialogTitle
					sx={{
						py:0.5,
						pl:1,
						bgcolor:'#08246b',
						color:'white',
					}}
				>
					{title}
					<IconButton 
						onClick={() => props.setShowConfirm(false)}
						sx={{
							mr:1,
							ml:'auto',
							height:'19px',
							minHeight:'0px',
							width:'19px',
							minWidth:'0px',
							color:'black'
						}}
					>
						<CloseIcon/>
					</IconButton>
				</DialogTitle>
				<DialogContent 
					sx={{
						display:'flex',
						flexDirection:'row',
						alignItems:'center',
						justifyContent:'center',
						fontSize:'18px',
						color:'black'
					}}
				>
					<HelpOutlineIcon sx={{width:'40px', height:'40px'}}/>
					Confirm {title}!
				</DialogContent>
				<DialogActions
				
					sx={{
						bgcolor:'#d6d3ce',
						pt:0,
					}}
				>
					<Input 
						key='No'
						type = 'Button' 
						onClick={() => props.setShowConfirm(false)}
						autoFocus
						value='No'
						sx={{
							borderRadius:0,
							minHeight:0,
							minWidth:0,
							height:'26px',
							width:'70px',
							border:'2px solid black',
							bgcolor:'#d6d3ce',
							color:'black',
							m:1,
							'&:hover': {
								backgroundColor: '#a8a5e6',
							}
						}}         
					/>
					<Button
						onClick={handleYes}
						color='neutral'
						sx={{
							borderRadius:0,
							minHeight:0,
							minWidth:0,
							height:'26px',
							width:'70px',
							border:'1px solid black',
							bgcolor:'#d6d3ce',
							color:'black',
							m:1,
							'&:hover': {
								backgroundColor: '#a8a5e6',
							}
						}}                            
					>
						Yes
					</Button>
				</DialogActions>
			</ModalDialog>
     	</Modal>
	);
}