import * as React from 'react';

import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import Button from '@mui/joy/Button';
import Textarea from '@mui/joy/Textarea';
import FormLabel from '@mui/joy/FormLabel';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import FormControl from '@mui/joy/FormControl';
import Autocomplete from '@mui/joy/Autocomplete';

import TaskIcon from '@mui/icons-material/Task';
import CloseIcon from '@mui/icons-material/Close';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';

import AS_Submiting_Initializing from './AS_Submiting_Initializing.tsx'

import URL from '../../../../URL.js'

export default function AS_Initializing({AS, setOpenInitializing}) {
	const [AMPstring, setAMPstring] = React.useState<{ id: string, otherProperties: string }[]>([]);
	const [AMP, setAMP] = React.useState<{ id: string, revision: string, revisionDate: string, remarks:string}[]>([]);
	const [selectedAMP, setSelectedAMP] = React.useState<{ id: string, revision: string, revisionDate: string, remarks:string}>(); 
	const [editedRemarks, setEditedRemarks] = React.useState('');
	const [openSubmiting, setOpenSubmiting] = React.useState(false);

	const handleClose = () => {
		setOpenInitializing(false);
	};

	React.useEffect(() => {
		const fetchData = async () => {
		  try {
			const response = await fetch(
			  `${URL}${process.env.REACT_APP_API_PARTM}/Amp?$filter=acFamily eq '${AS.acFamily}'&select=id,revision,revisionDate,remarks`,
			  {
				headers: {
				  Authorization: `Bearer ${localStorage.getItem('asid')}`,
				},
			  }
			);
			if (!response.ok) {
			  throw new Error('Failed to fetch data');
			}
			const data = await response.json();
	  
			// Преобразование данных и запись в состояние AMP
			const updatedAMP = data.value.map(item => {
				const keys = Object.keys(item).filter(key => key !== 'remarks');
				const otherProperties = keys.map(key => {
					if (key === "revisionDate") {
						const date = new Date(item[key]);
						const formattedDate = `${date.getDate()}-${date.toLocaleString('en-us', { month: 'short' })}-${date.getFullYear().toString().slice(-2)}`;
						return `Date:${formattedDate}`;
					} else if (key === "revision") {
						return `Rev.: ${item[key]}`;
					} else {
						return item[key];
					}
				}).join(';');
				return { id: item.id, remarks: item.remarks, otherProperties };
			});		

			setAMPstring(updatedAMP);
			setAMP(data.value);
		  } catch (error) {
			console.error('Error fetching data:', error);
		  }
		};
	  
		fetchData();
	}, []);

	const handlCreate = () =>{
		if (selectedAMP) {
			const updatedSelectedAMP = {
				...selectedAMP,
				remarks: editedRemarks !== '' ? editedRemarks : selectedAMP.remarks,
			};
			setSelectedAMP(updatedSelectedAMP);
		}
		setOpenSubmiting(true);
	};
	
	let xcenter = (window.innerWidth/2)-442;
	let ycenter = (window.innerHeight/2)-185;

	const [isDragging, setIsDragging] = React.useState(false);
  	const [position, setPosition] = React.useState({ x: xcenter, y: ycenter });
  	const [startPosition, setStartPosition] = React.useState({ x: 0, y: 0 });
	const handleMouseDown = (e) => {
		setIsDragging(true);
		setStartPosition({
			x: e.clientX - position.x,
			y: e.clientY - position.y,
		});
	};
	
	const handleMouseMove = (e) => {
		if (!isDragging) return;
		setPosition({
			x: e.clientX - startPosition.x,
			y: e.clientY - startPosition.y,
		});
	};
	
	const handleMouseUp = () => {
		setIsDragging(false);
	};

	return (
		<React.Fragment>
			<>
				{openSubmiting && (
					<AS_Submiting_Initializing 
						AS={AS}
						selectedAMP={selectedAMP}
						setOpenSubmiting={setOpenSubmiting}
						setOpenInitializing={setOpenInitializing}
					/>
				)}
				<div
					style={{
						position: 'fixed',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
						backgroundColor: 'rgba(0, 0, 0, 0.3)',
						zIndex: 1,
						overflow:'hidden'
					}}
				/>
				<Sheet
					variant="plain"
					sx={{
						height:'405px',
						width:'885px',
						borderRadius: '0px',
						position: 'absolute',
						border:'1px solid black',
						zIndex:2,
						left: position.x,
						top: position.y,
					}}
				>
					<Box
						sx={{
							display:'grid',
							gridTemplateRows:'30px 375px'
						}}
					>
						<Box
							sx={{
								display:'flex',
								alignItems:'center',
								background:'#08246b',
								cursor: isDragging ? 'grabbing' : 'grab',
							}}
							onMouseDown={handleMouseDown}
							onMouseMove={handleMouseMove}
							onMouseUp={handleMouseUp}
						>
							<Typography 
								startDecorator={<AirplanemodeActiveIcon sx={{height:'20px', color:'white'}}/>}
								sx={{
									fontSize:'15px',
									fontWeight:'600',
									color:'white',
								}}
							>
								Initial Aircraft Initializing
							</Typography>
							<IconButton 
							 	onClick={handleClose}
								sx={{
									mr:1,
									ml:'auto',
									'--Icon-color':'white',
									height:'19px',
									minHeight:'0px',
									width:'19px',
									minWidth:'0px'
								}}
							>
								<CloseIcon/>
							</IconButton>
						</Box>
						<Box
							sx={{
								display:'grid',
								gridTemplateRows:'140px 170px',
								backgroundColor:'#d6d3ce'
							}}
						>
							<Box
								sx={{
									display:'grid',
									gridTemplateRows:'25px ',
									border:'1px solid black',
									my:1,
									mx:1,
									height:'140px',
								}}
							>
								<Typography
									sx={{
										ml:1.5,
										color:'#000084',
										fontWeight:'bold'
									}}
								>
									Selected Aircraft:
								</Typography>
								<Box 
									sx={{
										display:'flex',
										flexDirection:'flex-start'
									}}
								>
									<FormControl sx={{mx:1}}>
										<FormLabel 
											sx={{
												alignSelf:'center',
												fontSize:'12px',
												margin:'0px'
											}}
										>
											AC Family:
										</FormLabel>
										<Textarea 
											maxRows={1} 
											variant='outlined' 
											value={AS.acFamily}
											disabled
											sx={{
												'--variant-outlinedDisabledColor':'balck',
												'--variant-outlinedDisabledBorder':'black',
												'--Textarea-paddingBlock':0,
												'--Textarea-paddingInline':0,
												width:'200px',
												height:'25px',
												minHeight:'0px',
												borderRadius:'0px',
												border:'1px solid black',
												fontWeight:'bold',
												fontSize:'16px',
												backgroundColor:'#d6d3ce',
												'& textarea':{
													textAlign:'center',
													height:'10px'
												}
											}}
										/>
									</FormControl>
									<FormControl sx={{mx:1}}>
										<FormLabel 
											sx={{
												alignSelf:'center',
												fontSize:'12px',
												margin:'0px'
											}}
										>
											AS Reg:
										</FormLabel>
										<Textarea 
											maxRows={1} 
											variant='outlined' 
											value={AS.acReg}
											disabled
											sx={{
												'--variant-outlinedDisabledColor':'balck',
												'--variant-outlinedDisabledBorder':'black',
												'--Textarea-paddingBlock':0,
												'--Textarea-paddingInline':0,
												width:'115px',
												height:'25px',
												minHeight:'0px',
												borderRadius:'0px',
												border:'1px solid black',
												fontWeight:'bold',
												fontSize:'16px',
												backgroundColor:'#d6d3ce',
												'& textarea':{
													textAlign:'center'
												}
											}}
										/>
									</FormControl>
									<FormControl sx={{ml:1}}>
										<FormLabel 
											sx={{
                                                alignSelf:'center',
												fontSize:'12px',
												margin:'0px',
											}}
										>
											S/N:
										</FormLabel>
										<Textarea 
											maxRows={1} 
											variant='outlined' 
											value={AS.acsn}
											disabled
											sx={{
												'--variant-outlinedDisabledColor':'balck',
												'--variant-outlinedDisabledBorder':'black',
												'--Textarea-paddingBlock':0,
												'--Textarea-paddingInline':0,
												width:'115px',
												height:'25px',
												minHeight:'0px',
												borderRadius:'0px',
												border:'1px solid black',
												fontWeight:'bold',
												fontSize:'16px',
												backgroundColor:'#d6d3ce',
                                                '& textarea':{
													textAlign:'center'
												}
											}}
										/>
									</FormControl>
								</Box>
                                <Box 
									sx={{
										display:'flex',
										flexDirection:'space-between'
									}}
								>
									<FormControl sx={{mx:1}}>
										<FormLabel 
											sx={{
												alignSelf:'center',
												fontSize:'12px',
												margin:'0px'
											}}
										>
											Operator Code ICAO:
										</FormLabel>
										<Textarea 
											maxRows={1} 
											variant='outlined' 
											value={AS.operatorCodeICAO}
											disabled
											sx={{
												'--variant-outlinedDisabledColor':'balck',
												'--variant-outlinedDisabledBorder':'black',
												'--Textarea-paddingBlock':0,
												'--Textarea-paddingInline':0,
												width:'120px',
												height:'25px',
												minHeight:'0px',
												borderRadius:'0px',
												border:'1px solid black',
												fontWeight:'bold',
												fontSize:'16px',
												backgroundColor:'#d6d3ce',
												'& textarea':{
													textAlign:'center',
													height:'10px'
												}
											}}
										/>
									</FormControl>
									<FormControl sx={{mx:1}}>
										<FormLabel 
											sx={{
												fontSize:'12px',
												margin:'0px'
											}}
										>
											Operator Name:
										</FormLabel>
										<Textarea 
											maxRows={1} 
											variant='outlined' 
											value={AS.operatorName}
											disabled
											sx={{
												'--variant-outlinedDisabledColor':'balck',
												'--variant-outlinedDisabledBorder':'black',
												'--Textarea-paddingBlock':0,
												'--Textarea-paddingInline':0,
												width:'580px',
												height:'25px',
												minHeight:'0px',
												borderRadius:'0px',
												border:'1px solid black',
												fontWeight:'bold',
												fontSize:'16px',
												backgroundColor:'#d6d3ce',
												pl:0.5
											}}
										/>
									</FormControl>
									<FormControl sx={{ml:1}}>
										<FormLabel 
											sx={{
                                                alignSelf:'center',
												fontSize:'12px',
												margin:'0px'
											}}
										>
											STA:
										</FormLabel>
										<Textarea 
											maxRows={1} 
											variant='outlined' 
											value={AS.staOperatorBaseLocation}
											disabled
											sx={{
												'--variant-outlinedDisabledColor':'balck',
												'--variant-outlinedDisabledBorder':'black',
												'--Textarea-paddingBlock':0,
												'--Textarea-paddingInline':0,
												width:'120px',
												height:'25px',
												minHeight:'0px',
												borderRadius:'0px',
												border:'1px solid black',
												fontWeight:'bold',
												fontSize:'16px',
												backgroundColor:'#d6d3ce',
												'& textarea':{
													textAlign:'center'
												}
											}}
										/>
									</FormControl>
								</Box>
							</Box>
							<Box
								sx={{
									border:'1px solid black',
									mt:2,
									mx:1
								}}
							>	
								<Typography
									sx={{
										ml:1.5,
										color:'#000084',
										fontWeight:'bold'
									}}
								>
									Selected AMP:
								</Typography>
								<FormControl sx={{mx:1}}>
									<Box
										sx={{
											display:'flex',
											mb:1
										}}
									>
										<FormControl required>
											<FormLabel
												sx={{
													alignSelf: 'center',
													fontSize: '12px',
													margin: '0px',
													color: 'black',
												}}
											>
												AMP: 
											</FormLabel>
											<Autocomplete
												options={AMPstring.map(item => ({
													id: item.id,
													label: item.otherProperties
												}))}
												getOptionLabel={option => option.label}
												onChange={(event, newValue) => {
													const selectedAmpObject = AMP.find(item => item.id === newValue.id);
													setSelectedAMP(selectedAmpObject);
												}}
												required
												sx={{
													width: 300,
													minHeight: 0,
													height: '25px',
													borderRadius: 0,
													borderLeft:'1px solid black',
													borderTop:'1px solid black',
													borderRight:'1px solid white',
													borderBottom:'1px solid white',
													backgroundColor:'#c6ffff',
													'--Icon-color': 'black',
													'& button':{
														backgroundColor:'#d6d3ce',
														borderRadius:0,
														borderLeft:'1px solid white',
														borderTop:'1px solid white',
														borderRight:'1px solid black',
														borderBottom:'1px solid black',
														minHeight:0,
														height:'23px',
														minWidth:0,
														width:'17px',
														
													}
												}}
											/>
										</FormControl>
									</Box>
									<FormLabel 
										sx={{
											fontSize:'12px',
											ml:1.5,
											my:0
										}}
									>
										Remarks:
									</FormLabel>
									<Textarea 
										maxRows={2} 
										variant='outlined'
										value={editedRemarks !== '' ? editedRemarks : (selectedAMP ? selectedAMP.remarks : '')} 
    									onChange={(e) => setEditedRemarks(e.target.value)}
											sx={{
											'--Textarea-paddingBlock':0,
											'--Textarea-paddingInline':0,
											'--Textarea-focusedThickness': '0',
											width:'850px',
											height:'50px',
											minHeight:'0px',
											borderRadius:'0px',
											border:'1px solid black',
											fontWeight:'bold',
											fontSize:'16px',
										}}
									/>
								</FormControl>
							</Box>
							<Box
								sx={{
									display:'flex',
									justifyContent:'flex-end'								
								}}
							>
								<Button 
									onClick={handlCreate}
									disabled={!selectedAMP} // Добавляем атрибут disabled, если selectedAMP не установлен
									sx={{
										display:'flex', 
										flexDirection:'column',
										backgroundColor: !selectedAMP ? '#c0bdfe80' : '#c0bdfe', // Изменяем цвет кнопки в зависимости от выбранного AMP
										borderRadius:'0px',
										borderTop:'2px solid white',
										borderLeft:'2px solid white',
										borderRight:'2px solid black',
										borderBottom:'2px solid black',
										height:'48px',
										minHeight:'0px',
										width:'78px',
										my:1,
										mx:1,
										'&:hover': {
											backgroundColor: !selectedAMP ? '#c0bdfe80' : '#a8a5e6', // Изменяем цвет при наведении в зависимости от выбранного AMP
										}
									}}
								>
									<TaskIcon 
										sx={{
											color:'black',
											height:'30px',
											width:'30px'
										}}
									/>
									<Typography 
										sx={{
											fontSize:'12px'
										}}
									>
										Initialize
									</Typography>
								</Button>

								<Button
									onClick={handleClose}
									sx={{
										display:'flex', 
										flexDirection:'column',
										backgroundColor:'#c0bdfe',
										borderRadius:'0px',
										borderTop:'2px solid white',
										borderLeft:'2px solid white',
										borderRight:'2px solid black',
										borderBottom:'2px solid black',
										height:'48px',
										minHeight:'0px',
										width:'78px',
										my:1,
										mx:1,
										'&:hover': {
											backgroundColor: '#a8a5e6',
										}
									}}
								>
									<MeetingRoomIcon
										sx={{
											color:'black',
											height:'30px',
											width:'30px'
										}}
									/>
									<Typography
										sx={{
											fontSize:'12px'
										}}
									>
										Close
									</Typography>
								</Button>
							</Box>
						</Box>
					</Box>
				</Sheet>
			</>
		</React.Fragment>
	);
}